import { makeAutoObservable } from "mobx";

class StoreReferralFormat {

    paymentOptionSelected = 'subscribe'
    shippingOptionSelected = 'delivery'

    constructor() {
        makeAutoObservable(this)
    }
}

export default new StoreReferralFormat()