import mobiscroll, { Select } from '@mobiscroll/react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import DefaultInput from '../DefaultInput'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './CitySelect.css'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { ThemeProvider } from '@emotion/react'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import styled from '@emotion/styled'
import { reaction } from 'mobx'
import { ReactComponent as DropdownIcon } from '../Assets/dropdown.svg'
import { storeElement } from 'GlobalStores/StoreElement'
import { isEmpty, lowerCase } from 'lodash'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'

const CitySelect = observer(props => {
    const selectRef = useRef(null)
    const [loading, setLoading] = useState(false)

    const localObserver = useLocalObservable(() => ({
        listForRender: [],
        value: ''
    }))

    const renderProvinces = async () => {
        try {
            let list = []
            const options = []
            storeCheckout
                .CheckOutInstance()
                .Instance()
                .AddressOptionList(props.item.key, storeCheckout)
                ?.forEach(item => {
                    options.push(item.value)
                })

            const cityOptions = options.map(item => {
                return {
                    city_native: item,
                    city_roman: item,
                    priority: null
                }
            })

            setLoading(true)

            const keyEnglish = 'city_roman'
            const keyNative = 'city_native'

            cityOptions.forEach(province => {
                const englishTitle = province[keyEnglish] ? province[keyEnglish].trim() : ''
                const nativeTitle = province[keyNative] ? province[keyNative].trim() : ''
                const title = StoreTranslate.CurrentLanguage() === 'EN' ? englishTitle : nativeTitle

                let multiLangTitle
                multiLangTitle = StoreTranslate.CurrentLanguage() === 'EN' ? `${englishTitle} (${nativeTitle})` : `${nativeTitle} (${englishTitle})`

                list.push({
                    value: title,
                    text: multiLangTitle ? multiLangTitle : title,
                    html: StoreTranslate.CurrentLanguage() === 'EN' ? `<span>${englishTitle}</span>` : `<span>${nativeTitle}</span>`
                })
            })

            localObserver.listForRender = list
            setLoading(false)
            setPropValueToState(localObserver.value)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const disposer = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    renderProvinces()
                }
            }
        )

        reaction(
            () => storeCheckout.CheckOutInstance().Instance().AddressOptionList(props.item.key, storeCheckout),
            (n, p, d) => {
                if (n !== p) {
                    renderProvinces()
                    d.dispose()
                }
            }
        )

        return () => {
            disposer()
        }
    })

    useEffect(() => {
        if (props.value || props.value === '') {
            if (localObserver.listForRender.length > 0) {
                setPropValueToState(props.value)
            } else if (localObserver.listForRender.length === 0) {
                renderProvinces()
                setPropValueToState(props.value)
            }
        } else {
            if (localObserver.listForRender.length === 0) {
                renderProvinces()
            }
        }
    }, [props.value, localObserver.listForRender.length])

    // useEffect(() => {
    //     if (props.option.length > 0) {
    //         renderProvinces()
    //     }
    // }, [props.option])

    const handleOnClick = () => {
        selectRef.current?.click()
    }

    const setPropValueToState = propValue => {
        if (propValue) {
            let val = lowerCase(propValue)
            localObserver.listForRender.forEach(l => {
                if (lowerCase(l.text).includes(val)) {
                    localObserver.value = l.value
                }
                return l
            })
        }
    }

    const onSelected = (event, inst) => {
        storeCheckout.SetCheckoutFormValue(props.item.key, inst.getVal())
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <div style={{ display: 'none' }}>
                <Select
                    className={'province'}
                    disabled={loading}
                    key={'province'}
                    display="center"
                    touchUi={false}
                    filter={true}
                    height={40}
                    maxWidth={400}
                    data={localObserver.listForRender}
                    onSet={onSelected}
                    value={localObserver.value}
                    filterPlaceholderText=""
                    group={{
                        groupWheel: false,
                        header: false
                    }}>
                    <input ref={selectRef} />
                </Select>
            </div>

            <CustomInputWithEndorment
                onClick={handleOnClick}
                value={props.value}
                label={props.label}
                endAdornment={<DropdownIcon style={{ width: '10px', height: '13px' }} />}
            />
        </ThemeProvider>
    )
})
const CustomInputWithEndorment = styled(DefaultInput)`
    .MuiInputAdornment-root {
        position: absolute;
        right: 15px;
    }
`

export default CitySelect
