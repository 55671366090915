import React, { useEffect, useRef, useState } from 'react'
import { Box, InputAdornment, Stack, ThemeProvider, Typography, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { get } from 'lodash'

import { storeElement } from 'GlobalStores/StoreElement'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './mobiscollNumber.css'

import { ReactComponent as DropdownIcon } from './../Assets/miniDropDown.svg'
import DefaultInput from '../DefaultInput'
import AllCountries from '../Data/AllCountries.json'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import UAECountryData from '../Data/UAECountry.json'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { uaeAreaCountries } from '../../../Helpers/UAEHelpers'

const DefaultPhoneNumberCountry = observer(({ item, onChange, onKeyUp }) => {
    const [flag, setFlag] = useState('')
    const [valueOptions, setValueOptions] = useState('')
    const [inInit, setIsInit] = useState(true)

    const translate = useTranslate()

    const allCountriesData = AllCountries.map(item => {
        return {
            flag: item.code,
            countryName: item.name,
            options: `${item.dial_code} ${item.name}`,
            dial_code: item.dial_code
        }
    })

    useEffect(() => {
        if (inInit) {
            const defaultValueOptions = allCountriesData.find(item => {
                return storeFormat.GetDetailPage().country_calling_code === item.dial_code || item.flag === storeCountry.Country2()
            })

            setValueOptions(defaultValueOptions)
            setFlag(defaultValueOptions.flag)

            runInAction(() => {
                StoreCheckout.SetPhoneNumberCode(defaultValueOptions.dial_code)
            })
            setIsInit(false)
        } else {
            const phoneCodeObj = allCountriesData.find(item => item.dial_code === StoreCheckout.GetPhoneNumberCode())
            setValueOptions(phoneCodeObj)
            setFlag(phoneCodeObj.flag)
        }
    }, [StoreCheckout.GetPhoneNumberCode()])

    const renderLists = allCountriesData.map(item => {
        return {
            text: `${item.dial_code} ${item.countryName}`,
            value: item.countryName
        }
    })

    const onSelected = (e, inst) => {
        const valueSelected = e.valueText

        const data = allCountriesData.find(item => item.options === valueSelected)

        const shipingFormCountry = get(
            StoreCheckout.GetShippingForm().find(item => item.key === 'country'),
            'value',
            ''
        )

        const CheckoutFormCountry = StoreCheckout.GetCheckoutFormValue('country')

        if (
            UAECountryData.find(item => item.name === data.countryName)
            // shipingFormCountry !== 'United Arab Emirates' &&
            // CheckoutFormCountry !== 'United Arab Emirates'
        ) {
            const uaeCountryObj = uaeAreaCountries(data.countryName)

            const countryName = get(uaeCountryObj, StoreTranslate.IsEnglish() ? 'name' : 'native_name', '')
            StoreCheckout.SetCheckoutFormValue('country', countryName)
        }

        setValueOptions(data)
        setFlag(data.flag)
        StoreCheckout.SetPhoneNumberCode(data.dial_code)
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Box>
                <Stack>
                    <Typography variant="label" sx={{ color: 'rgba(21, 56, 98, 0.5)' }} mb="8px" pl="16px">
                        {translate(get(item, 'key', ''))}
                    </Typography>
                </Stack>
                <Stack flexDirection={'row'} gap={'15px'}>
                    <Stack width={'90px'} sx={{ cursor: 'pointer' }} className="mobi-custom-input">
                        <mobiscroll.Select
                            className={item.key}
                            key={item.key}
                            display="center"
                            touchUi={false}
                            filter={true}
                            height={40}
                            maxWidth={400}
                            data={renderLists}
                            defaultSelection={valueOptions.countryName}
                            onSet={(e, inst) => onSelected(e, inst)}
                            value={valueOptions.countryName}
                            filterPlaceholderText=""
                            group={{
                                groupWheel: false,
                                header: false
                            }}>
                            <mobiscroll.Input>
                                <Stack
                                    position={'absolute'}
                                    sx={{
                                        flexDirection: 'row',
                                        transform: 'translate(-50%, -50%)',
                                        top: '50%',
                                        left: '50%',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                    <Flag flag={flag ? flag : storeCountry.Country2()} type />
                                    <DropdownIcon style={{ cursor: 'pointer' }} />
                                </Stack>
                            </mobiscroll.Input>
                        </mobiscroll.Select>
                    </Stack>
                    <DefaultInputStyled
                        value={item.value}
                        type="tel"
                        key={item.key}
                        typeKey={item.key}
                        disabled={item.disabled || false}
                        placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                if (e.target.value.length === 0) {
                                    StoreCheckout.SetCheckoutFormValue(item.key, '')
                                } else {
                                    const regx = get(item, 'args.regx', null)
                                    if (regx) {
                                        const regex = new RegExp(regx, 'i')
                                        if (regex.test(e.target.value)) {
                                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                        }
                                    } else {
                                        StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                    }
                                }
                            }
                        }}
                        InputProps={{
                            autoComplete: 'new-password',
                            autoCorrect: 'off',
                            spellCheck: 'false',
                            startAdornment: (
                                <InputAdornment sx={{ marginRight: '5px' }} position="start">
                                    <Typography variant="label" fontSize={'14px'} fontWeight={'400'} color="#000000">
                                        {StoreCheckout.GetPhoneNumberCode()}
                                    </Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
            </Box>
        </ThemeProvider>
    )
})

const Flag = observer(props => {
    return (
        <Wrapper>
            <FlagImage className={props.type ? 'border-header' : ''} src={require(`./../Assets/countriesSVG/${props.flag.toLowerCase()}.svg`)} />
        </Wrapper>
    )
})

const Wrapper = styled(`div`)`
    width: 25px;
    display: flex;
`

const FlagImage = styled(`img`)`
    // width: 30px;
    height: 15px;

    &.border-header {
        border-radius: 3px;
    }
`

const DefaultInputStyled = styled(DefaultInput)`
    width: 100%;

    .MuiOutlinedInput-root {
        border: 1px solid #ccdeef;
        border-radius: 6px;
        height: 50px;
        width: 100%;

        .MuiInputBase-input {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .Mui-focused {
        border: 2px solid #99bcdf;
    }

    .MuiOutlinedInput-notchedOutline {
        display: none;
    }
`

// const MobiStyled = styled(mobiscroll.Select)

export default DefaultPhoneNumberCountry
