import { autorun, makeAutoObservable } from 'mobx'

class checkoutLoader {
    orderApiReady = false
    /**
     * -1 = not used
     * 0 = not started
     * 1 = starting
     * 2 = loaded
     * 3 = failed
     */
    orderApiProgress = {
        hydraCreateAccount: 0,
        login: 0,
        createOrder: 0,
        createAutoship: 0
    }

    orderApiProgressKeyToDictionary = {
        createOrder: 'processing_payment',
        hydraCreateAccount: 'processing_membership',
        login: 'logging_in',
        createAutoship: 'processing_subscription'
    }

    IsApiReady() {
        return this.orderApiReady
    }

    GetOrderApisPercentSummary() {
        let percent = 0
        let totalMainApi = 0
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] !== -1) {
                totalMainApi++
            }
        })
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] === 2) {
                percent += 100 / totalMainApi
            }
        })

        return percent
    }

    GetOrderApisPercentBuffer() {
        let percent = 0
        let totalMainApi = 0
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] !== -1) {
                totalMainApi++
            }
        })
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] === 1) {
                percent += 10 / totalMainApi
            }
        })

        return percent
    }

    IsOrderApisHasFailed() {
        let hasFailed = false
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] === 3) {
                hasFailed = true
            }
        })

        return hasFailed
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const CheckoutLoaderStore = new checkoutLoader()

autorun(() => {
    Object.keys(CheckoutLoaderStore.orderApiProgress).forEach(key => {
        if (CheckoutLoaderStore.orderApiProgress[key] === 0) {
            CheckoutLoaderStore.orderApiReady = false
            return
        }
    })
})

export default CheckoutLoaderStore
