import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SessionAuth from 'GlobalStores/User/SessionAuth'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { Stack } from '@mui/material'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import storeReferral from './Stores/StoreReferral'

const LoginWrapper = observer(({ children }) => {
    const params = useParams()
    const navigate = useNavigate()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])
    const [loading, setLoading] = useState(true)

    const RestoreData = useCallback(() => {
        if (!storeAuth.IsAuthorized()) {
            SessionAuth.ShouldRestore()
                .then(() => {
                    storeAuth
                        .RestoreSession(SessionAuth.GetLoginExtends()[storeCountry.CountryLowerCase()])
                        .then(() => {
                            if (/\/login/.test(window.location.pathname)) {
                                console.log('redirect from login')
                                navigate('/', { replace: true })
                            } else if (params.customerId) {
                                console.log('redirect to referral')
                                navigate('/', { replace: true })
                            }
                            setLoading(false)
                        })
                        .catch(() => {
                            console.log('can not restore session')
                            SessionAuth.RemoveSession(true)
                            if (/\/login/.test(window.location.pathname)) {
                                setLoading(false)
                            } else {
                                navigate('/login', { replace: true })
                            }
                        })
                })
                .catch(() => {
                    console.log('can not restore session then redirect to login')
                    if (params.customerId || storeReferral.useReferral) {
                        if (!storeReferral.useReferral) {
                            storeReferral.useReferral = params.customerId
                        }
                        setLoading(false)
                    } else {
                        navigate('/login', { replace: true })
                        setLoading(false)
                    }
                })
        } else {
            setLoading(false)
        }
    }, [params.customerId, navigate])

    useEffect(() => {
        if (qs.get('logout')) {
            localStorage.setItem('from-logout', '1')
        }
        RestoreData()
    }, [params.customerId, navigate, qs, RestoreData])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {loading ? (
                <Stack minHeight={'calc(100vh - 138px)'} bgcolor={'#d2d9e1'} alignItems={'center'} justifyContent={'center'}>
                    <IconSpinner />
                </Stack>
            ) : (
                children
            )}
        </>
    )
})

export default LoginWrapper
