import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, Stack, Typography } from '@mui/material'
import DefaultInput from '../DefaultInput'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

const MobileInput = observer(props => {
    const [values, setValues] = useState({
        formattedValue: ''
    })

    const FormatMobile = inputValue => {
        if (inputValue && inputValue.length > 0) {
            inputValue = inputValue.match(/^(\d{0,2})(\d{0,3})(\d{0,4})/)
            inputValue = !inputValue[2] ? inputValue[1] : inputValue[1] + '-' + inputValue[2] + (inputValue[3] ? '-' + inputValue[3] : '')
        }

        return inputValue
    }

    useEffect(() => {
        setValues({
            ...values,
            formattedValue: FormatMobile(props.currentValue)
        })
    }, [props.currentValue])

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Box>
                <Stack>
                    <Typography variant="label" sx={{ color: 'rgba(21, 56, 98, 0.5)' }} mb="8px" pl="16px">
                        <T>{props.item.key}</T>
                    </Typography>
                </Stack>
                <DefaultInputStyled
                    value={values.formattedValue}
                    onChange={event => {
                        let inputValue = FormatMobile(event.target.value.replace(/\D/g, '') || '')

                        setValues({
                            ...values,
                            formattedValue: inputValue
                        })

                        if (props.onChange) {
                            props.onChange(inputValue.replace(/-/g, ''))
                        }
                    }}
                    InputProps={props.InputProps}
                />
            </Box>
        </ThemeProvider>
    )
})

const DefaultInputStyled = styled(DefaultInput)`
    width: 100%;

    .MuiOutlinedInput-root {
        border: 1px solid #ccdeef;
        border-radius: 6px;
        height: 50px;
        width: 100%;

        .MuiInputBase-input {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .Mui-focused {
        border: 2px solid #99bcdf;
    }

    .MuiOutlinedInput-notchedOutline {
        display: none;
    }
`

export default MobileInput
