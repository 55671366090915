const ENV_DEV = 'DEV'
const ENV_LIVE = 'LIVE'

//* MAIN ENV FORCE API ENVIRONMENT
const FORCE_ENV = ENV_LIVE
// const FORCE_ENV = ENV_DEV

const LIVE_SUB_DOMAIN = /^ufg$/g
// Example DEV_SUB_DOMAIN = [/ufg-dev/, /ufg-uat/, /ufg-qa/, /localhost/, /127\.0\.0\.1/]

function IsForceEnv() {
    if (FORCE_ENV) {
        return true
    } else {
        return false
    }
}

function IsLIVE() {
    const hostname = window.location.hostname
    const [subDomain] = hostname.split('.')
    return LIVE_SUB_DOMAIN.test(subDomain)
}

const ENV = IsForceEnv() ? FORCE_ENV : IsLIVE() ? ENV_LIVE : ENV_DEV

function MemberCalls2(forceLive = false) {
    return ENV === ENV_DEV && forceLive === false ? 'https://member-calls2-dev.unicity.com' : 'https://member-calls2.unicity.com'
}

function MemberCalls2Dev() {
    return 'https://member-calls2-dev.unicity.com'
}

function Hydra() {
    return ENV === ENV_DEV ? 'https://hydraqa.unicity.net/v5a-test' : 'https://hydra.unicity.net/v5a'
}

export const ServerEnv = {
    MemberCalls2,
    MemberCalls2Dev,
    Hydra,
    ENV
}
