import { InputAdornment, InputBase, Stack, styled } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useEffect, useState } from 'react'
import { ReactComponent as ArrowRightIcon } from './Assets/arrow-long-right.svg'
import { observer } from 'mobx-react-lite'
import storeReferral from '../../Stores/StoreReferral'

const ReferralInput = observer(({ currentBAId, onSubmit }) => {
    const [referralCode, setReferralCode] = useState(currentBAId)
    const translate = useTranslate()

    const submitRefCode = async e => {
        e.preventDefault()

        await onSubmit(referralCode)
    }

    const handleOnChange = e => {
        const { value } = e?.target

        if (Boolean(storeReferral.GetReferralCheckError())) {
            storeReferral.SetReferralCheckError('')
        }

        setReferralCode(value)
    }

    useEffect(() => {
        setReferralCode(currentBAId)

        return () => {
            setReferralCode('')
        }
    }, [currentBAId])

    return (
        <ReferralInputWrapper>
            <form className="mainInput" onSubmit={submitRefCode}>
                <CustomRefInput
                    type="text"
                    autoComplete="off"
                    name="referralCode"
                    placeholder={translate('enter_your_referral_code')}
                    endAdornment={
                        <InputAdornment position="end">
                            <ArrowRightIcon style={{ cursor: 'pointer' }} onClick={submitRefCode} />
                        </InputAdornment>
                    }
                    onChange={handleOnChange}
                    value={referralCode}
                    //referralInvalid={true}
                    referralInvalid={Boolean(storeReferral.GetReferralCheckError())}
                />
            </form>
        </ReferralInputWrapper>
    )
})

export default ReferralInput

const CustomRefInput = styled(InputBase)(({ theme, referralInvalid }) => ({
    width: '287px',
    height: '36px',
    borderRadius: '6px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,

    lineHeight: '16px',

    padding: '10px 16px',

    input: {
        fontSize: '12px'
    },

    border: `1px solid ${referralInvalid ? '#FFD058' : '#ffffff'}`,
    color: `${referralInvalid ? '#FFD058' : '#ffffff'}`,

    '& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& -moz-appearance': 'textfield'
}))

const ReferralInputWrapper = styled(Stack)`
    flex-direction: column;
    align-items: center;
    .mainInput {
        display: flex;
        flex-direction: column;
    }
`
