import axios from 'axios'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'
import { ServerEnv } from 'Configs/ServerEnv'

const getFeelGreatProductsV3 = (balStatus = null) => {
    let queryStrings = []
    if (balStatus) {
        queryStrings.push(`baStatus=${balStatus}`)
    }

    const url = `${ServerEnv.MemberCalls2()}/products-v2/feelGreat/v3/publish/${StoreCountry.Country3()}${queryStrings.length ? '?' + queryStrings.join('&') : ''}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const getReferralCodes = token => {
    const url = `${ServerEnv.Hydra()}/customers/me/referralcodes`
    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-Application': `asia.ufg.${StoreCountry.Country2()}`
        }
    })
}

const feelGreateAPI = { getFeelGreatProductsV3, getReferralCodes }

export default feelGreateAPI
