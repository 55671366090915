import { Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Suspense } from 'react'

// const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('./Uae/Components/OrderSummary/OrderSummaryItemCard'), 0))

const DefaultCheckoutTheme = observer(({ Sections, step, onPlaceOrder, PreSummarySection }) => {
    return (
        <>
            <Suspense fallback={<div></div>}>
                <Stack minHeight={'300px'}>{Sections(step)}</Stack>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <Stack>{PreSummarySection()}</Stack>
                {/* <PreSummaryBoxLazyLoad onSubmit={onPlaceOrder} /> */}
            </Suspense>
        </>
    )
})

export default DefaultCheckoutTheme
