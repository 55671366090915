import ResetPassword from 'Components/Pages/ResetPassword'
import ReferralCheckoutPage from '../ReferralCheckoutPage'
import ReferralMainPage from '../ReferralMainPage'
import PaymentResponseSuccessPage from '../ReferralPaymentResponseSuccessPage'
import ReferralQRPaymentPage from '../ReferralQRPaymentPage'
import TaxInvoice from './Components/TaxInvoice/TaxInvoice'
import { Navigate } from 'react-router-dom'
import ResetPasswordSuccess from 'Components/Pages/ResetPasswordSuccess'
import LoginPage from './Components/LoginPage/LoginPage'
import ContactAddressForm from './Components/ContactAddressForm'

export const AREReferralRouter = [
    {
        path: '/',
        element: <ReferralMainPage />,
        children: [
            {
                path: 'checkout/create-account',
                element: <ContactAddressForm />
            },
            {
                path: 'checkout/shipping',
                element: <ContactAddressForm />
            },
            {
                path: 'checkout/payment',
                element: <ReferralCheckoutPage />
            },
            {
                path: 'checkout/qrpayment',
                element: <ReferralQRPaymentPage />
            },
            {
                path: 'tax-invoice',
                element: <TaxInvoice />
            },
            {
                path: ':customerId',
                element: <ReferralMainPage />
            },
            {
                path: 'payment-response/success',
                element: <PaymentResponseSuccessPage />
            },
            {
                path: 'login',
                element: <LoginPage />
            },
            {
                path: 'reset-password',
                element: <ResetPassword />
            },
            {
                path: 'reset-password-success',
                element: <ResetPasswordSuccess type={'success'} />
            },
            {
                path: 'reset-password-error',
                element: <ResetPasswordSuccess type={'error'} />
            },
        ]
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
        children: []
    }
]
