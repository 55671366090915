import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useCountries } from 'Services/Cores/Countries/useCountries'
import { Initialize } from 'Services/Cores/Initialize'
import StoreCountry from './GlobalStores/Cores/County/StoreCountry'
import AppRouter from './Routers/AppRouter'

import mobiscroll from '@mobiscroll/react'
import BypassRedirectPath from './Data/BypassRedirectPath.json'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import LoadingBar from 'Components/Elements/LoadingBar/LoadingBar'
import { isMaintenanceMode, isComingSoon } from 'Helpers/GlobalHelpers'
import Maintenance from 'Components/Pages/Maintenance'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import ReactFbq from 'react-fbq'
import { get, lowerCase } from 'lodash'
import { Paths } from 'Routers/Paths'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

import storeReCaptcha from 'GlobalStores/StoreReCaptcha'
import { autorun, configure, reaction } from 'mobx'
import { useMemo } from 'react'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import { storeElement } from 'GlobalStores/StoreElement'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import ComingSoonPage from 'Components/Pages/Referral/ComingSoonPage'
import feelGreateAPI from 'GlobalStores/Cores/County/APIs'
import SessionAuth from 'GlobalStores/User/SessionAuth'
import StoreOrderCalc from 'GlobalStores/Cores/OrderCalc/StoreOrderCalc'
import StoreReferralCart from 'Components/Pages/Referral/Uae/Stores/StoreReferralCart'

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light'
}

configure({
    enforceActions: 'never'
})

const App = observer(() => {
    const country = useCountries()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    const [autoLoginLoader, setAutoLoginLoader] = useState(false)

    Initialize()

    const GetSiteVersion = useCallback(() => {
        if (country) {
            feelGreateAPI.getSiteVersion({ country: country.country.toLowerCase() }).then(res => {
                let currentVersion = localStorage.getItem('siteVersion')
                if (!currentVersion) {
                    localStorage.setItem('siteVersion', res.data.version)
                } else if (currentVersion !== res.data.version) {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                }
            })
        }
    }, [country])

    useEffect(() => {
        if (country) {
            GetSiteVersion()
            let countryCapitalized = country.country.toLowerCase().charAt(0).toUpperCase() + country.country.toLowerCase().slice(1)
            let templateName = `${countryCapitalized}Template`
            import(`./Components/Template/${templateName}`)
                .then(theme => {
                    storeElement.theme = theme[templateName]
                })
                .catch(e => {
                    storeElement.theme = MainTemplate
                })

            const currentLanguageStorage = localStorage.getItem('currentLanguage')

            let countryLowerCase = country.country.replace(/ /g, '').toLowerCase()
            checkToken()

            if (currentLanguageStorage && currentLanguageStorage !== '' && hasLanguage(country, currentLanguageStorage)) {
                if (country.config.languageSettings.default !== currentLanguageStorage) {
                    localStorage.setItem('currentLanguage', country.config.languageSettings.default)
                    StoreTranslate.SetCurrentLanguage(country.config.languageSettings.default.replace('/ /g', ''))
                    storeReCaptcha.setCurrentLanguage(countryLowerCase, country.config.languageSettings.default.replace('/ /g', ''))
                } else {
                    StoreTranslate.SetCurrentLanguage(currentLanguageStorage)
                    storeReCaptcha.setCurrentLanguage(countryLowerCase, currentLanguageStorage)
                }
            } else {
                StoreTranslate.SetCurrentLanguage(country.config.languageSettings.default.replace('/ /g', ''))
                localStorage.setItem('currentLanguage', country.config.languageSettings.default.replace('/ /g', ''))
            }

            // set current country to local storage
            localStorage.setItem('currentCountry', countryLowerCase)

            if (PathHelper.Segments().length > 2 && isNaN(PathHelper.Segment(2)) && !isBypass()) {
                if (Paths.includes(PathHelper.Segment(2))) {
                    window.location.replace(PathHelper.BuildBasename(countryLowerCase))
                }
            } else if (window.location.pathname === '/' || PathHelper.Segment(1) !== countryLowerCase) {
                window.location.replace(PathHelper.BuildBasename(countryLowerCase))
            }

            TrackingEvents.Commit(lowerCase(country.country))
            try {
                ReactFbq.initialize({
                    id: StoreCountry.FBPixelCode.all
                })
                ReactFbq.pageView()
            } catch (e) {
                console.error('FACEBOOK PIXEL ERROR', e)
            }
        }
    }, [country])

    /*
     * for redirecting reset password page
     */
    useEffect(() => {

        StoreOrderCalc.resetOrderCalc()
        StoreReferralCart.ResetAllItemQtytoOne()

        autorun(diposer => {
            if (StoreCountry.countryList) {
                if (qs.get('market') && /new-password/.test(window.location.pathname) && !!country === false) {
                    const marketCountry = StoreCountry.CountryList().find(item => item.shorter.toLowerCase() === qs.get('market').toLowerCase())
                    const isNewPasswordPath = PathHelper.Segment(1) === 'new-password'
                    if (marketCountry && isNewPasswordPath) {
                        const pathnames = window.location.pathname.split('/')
                        let newUrl = ''
                        if (/^\/feature/.test(window.location.pathname)) {
                            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${
                                pathnames[3]
                            }/${marketCountry.country.toLowerCase()}/new-password/${pathnames[5]}/${window.location.search}`
                        } else if (/^\/epic/.test(window.location.pathname)) {
                            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}/${pathnames[4]}/${
                                pathnames[5]
                            }/${marketCountry.country.toLowerCase()}/new-password/${pathnames[7]}/${window.location.search}`
                        } else {
                            newUrl = `${window.location.protocol}//${window.location.host}/${marketCountry.country.toLowerCase()}/new-password/${
                                pathnames[2]
                            }/${window.location.search}`
                        }
                        diposer.dispose()
                        window.location.href = newUrl
                    }
                } else {
                    
                    diposer.dispose()
                }
            }
        })
    }, [])

    useEffect(() => {
        if (qs.get('autoLogin')) {
            localStorage.setItem('autoLogin', qs.get('autoLogin'))
            window.location.href = `${window.location.pathname}`
        }
    }, [qs])

    useEffect(() => {
        if (localStorage.getItem('autoLogin')) {
            GetAutoLogin(localStorage.getItem('autoLogin'))
        }
    }, [])

    const GetAutoLogin = async token => {
        try {
            setAutoLoginLoader(true)
            await storeAuth.AutoLogin(token)
            localStorage.removeItem('autoLogin')

            setAutoLoginLoader(false)
        } catch (e) {
            setAutoLoginLoader(false)
        }
    }

    const checkFirstPathname = () => {
        if (StoreCountry.CountryList().length > 0) {
            const countryList = StoreCountry.CountryList()

            const index = countryList.findIndex(country => {
                const countryName = country.country.toLowerCase()
                return PathHelper.Segment(1) === countryName || BypassRedirectPath.includes(PathHelper.Segment(1))
            })

            return index > -1 ? true : false
        }
    }

    const checkToken = async () => {
        if (SessionAuth.GetLoginExtend('token')) {
            try {
                StoreLoader.SetApiLoaded('refreshToken', false)

                const response = await SessionAuth.CheckToken(
                    SessionAuth.GetLoginExtend('token'),
                    StoreCountry.CountryLowerCase(),
                    SessionAuth.GetLoginExtend('id')
                )
                if (response.data.token) {
                    SessionAuth.SetLoginExtend('token', response.data.token)
                }
                StoreLoader.SetApiLoaded('refreshToken', true)
            } catch (e) {
                console.log(e)
                SessionAuth.RemoveSession(true)
                StoreLoader.SetApiLoaded('refreshToken', true)
            }
        } else {
            StoreLoader.SetApiLoaded('refreshToken', true)
        }
    }

    return (
        <div className="App">
            {isMaintenanceMode() ? (
                <Maintenance />
            ) : (
                <>
                    {StoreLoader.IsWebReady() && autoLoginLoader === false ? (
                        <>
                            <LoadingBar />
                            <AppRouter />
                        </>
                    ) : (
                        <LoadingBar />
                    )}
                </>
            )}
        </div>
    )
})

const isBypass = () => {
    const bypassPath = BypassRedirectPath

    return bypassPath.some(path => window.location.pathname.includes(path))
}

const hasLanguage = (country, currentLanguageStorage = '') => {
    const countryLanguageList = get(country, 'config.language.list', [])
    return countryLanguageList.some(lang => (lang.code || '').toLowerCase() === currentLanguageStorage.toLowerCase())
}

export default App
