import styled from '@emotion/styled'
import { Box, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import LinkElement from './Elements/Link'
import Social from './Elements/Social'
import { get } from 'lodash'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useLocation } from 'react-router-dom'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const Footer = observer(props => {
    const location = useLocation()
    const showFullFooterPath = ['/', '/products', '/contact-us']
    const hideSubFooter = [`/user`]
    return (
        <ThemeProvider theme={storeElement.theme}>
            <div id="fg_footer">
                {storeElement.hideFooter ? (
                    <></>
                ) : (
                    <div>
                        {!showFullFooterPath.includes(location.pathname) || !StoreFeelGreat.contents.quickLinks ? (
                            // <FooterMobile />
                            <></>
                        ) : (
                            <>
                                <Box sx={{ width: '100%', height: '5px', backgroundColor: '#99BCDF' }} />
                                <FooterStyled className="site-footer">
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: {
                                                xs: '1fr',
                                                lg: '1fr 3fr'
                                            },
                                            gap: {
                                                xs: '41px',
                                                lg: '180px'
                                            }
                                        }}>
                                        <Social data={StoreFeelGreat.contents.social} />
                                        <Box>
                                            <LinkElement data={StoreFeelGreat.contents.quickLinks.items} />
                                            {StoreFeelGreat.contents.disclaimer && (
                                                <Box
                                                    sx={{
                                                        border: '1px solid #CDDDED',
                                                        borderRadius: '8px',
                                                        marginTop: {
                                                            sm: '40px'
                                                        },
                                                        padding: '16px 14px'
                                                    }}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            color: '#0B3B61',
                                                            opacity: '0.5',
                                                            lineHeight: '18px'
                                                        }}>
                                                        {StoreTranslate.UseLanguage(StoreFeelGreat.contents.disclaimer.paragraphs)}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </FooterStyled>
                            </>
                        )}
                        {hideSubFooter.includes(location.pathname) ? (
                            <></>
                        ) : (
                            <Box
                                sx={{
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    backgroundColor: '#153862',
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'row'
                                    },
                                    alignItems: 'center',
                                    padding: {
                                        xs: '22px 30px',
                                        sm: '22px 60px',
                                        lg: '22px 135px'
                                    },
                                    justifyContent: 'center',
                                    gap: {
                                        xs: '16px',
                                        md: '0px'
                                    }
                                }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: 'white',
                                        fontSize: {
                                            xs: '10px',
                                            sm: '12px'
                                        },
                                        textAlign: 'center'
                                    }}>
                                    &copy; {new Date().getFullYear()} <T>copyright_ufg</T>
                                </Typography>
                                {/* <Box
                                sx={{
                                    display: 'flex',
                                    gap: '22px',
                                    alignItems: 'center',
                                    fontSize: {
                                        xs: '10px',
                                        sm: '12px'
                                    }
                                }}>
                                {StoreFeelGreat.contents.privacyPolicy && (
                                    <Typography
                                        component={'a'}
                                        style={{
                                            color: 'white',
                                            fontFamily: 'Inter, NotoSansThai',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            textUnderlineOffset: '4px',
                                            fontSize: '10px'
                                        }}
                                        href={StoreTranslate.UseLanguage(StoreFeelGreat.contents.privacyPolicy.externalLink)}
                                        target={StoreFeelGreat.contents.privacyPolicy.openTarget}>
                                        {StoreTranslate.UseLanguage(StoreFeelGreat.contents.privacyPolicy.title)}
                                    </Typography>
                                )}
                                <Box
                                    sx={{
                                        color: 'white',
                                        display: {
                                            xs: 'block',
                                            sm: 'none'
                                        }
                                    }}>
                                    |
                                </Box>
                                {StoreFeelGreat.contents.termOfUse && (
                                    <Typography
                                        component={'a'}
                                        style={{
                                            color: 'white',
                                            fontFamily: 'Inter, NotoSansThai',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            textUnderlineOffset: '4px',
                                            fontSize: '10px'
                                        }}
                                        href={StoreTranslate.UseLanguage(StoreFeelGreat.contents.termOfUse.externalLink)}
                                        target={get(StoreFeelGreat, 'contents.termOfUse.openTarget', '')}>
                                        {StoreTranslate.UseLanguage(StoreFeelGreat.contents.termOfUse.title)}
                                    </Typography>
                                )}
                            </Box> */}
                            </Box>
                        )}
                    </div>
                )}
            </div>
        </ThemeProvider>
    )
})

const FooterStyled = styled.div`
    padding: 60px 135px 40px;
    box-sizing: border-box;
    @media screen and (max-width: 599px) {
        padding: 60px 50px 40px !important;
    }
    @media screen and (max-width: 1199px) {
        padding: 60px 60px 40px;
    }
`

export default Footer
