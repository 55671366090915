import httpHelper from 'Services/HttpHelper'
import { ServerEnv } from 'Configs/ServerEnv'
const GetProducts = ({ status = 'C' }) => {
    const url = `${ServerEnv.MemberCalls2()}/products-v2/publish/THA?allow=shop&status=${status}&warehouse=Main`

    return httpHelper.Get({
        url: url,
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

const getHotFormatV2 = (uuid, countryCode3Alpha, calc) => {
    const url = `${ServerEnv.MemberCalls2()}/hot/format/${countryCode3Alpha}/feelGreatUfg/detail-v2/${uuid}`

    return httpHelper.Get({
        url: url,
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

const putHotFormatV2 = (data, countryCode3Alpha) => {
    const url = `${ServerEnv.MemberCalls2()}/hot/formatV2/${countryCode3Alpha}/feelGreatReferral/address`
    return httpHelper.Put({
        url: url,
        data
    })
}

const checkDuplicateOrder = ({ countryCode3Alpha, uuid }) => {
    const url = `${ServerEnv.MemberCalls2()}/hot/payment_router/duplicateOrder/${countryCode3Alpha}/feelGreatUfg/${uuid}`

    return httpHelper.Get({
        url: url
    })
}

export const ReferralUaeAPIs = {
    GetProducts,
    getHotFormatV2,
    putHotFormatV2,
    checkDuplicateOrder
}
