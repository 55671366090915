import { Stack, ThemeProvider } from '@mui/material'
import { storeElement } from 'GlobalStores/StoreElement'
import CheckoutPage from './Uae/CheckoutPage'
import { useEffect } from 'react'
import trackingEvents from 'Services/Tracker/TrackingEvents'

const ReferralCheckoutPage = () => {

    useEffect(() => {
        trackingEvents.ViewItems(trackingEvents.EVENT_TYPE.VIEW_ITEM.toLowerCase())
        trackingEvents.AddToCart(trackingEvents.EVENT_TYPE.ADD_TO_CART.toLowerCase())
    }, [])

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack minHeight={'calc(100vh - 137px)'} bgColor={'#d2d9e1'}>
                <CheckoutPage />
            </Stack>
        </ThemeProvider>
    )
}

export default ReferralCheckoutPage
