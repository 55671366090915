import { get } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import StoreReferralCart from './StoreReferralCart'
import StoreReferralFormat from './StoreReferralFormat'

class StoreReferralProducts {
    products = []
    defaultProducts = []

    isShowProducts = false

    isInit = false

    Init(data) {
        const productList = get(data, 'productList.items')

        if (!productList) return

        runInAction(() => {
            this.products = productList
            const itemDefault = data.productList.defaultItem
            itemDefault.qty = 1
            StoreReferralCart.items = [itemDefault]
            StoreReferralFormat.paymentOptionSelected = data?.purchaseOptionDefault

            this.isInit = true
        })
    }

    GetIsInit() {
        return this.isInit
    }

    GetProducts() {
        return this.products
    }

    SetProducts(products) {
        this.products = products
    }

    SetShowProducts(value) {
        this.isShowProducts = value
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new StoreReferralProducts()
