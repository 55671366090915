import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, ThemeProvider, Tooltip, Typography, createTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useEffect, useLayoutEffect, useState } from 'react'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { reaction, runInAction } from 'mobx'
import { ReactComponent as LockIcon } from '../Forms/Assets/Lock.svg'
import Loader from '../Forms/Assets/Loader.svg'
import Loader2 from '../Forms/Assets/LoaderForModal.svg'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { ReactComponent as Exclamation } from '../Forms/Assets/exclamation.svg'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeReferralCheckout from '../../Stores/StoreReferralCheckout'
import { get } from 'lodash'
import storeUser from 'GlobalStores/User/StoreUser'
import CardFrontDrop from '../CardFrontDrop'

const AddressHistory = observer(() => {
    useEffect(() => {
        if (storeReferralCheckout.listAddress.length === 0) {
            storeReferralCheckout.GetAddressHistory()
        }

        const disposer = reaction(
            () => storeAuth.IsAuthorized(),
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    storeReferralCheckout.GetAddressHistory()
                }
            }
        )

        return () => {
            disposer()
        }
    }, [])

    return (
        <Stack>
            {storeReferralCheckout.changeAddress === true && <ModalSearchAddress />}

            {storeReferralCheckout.typeFormAddress === 'addressHistory' && (
                <ThemeSelector countryCapitalize={storeCountry.CountryCapitalize()}>
                    <PaperStyled className="address-history--dynamic-form">
                        <Stack width={'100%'}>
                            <Wrapper>
                                {storeReferralCheckout.loading === true ? (
                                    <Stack width={'100%'} alignItems={'center'}>
                                        <Spinner src={Loader2} alt="Loading" width={'20px'} />
                                    </Stack>
                                ) : (
                                    <>
                                        <Stack width={'100%'}>
                                            <TextFullName varian="caption2">{storeReferralCheckout.GetDisplayShipToName()}</TextFullName>
                                            <TextAddress varian="caption2">{storeReferralCheckout.GetDisplayMainAddress()}</TextAddress>
                                        </Stack>
                                        <Stack width={'20px'} alignItems={'center'}>
                                            <LockIcon />
                                        </Stack>
                                    </>
                                )}
                            </Wrapper>
                        </Stack>
                    </PaperStyled>
                </ThemeSelector>
            )}
        </Stack>
    )
})

const ModalSearchAddress = observer(props => {
    let DialogWrapper = Dialog
    const [loading] = useState(false)
    const [focusAddress, setFocusAddress] = useState(null)
    const [themeStyle] = useState(ModalSearchTheme)
    const translate = useTranslate()

    useEffect(() => {
        DialogWrapper = DefaultDialogWrapper
    }, [])

    const handleCloseModal = () => {
        runInAction(() => {
            storeReferralCheckout.changeAddress = false
        })
    }

    const handleChangeSelectAddress = (index, objAddressSelected) => {
        setFocusAddress(index)
        storeReferralCheckout.SetAddressHistorySelected(objAddressSelected)
    }

    const handleAddNewAddress = () => {
        runInAction(() => {
            storeReferralCheckout.typeFormAddress = 'new'
        })

        storeCheckout.GetShippingForm().map(item => {
            if (storeReferralCheckout.typeFormAddress === 'new' && storeReferralCheckout.passedToOrderSummary === false) {
                item.value = ''
            }
            if (item.key === 'country') {
                item.value = 'United Arab Emirates'
            }
            return item
        })

        handleCloseModal()
    }

    const handleSaveAddress = () => {
        storeReferralCheckout.UpdateDisplayMainAddress()
        handleCloseModal()

        runInAction(() => {
            storeReferralCheckout.typeFormAddress = 'addressHistory'
        })
    }

    return (
        <ThemeProvider theme={themeStyle}>
            <DialogWrapper disablePortal open={storeReferralCheckout.changeAddress} onClose={handleCloseModal} scroll="paper">
                <DialogTitle>
                    <Typography sx={{ color: '#5A8FC3', fontSize: '16px', cursor: 'pointer' }} onClick={() => handleAddNewAddress()}>
                        <T>add_new_address</T>
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    {storeReferralCheckout.loading === true ? (
                        <Stack width={'100%'} alignItems={'center'}>
                            <Spinner src={Loader2} alt="Loading" width={'20px'} />
                        </Stack>
                    ) : (
                        <>
                            {storeReferralCheckout.GetListHistoryAddress().map((addr, addrIndex) => {
                                const index = addrIndex + 1
                                const governmentId = get(storeUser.CustomerData(), 'userData.taxTerms.taxId')

                                const isDisabled =
                                    (!['SAR', 'UAE'].includes(addr.shipToAddress?.state) || !['UE'].includes(addr.shipToAddress?.country)) && !governmentId

                                return (
                                    <>
                                        {isDisabled ? (
                                            <Tooltip title={translate('add_new_address_with_government_id')} followCursor>
                                                <AddressDisplay
                                                    key={`addressHistory` + index}
                                                    onClick={() => {
                                                        if (isDisabled) return
                                                        handleChangeSelectAddress(index, addr)
                                                    }}
                                                    focused={index === focusAddress}
                                                    isDisabled={isDisabled}>
                                                    {isDisabled ? <CardFrontDropStyled /> : null}
                                                    {/* <Typography variant="h5">{addr.zip}</Typography> */}
                                                    <Typography variant="h5">{addr.ushopShipToName}</Typography>
                                                    <Typography variant="h5">{addr.displayAddress}</Typography>
                                                </AddressDisplay>
                                            </Tooltip>
                                        ) : (
                                            <AddressDisplay
                                                key={`addressHistory` + index}
                                                onClick={() => {
                                                    if (isDisabled) return
                                                    handleChangeSelectAddress(index, addr)
                                                }}
                                                focused={index === focusAddress}
                                                isDisabled={isDisabled}>
                                                {isDisabled ? <CardFrontDropStyled /> : null}
                                                {/* <Typography variant="h5">{addr.zip}</Typography> */}
                                                <Typography variant="h5">{addr.ushopShipToName}</Typography>
                                                <Typography variant="h5">{addr.displayAddress}</Typography>
                                            </AddressDisplay>
                                        )}
                                    </>
                                )
                            })}
                        </>
                    )}

                    {storeReferralCheckout.GetListHistoryAddress().length === 0 && storeReferralCheckout.loading === false && (
                        <Stack gap={'10px'} mt="20px" alignItems={'center'} flexDirection={'row'}>
                            <Exclamation />
                            <Typography sx={{ color: '#FF6187' }}>
                                <T>not_found_address_history</T>
                            </Typography>
                        </Stack>
                    )}
                </DialogContent>

                <DialogActions>
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                        <Button
                            onClick={handleCloseModal}
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            sx={{ textTransform: 'capitalize', fontWeight: '400' }}>
                            <T>back</T>
                        </Button>

                        <Button
                            disabled={!focusAddress}
                            onClick={handleSaveAddress}
                            className="ok-button"
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            sx={{ width: '145px', background: 'rgba(7, 159, 244, 1)', color: 'white', borderRadius: '32px' }}>
                            {loading ? <Spinner src={Loader} alt="Loading" /> : <T>ok</T>}
                        </Button>
                    </Box>
                </DialogActions>
            </DialogWrapper>
        </ThemeProvider>
    )
})

const ThemeSelector = ({ countryCapitalize, children }) => {
    const [theme, setTheme] = useState()

    useLayoutEffect(() => {
        if (!theme) {
            const themeName = `${countryCapitalize}FormTheme`
            import(`../Forms/Theme/${themeName}`).then(module => {
                setTheme(module.FormTheme)
            })
        }
    }, [countryCapitalize, theme])

    return <>{theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null}</>
}

const PaperStyled = styled(Paper)`
    padding-top: 20px !important;
    box-shadow: none;

    @media screen and (max-width: 699px) {
        .MuiBox-root {
            width: 100% !important;

            :nth-of-type(1),
            :nth-of-type(3),
            :nth-of-type(6) {
                margin-right: 0px !important;
            }

            :nth-of-type(6) {
                margin-bottom: 0px !important;
            }
        }
    }

    @media screen and (min-width: 700px) {
        padding-top: 30px !important;
    }
`

const Wrapper = styled.div`
    background: #f8fafc;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #ccdeef;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;

    svg {
        width: 13px;
        height: 20px;
    }
`

const TextFullName = styled(Typography)`
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: left;
`

const TextAddress = styled(Typography)`
    font-weight: 400;
    font-size: 14px;
    color: #8a9bb0;
    line-height: 24px;
    text-align: left;
`

const Spinner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

const AddressDisplay = styled(Stack)(({ theme, focused, isDisabled }) => {
    return {
        position: 'relative',
        border: focused ? '2px solid rgb(153, 188, 223)' : '1px solid rgb(229, 238, 247)',
        borderRadius: '12px',
        cursor: isDisabled ? '' : 'pointer',
        padding: '15px',
        margin: '10px 0',
        h5: {
            fontSize: '14px',
            color: focused ? '#003B6F' : '#93AABF',
            ':first-of-type': {
                fontSize: '18px',
                color: focused ? '#003B6F' : '#33628C',
                fontWeight: focused ? 700 : 400,
                marginBottom: '10px'
            }
        },
        ':first-of-type': {
            marginTop: '0px'
        }
    }
})

const DefaultDialogWrapper = styled(Dialog)(({ theme }) => {
    return {
        '.MuiDialog-paper': {
            '.MuiDialogTitle-root': {
                padding: '15px 20px',
                color: '#22aaec'
            }
        }
    }
})

const ModalSearchTheme = createTheme({
    typography: {
        fontFamily: 'Poppins, NotoSansThai !important',
        option: {
            fontSize: '1.833333em',
            fontFamily: 'Poppins, NotoSansThai !important',
            padding: '0.5em'
        }
    },
    components: {
        MuiDialog: {
            defaultProps: {
                PaperComponent: 'div'
            },
            styleOverrides: {
                paper: {
                    backgroundColor: '#f7f7f7',
                    width: '345px !important',
                    borderRadius: '12px',
                    margin: 0
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0px 15px 20px 15px',
                    zIndex: '2'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    padding: '20px 15px 20px 15px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    with: '100% !important',
                    fontFamily: 'Poppins, NotoSansThai !important',
                    color: 'rgba(51, 98, 140, 1)',
                    fontSize: '16px',

                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&.ok-button': {
                        width: '145px',
                        height: '55px',
                        padding: '18px 0',
                        fontWeight: 'bold',
                        fontSize: '24px',
                        background: '#5A8FC3',
                        color: 'white',
                        borderRadius: '32px',
                        '&:disabled': {
                            background: 'rgba(204, 222, 239, 1)',
                            color: 'white'
                        }
                    }
                }
            }
        }
    }
})

const CardFrontDropStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    display: inherit;
    z-index: 2;
    top: 0px;
    left: 0px;
`

export default AddressHistory
