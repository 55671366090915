import { createTheme } from '@mui/material'

const palette = {
    primary: {
        main: '#0B3B6F'
    },
    secondary: {
        main: '#33628C',
        disabled: '#FFFFFF'
    },
    muted: {
        main: '#8A9BB0'
    },
    info: {
        main: '#0BA4D4'
    },
    strike: {
        main: '#FF820E'
    },
    link: {
        main: '#153862'
    },
    background: {
        default: '#D1D9E2',
        main: '#D1D9E2'
    },
    button: {
        main: 'rgba(7, 159, 244, 1)',
        disabled: 'rgba(204, 222, 239, 1)'
    },
    black: {
        main: '#000000'
    },
    light: {
        main: '#FFFFFF'
    },
    dark: {
        main: '#000000'
    }
}

export const DefaultReferralTheme = createTheme({
    palette: palette,
    typography: {
        fontFamily: 'Poppins',
        h2: {
            fontSize: '24px',
            fontWeight: 700,
            color: palette.primary.main,
            fontStyle: 'normal',
            lineHeight: 'normal'
        },
        h3: {
            fontSize: '16px',
            fontWeight: 700,
            color: palette.primary.main,
            lineHeight: 'normal'
        },
        h4: {
            fontSize: '16px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal'
        },
        h5: {
            fontSize: '14px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal'
        },
        body1: {
            fontSize: '12px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal',
            fontFamily: 'Poppins'
        },
        caption: {
            fontSize: '12px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal',
            fontFamily: 'Poppins'
        },
        caption2: {
            fontSize: '12px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal',
            fontFamily: 'Poppins'
        },
        muted: {
            fontSize: '10px',
            fontWeight: 400,
            color: palette.muted.main,
            lineHeight: 'normal',
            fontFamily: 'Poppins'
        },
        strike: {
            fontSize: '10px',
            fontWeight: 400,
            color: palette.strike.main,
            lineHeight: 'normal',
            fontFamily: 'Poppins',
            textDecoration: 'line-through'
        },
        link: {
            fontWeight: 700,
            fontFamily: 'Poppins',
            color: palette.link.main
        },
        link2: {
            fontWeight: 500,
            fontSize: '14px',
            color: palette.link.main
        },
        link3: {
            fontWeight: 500,
            fontSize: '12px',
            fontFamily: 'Poppins',
            color: palette.link.main,
            textDecoration: 'underline'
        },
        error: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            color: '#FE5D7C'
        },
        label: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&.has-shadow': {
                        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '12px',
                    '.MuiCardContent-root': {
                        padding: '20px'
                    },
                    '&.products--product': {
                        borderRadius: '8px',
                        padding: '20px'
                    }
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    width: '380px',
                    boxShadow: 'none',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '8px',
                    background: '#5A8FC3',
                    '&.Mui-disabled': {
                        color: '#CCCCCC'
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#E9F1FA'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.MuiFromControlLabel-label': {
                        fontFamily: 'Poppins'
                    }
                }
            }
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontFamily: 'Poppins'
                }
            }
        }
    }
})
