import { makeAutoObservable } from 'mobx'

class storeOrderCalc {
    enabled = false

    subtotal = 0
    pv = 0
    total = 0

    shippingPrice = 0
    totalPrice = 0
    qty = 1

    purchaseOption = 'onetime'

    loadingOrderCal = false

    resetOrderCalc() {
        this.subtotal = 0
        this.pv = 0
        this.total = 0
        this.shippingPrice = 0
        this.totalPrice = 0
        this.qty = 1
        this.purchaseOption = 'onetime'
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const StoreOrderCalc = new storeOrderCalc()
export default StoreOrderCalc
