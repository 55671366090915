import {
    Box,
    Card,
    CardContent,
    Dialog,
    Paper,
    Skeleton,
    Stack,
    ThemeProvider,
    Typography
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useLayoutEffect, useState } from 'react'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { FormSelector } from './Forms/FormSelector'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { toJS } from 'mobx'

import { useNavigate } from 'react-router-dom'
import LoginBar from './LoginBar'

const FormInfo = observer(() => {
    const [loadingForm, setLoadingForm] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack>
                <Card
                    sx={{
                        width: {
                            xs: '100%',
                            sm: '100%',
                            lg: '400px'
                        }
                    }}>
                    <CardContent sx={{ padding: '40px 30px 30px 30px !important' }}>
                        <Stack flexDirection={'row'} gap={'10px'}>
                            <Typography lineHeight="42px" fontSize={24} fontWeight={700} color={'#003B6F'}>
                                <T>personal_info</T>
                            </Typography>
                        </Stack>

                        <ThemeSelector countryCapitalize={storeCountry.CountryCapitalize()}>
                            {loadingForm === true ? (
                                <SkeletonLoading />
                            ) : (
                                <PaperStyled className="info--dynamic-form">
                                    {!ObjectHelpers.isEmpty(storeAuth.IsAuthorized() ? storeCheckout.GetShippingForm() : storeCheckout.GetCheckoutForms()) &&
                                        (storeAuth.IsAuthorized() ? storeCheckout.GetShippingForm() : storeCheckout.GetCheckoutForms()).map(item => {
                                            if (
                                                item.key === 'zip_code' ||
                                                item.key === 'country' ||
                                                item.key === 'address1' ||
                                                item.key === 'address2' ||
                                                item.key === 'city' ||
                                                item.key === 'area_selection' ||
                                                item.key === 'city_selection' ||
                                                item.key === 'address' ||
                                                item.key === 'state_selection' ||
                                                item.key === 'street1' ||
                                                item.key === 'street2' ||
                                                item.key === 'province_selection' ||
                                                item.key === 'shipping_method_selection' ||
                                                item.key === 'sns_city_code' ||
                                                item.key === 'sns_mailbox_code' ||
                                                item.key === 'native_phone_number' ||
                                                item.key === 'government_id'
                                            ) {
                                                return false
                                            }

                                            if (storeAuth.IsAuthorized() && item.key === 'password') {
                                                return false
                                            }

                                            // if (item.type === 'select') {
                                            //     return item.type === 'text'
                                            // }

                                            return (
                                                <Box boxSizing={'border-box'} className="Input" key={item.key}>
                                                    {FormSelector(translate, item)}
                                                </Box>
                                            )
                                        })}
                                </PaperStyled>
                            )}
                        </ThemeSelector>

                        <Stack>{storeAuth.IsAuthorized() ? null : <LoginBar />}</Stack>
                    </CardContent>
                </Card>
            </Stack>
        </ThemeProvider>
    )
})

const SkeletonLoading = observer(() => {
    return (
        <StackSkeletonLoading mt="30px">
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
        </StackSkeletonLoading>
    )
})

const ThemeSelector = ({ countryCapitalize, children }) => {
    const [theme, setTheme] = useState()

    useLayoutEffect(() => {
        if (!theme) {
            const themeName = `${countryCapitalize}FormTheme`
            import(`./Forms/Theme/${themeName}`).then(module => {
                setTheme(module.FormTheme)
            })
        }
    }, [countryCapitalize, theme])

    return <>{theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null}</>
}

const PaperStyled = styled(Paper)`
    padding-top: 20px !important;
    box-shadow: none;

    @media screen and (max-width: 699px) {
        .MuiBox-root {
            width: 100% !important;

            :nth-of-type(1),
            :nth-of-type(3),
            :nth-of-type(6) {
                margin-right: 0px !important;
            }

            :nth-of-type(6) {
                margin-bottom: 0px !important;
            }
        }
    }

    @media screen and (min-width: 700px) {
        padding-top: 30px !important;
    }
`

const StackSkeletonLoading = styled(Stack)`
    @media screen and (min-width: 700px) {
        width: 600px;
    }

    @media screen and (min-width: 800px) {
        width: 700px;
    }
`

const StackAgreeAndVerify = styled(Stack)`
    padding-top: 20px;

    & > div {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 20px;
        & > div {
            width: 50%;
            justify-content: center;
        }
    }
`

const DialogStyled = styled(Dialog)`
    &.tax-invoice-dialog .MuiPaper-root:first-of-type {
        @media screen and (min-width: 768px) {
            min-width: 700px;
        }
    }
`

const CardWrapper = styled(Card)`
    width: 100%;

    @media screen and (min-width: 1180px) {
        width: 400px;
    }
`

export default FormInfo
