import httpHelper from 'Services/HttpHelper'
import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
export const GetReferralCode = token => {
    const url = httpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/referralcodes` })

    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-Application': `asia.ufg.${storeCountry.Country2()}`
        }
    })
}

export const validateEnrollSponsor = (market, enrollerID, sponsorID) => {
    const url = `${httpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/unishop-fn-misc/validator/enroller_sponsor` })}`

    return httpHelper.Post({
        url: url,
        data: {
            market: market,
            enrollerId: enrollerID,
            sponsorId: sponsorID
        },
        config: {
            headers: {
                'Content-Type': 'application/json',
                'X-Application': `asia.ufg.${storeCountry.Country2()}`
            }
        }
    })
}
