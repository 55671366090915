import { observer } from 'mobx-react-lite'
import { Stack, Typography, Card, Box, styled } from '@mui/material'
import DefaultInput from '../../Elements/Forms/DefaultInput'
import DefaultButton from '../../Elements/Forms/DefaultButton'
import { useEffect, useState } from 'react'
import Loader from '../Login/Assets/Loader.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import Errors from '../Errors/Errors'

// const option = [
//     {
//         title: 'Send Email',
//         value: 'Email'
//     },
//     {
//         title: 'Send SMS',
//         value: 'Sms'
//     }
// ]

const validationEmailSchema = yup.object({
    emailOrId: yup
        .string('Enter your email or UnicityID')
        .required('email_or_unicity_id_are_required')
        .test('test-name', 'Enter Valid Email or UnicityID', value => {
            //eslint-disable-next-line
            const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
            const numberRegex = /^()?\d{8,10}$/
            let isValidEmail = emailRegex.test(value)
            let isValidUID = numberRegex.test(value)
            if (!isValidEmail && !isValidUID) return false
            return true
        })
})

const validationAllSchema = yup.object({
    emailOrId: yup
        .string('Enter your email')
        .required('email_is_required')
        .test('test-name', 'Enter Valid Email', value => {
            //eslint-disable-next-line
            const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
            let isValidEmail = emailRegex.test(value)
            if (!isValidEmail) return false
            return true
        }),
    customerId: yup
        .string('Enter your Customer ID')
        .required('for_security')
        .test('test-name', 'Enter Valid Customer ID', value => {
            //eslint-disable-next-line
            const numberRegex = /^()?\d{8,10}$/
            let isValidUID = numberRegex.test(value)
            if (!isValidUID) return false
            return true
        })
})

const ResetPasswordForm = observer(() => {
    const [isLoading, setIsLoading] = useState(false)
    const [isShowCustomerId, setIsShowCustomerId] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const navigate = useNavigate()
    const translate = useTranslate()
    const formik = useFormik({
        initialValues: {
            emailOrId: '',
            customerId: '',
            type: 'Email'
        },
        validationSchema: () => {
            if (!isShowCustomerId) {
                return validationEmailSchema
            }
            return validationAllSchema
        },
        onSubmit: async values => {
            try {
                setIsLoading(true)
                const response = await StoreAuth.resetPassword(values)

                if (get(response, 'error_code', '') === '666') {
                    setIsShowCustomerId(true)
                    setIsLoading(false)
                    setShowErrorMessage(false)
                    return
                }

                if (response.code === 400 || response.code === 404) {
                    console.log('error')
                    setIsLoading(false)
                    setShowErrorMessage(true)
                    return
                }

                if (response.code === 500) {
                    console.log('error')
                    setIsLoading(false)
                    navigate('/reset-password-error')
                    return
                }

                setIsLoading(false)
                navigate('/reset-password-success')
            } catch (error) {
                console.log("%c ResetPassword Error", 'background-color: #7B0323; color: #fdfdfd; padding: 10px; font-size: 16px;', error)
                setIsLoading(false)
                navigate('/reset-password-error')

            }
        }
    })

    useEffect(() => {
        setShowErrorMessage(false)
    }, [formik.values.emailOrId])


    useEffect(() => {
        console.log('showErrorMessage changing', showErrorMessage)
    }, [showErrorMessage])
    return (
        <Card
            sx={{
                padding: '40px 30px',
                borderRadius: '12px',
                width: "100%",
                maxWidth: "650px",
                boxShadow: 'none',
                margin: '0 auto',
            }}>
            <Stack width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: '20px',
                        fontSize: '32px',
                        width: '100%',
                        textAlign: 'center'
                    }}>
                    <T>reset_password</T>
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        textAlign: {
                            xs: 'center',
                            sm: 'center'
                        },
                        color: '#5A8FC3',
                        width: '100%',
                        maxWidth: '452px',
                    }}>
                    <T>reset_password_description</T>
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                    autoComplete="off"
                    noValidate>
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={{
                            width: "100%",
                            maxWidth: "340px",
                            marginTop: '40px',
                            "div.MuiBox-root span": { display: "block", fontSize: "14px", lineHeight: "15px" },
                            "& input.MuiInputBase-input": { color: "#000", p: "15px" }
                        }}>
                        <DefaultInput
                            id="emailOrId"
                            name="emailOrId"
                            label={isShowCustomerId ? translate('email_address') : translate('email_address_or_unicity_id')}
                            value={formik.values.emailOrId}
                            fullWidth={true}
                            onBlur={formik.handleBlur}
                            error={formik.touched.emailOrId && Boolean(formik.errors.emailOrId)}
                            onChange={formik.handleChange}
                        // helpertext={!showErrorMessage && formik.touched.emailOrId ? translate(formik.errors.emailOrId) : ''}
                        />
                    </Box>

                    <Stack mt="20px" textAlign={"center"}>
                        <Typography variant='caption' component={"span"} sx={{ color: "#5A8FC3", lineHeight: "16px", letterSpacing: "normal" }}>
                            <T>send_link_reset_pwd_desc</T>
                        </Typography>
                    </Stack>


                    <Stack mt="20px" sx={{ "& >div.MuiStack-root": { mt: 0, pt: "12px", pb: "12px" }, "& .MuiStack-root .text_error_box:not(:last-of-type) .MuiBox-root": { pb: "12px", mb: "12px" }, width: "100%", maxWidth: "340px" }}>
                        {showErrorMessage ? (
                            <Errors list={[translate('email_or_customerId_is_invalid')]} />
                        ) : null}
                    </Stack>

                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: "306px",
                            mt: "20px"
                        }}
                    >
                        <DefaultButton
                            disabled={!(formik.isValid && formik.dirty)}
                            type="submit"
                            fullWidth={true}
                            titleText={isLoading ? <Spiner src={Loader} alt="Loading" /> : translate('submit')}
                        />
                    </Box>
                </form>

                <Stack
                    sx={{
                        cursor: 'pointer',
                        pt: '20px'
                    }}
                >
                    <Typography
                        sx={{ color: "#153862", fontSize: "14px", textTransform: "capitalize" }}
                        variant="label"
                        onClick={() => navigate("/login")}
                    >
                        <T>cancel</T>
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    )
})

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

const TextHeleperTypo = styled(Typography)(({ theme }) => ({
    fontStyle: 12,
    color: '#D03C3C',
    fontWeight: 500
}))

export default ResetPasswordForm
