import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import DefaultInput from './DefaultInput'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { Box, IconButton, InputAdornment, Stack, TextField, ThemeProvider, Typography } from '@mui/material'
import { storeElement } from 'GlobalStores/StoreElement'
import { DefaultInputStyled, DefaultLabelTypo } from './InputsStyled'
import { ReactComponent as PasswordOffIcon } from './Assets/password-off.svg'
import { ReactComponent as PasswordOnIcon } from './Assets/password-on.svg'
import { ReactComponent as SearchIcon } from './Assets/SearchIcon.svg'
import { ReactComponent as Dropdown } from './Assets/dropdown.svg'
import { isSomething } from 'Utils/Utils'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import storeReferralCheckout from '../../Stores/StoreReferralCheckout'
import { get } from 'lodash'

const DefaultFloatError = observer(props => {
    const { width } = useSizeWindow()
    const {
        label = '',
        value = '',
        fullWidth = true,
        onChange,
        onBlur = () => {},
        type = 'text',
        helpertext = '',
        paddingleft = 16,
        onKeyUp,
        placeholder = '',
        item,
    } = props

    const errorText = storeCheckout.checkoutErrorsFormInfo.filter(error => error.includes(label.toLowerCase()))
    
    return (
      <ThemeProvider theme={storeElement.theme}>
        <Box width={fullWidth ? '100%' : 'auto'}>
            {isSomething(label) && (
                <div style={{ marginBottom: '8px', paddingLeft: paddingleft }}>
                    <label>
                        <DefaultLabelTypo variant="label" style={{ color: errorText.length > 0 ? '#FF6187' : 'rgba(21, 56, 98, 0.5)', fontFamily: 'Poppins, NotoSansThai' }}>
                            {label}
                        </DefaultLabelTypo>
                    </label>
                </div>
            )}
            <DefaultInputStyled
                {...props}
                label={
                    <Typography variant="label" sx={{ color: 'rgba(21, 56, 98, 0.5)' }}>
                        {label}
                    </Typography>
                }
                value={value}
                helpertext={helpertext}
                variant="outlined"
                fullWidth={fullWidth}
                onKeyUp={onKeyUp}
                onChange={onChange}
                onBlur={onBlur}
                inputlabelprops={{
                    shrink: true
                }}
                inputProps={{
                    'aria-autocomplete': 'none',
                    autoComplete: 'new-password',
                    spellCheck: 'false',
                    ...props.inputProps
                }}
                placeholder={placeholder}
                sx={{
                    '& .MuiInputBase-input': {
                        border: errorText.length > 0 ? '2px solid #FF6187 !important' : '1px solid #CCDEEF !important',
                    },
                }}
            />
            {errorText.length > 0 && 
                <Stack mt={'8px'}>
                    <Typography variant="label" fontWeight={500} color={'#FD5C5C'} textAlign={'right'}>
                        {errorText}
                    </Typography>
                </Stack>
            }
        </Box>
      </ThemeProvider>
    )
  })

export default DefaultFloatError