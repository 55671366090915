import { observer } from 'mobx-react-lite'
import { Stack, createTheme, ThemeProvider, Typography, styled } from '@mui/material'
import ResetPasswordForm from '../Elements/ResetPassword/ResetPasswordForm'
import { storeElement } from 'GlobalStores/StoreElement'

const ResetPassword = observer(props => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <ResetPasswordWrapper>
                <ResetPasswordForm />
            </ResetPasswordWrapper>
        </ThemeProvider>
    )
})

export default ResetPassword

const ResetPasswordWrapper = styled(Stack)`
    * {
        box-sizing: border-box;
    }

    background-color: #d1d9e2;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: calc(100dvh - (64px + 60px)); // header + footer + referral bar + padding
    align-items: center;

    @media screen and (min-width: 768px) {
        padding-top: 40px;
    }
`
