import { ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

import './Invite.scss'
import ReferralLinks from './ReferralLinks/ReferralLinks'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'

const Invite = observer(props => {
    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep1 = <T>invite</T>
            storeElement.settingCard.title = ''
            storeElement.settingCard.padding = ''
        })

        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep1 = ''
                storeElement.settingCard.padding = ''
            })
        }
    }, [])

    return (
        <ThemeProvider theme={storeElement.theme}>
            <ReferralLinks />
        </ThemeProvider>
    )
})

export default Invite
