import { Select } from '@mobiscroll/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import DefaultInput from '../DefaultInput'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './BranchSelect.css'
import { ThemeProvider } from '@emotion/react'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import styled from '@emotion/styled'
import { ReactComponent as DropdownIcon } from '../Assets/dropdown.svg'
import { storeElement } from 'GlobalStores/StoreElement'

const BranchSelect = observer(props => {
    const selectRef = useRef(null)
    const [listForRender, setListForRender] = useState([])

    const renderProvinces = async () => {
        try {
            let list = [
                {
                    value: 'Main Office',
                    text: 'Main Office'
                }
            ]

            Array.from(Array(1000).keys()).map(i => {
                list.push({
                    value: `Branch 0000${i + 1}`,
                    text: `Branch 0000${i + 1}`
                })
                return null
            })

            setListForRender(list)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        renderProvinces()
    }, [])

    const handleOnClick = () => {
        selectRef.current?.click()
    }

    const onSelected = (event, inst) => {
        props.onSet(inst.getVal(true))
    }

    const { inputProps, endAdornment } = props

    return (
        <ThemeProvider theme={storeElement.theme}>
            <div style={{ display: 'none' }}>
                <Select
                    display="center"
                    touchUi={false}
                    filter={true}
                    filterPlaceholderText=""
                    height={40}
                    maxWidth={400}
                    data={listForRender}
                    onSet={onSelected}
                    value={props.value}
                    group={{
                        groupWheel: false,
                        header: false
                    }}
                    context={'#taxInvoiceDialog'}>
                    <input ref={selectRef} />
                </Select>
            </div>

            <CustomInputWithEndorment
                onClick={handleOnClick}
                value={props.value}
                label={props.label}
                type={'province'}
                inputProps={inputProps}
                endAdornment={endAdornment || <DropdownIcon />}
                onChange={props.onChange}
            />
        </ThemeProvider>
    )
})
const CustomInputWithEndorment = styled(DefaultInput)`
    .MuiInputAdornment-root {
        position: absolute;
        right: 15px;
    }
`

export default BranchSelect
