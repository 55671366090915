import { Backdrop, CircularProgress, Stack, ThemeProvider, Typography, styled } from '@mui/material'
import { DefaultReferralTheme } from './Theme/DefaultReferralTheme'
import { ReactComponent as Email } from './Uae/Assets/Svgs/email-icon.svg'
import { ReactComponent as Phone } from './Uae/Assets/Svgs/phone-icon.svg'
import { ReactComponent as Logo } from './Uae/Assets/Svgs/comingsoon-mlbs-logo.svg'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { useEffect, useState } from 'react'
import { Customers as CustomersApi } from 'Services/Cores/Customers/Customers'
import { get } from 'lodash'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const ComingSoonPage = () => {
    const [displayName, setDisplayName] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getReferralData()
    }, [])

    const getReferralData = async () => {
        const [, , referralCode] = window.location.pathname.split('/')
        if (!referralCode) return
        setLoading(true)
        try {
            const response = await CustomersApi.getAllowReferrer(referralCode)

            setDisplayName(StoreTranslate.UseLanguage(get(response, 'data.displayName', { english: '', native: '' })))
        } catch (e) {
            console.log('e', e)
        }
        setLoading(false)
    }

    return (
        <ThemeProvider theme={DefaultReferralTheme}>
            <ComingSoonPageStyled>
                <BackdropShow isOpen={loading} />
                <ContentWrapper>
                    <ComingSoonLogoWrapper>
                        <Logo />
                    </ComingSoonLogoWrapper>
                    <ComingSoonTitle>
                        <T>comingsoon_title</T>
                    </ComingSoonTitle>
                    <ComingSoonSubTitle>
                        <T>comingsoon_subtitle</T>
                    </ComingSoonSubTitle>
                    <ComingSoonDescription>
                        <T>comingsoon_desc</T>
                    </ComingSoonDescription>
                    <ComingSoonContactUsWrapper>
                        <ComingSoonContactUsItem>
                            <Email />
                            <ComingSoonContactUs>
                                <T>comingsoon_uae_email</T>
                            </ComingSoonContactUs>
                        </ComingSoonContactUsItem>
                        <ComingSoonContactUsItem>
                            <Phone />
                            <ComingSoonContactUs>
                                <T>comingsoon_uae_phone</T>
                            </ComingSoonContactUs>
                        </ComingSoonContactUsItem>
                    </ComingSoonContactUsWrapper>
                    <ComingSoonFooter>
                        <T>comingsoon_footer</T>
                    </ComingSoonFooter>
                    {displayName && (
                        <ComingSoonReferralName>
                            <T>referred_by</T> <span>{displayName}</span>
                        </ComingSoonReferralName>
                    )}
                </ContentWrapper>
            </ComingSoonPageStyled>
        </ThemeProvider>
    )
}

const BackdropShow = ({ isOpen }) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1
            }}
            open={isOpen}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

const ComingSoonPageStyled = styled(Stack)`
    box-sizing: border-box;
    background-color: #10203f;
    padding: 39px;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    * {
        font-family: Poppins !important;
        transition: all 0.5s ease-in-out;
    }
`

const ContentWrapper = styled(Stack)`
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const ComingSoonLogoWrapper = styled(Stack)`
    width: 100%;
    height: 100%;
    max-width: 200px;
    svg {
        max-width: 200px;
        max-height: 200px;
    }

    @media screen and (min-width: 768px) {
        max-width: 306px;
        svg {
            max-width: 306px;
            max-height: 306px;
        }
    }
`

const ComingSoonReferralName = styled(Typography)`
    margin-top: 24px;
    font-size: 12px !important;
    font-weight: 400;
    color: #fff;
    line-height: 30px;

    span {
        display: inline-block;
        font-weight: 700;
    }

    @media screen and (min-width: 768px) {
        margin-top: 32px;
        font-size: 20px !important;
    }
`

const ComingSoonTitle = styled(Typography)`
    margin-top: 32px;
    font-size: 36px !important;
    font-weight: 700;
    color: #fff;
    line-height: 54px;

    @media screen and (min-width: 768px) {
        font-size: 48px !important;
        line-height: 72px;
    }
`

const ComingSoonSubTitle = styled(Typography)`
    margin-top: 16px;
    font-size: 20px !important;
    font-weight: 400;
    color: #fff;
    line-height: 30px;

    @media screen and (min-width: 768px) {
        line-height: 48px;
        font-size: 32px !important;
    }
`

const ComingSoonDescription = styled(Typography)`
    margin-top: 24px;
    font-size: 14px !important;
    font-weight: 300;
    color: #99bcdf;
    line-height: 30px;

    @media screen and (min-width: 768px) {
        font-size: 20px !important;
        margin-top: 32px;
    }
`

const ComingSoonContactUsWrapper = styled(Stack)`
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media screen and (min-width: 834px) {
        gap: 32px;
        flex-direction: row;
        margin-top: 40px;
    }
`

const ComingSoonContactUs = styled(Typography)`
    font-size: 14px !important;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    text-align: left;

    @media screen and (min-width: 768px) {
        line-height: 30px;
        font-size: 20px !important;
    }
`

const ComingSoonContactUsItem = styled(Stack)`
    flex-direction: row;
    align-items: center;
    gap: 8px;
`

const ComingSoonFooter = styled(Typography)`
    margin-top: 24px;
    font-size: 12px !important;
    font-weight: 300;
    color: #99bcdf;
    line-height: 18px;

    @media screen and (min-width: 768px) {
        line-height: 24px;
        font-size: 16px !important;
        margin-top: 40px;
    }
`

export default ComingSoonPage
