import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Skeleton } from '@mui/material'
import storeReCaptcha from 'GlobalStores/StoreReCaptcha'
import { ReCAPTCHAV2_KEY, ReCAPTCHAV2QA_KEY } from 'Utils/Utils'

const ReCaptcha = observer(props => {
    const [reCaptchaValue, setReCaptchaValue] = useState(false)
    const [loading, setLoading] = useState(true)

    const RecaptchaKey = useMemo(() => {
        const liveDomain = ['ufg.expert']
        return liveDomain.includes(window.location.hostname) ? ReCAPTCHAV2_KEY : ReCAPTCHAV2QA_KEY

    }, [])

    const reCaptchaVerify = async token => {
        if (token === null) {
            setReCaptchaValue(false)
            return
        }
        setReCaptchaValue(true)
        return
    }
    const handleLoading = () => {
        const timeOut = setTimeout(setLoading(false), 1200)
        clearTimeout(timeOut)
    }

    useEffect(() => {
        props.handleReCaptCha(reCaptchaValue)
    }, [reCaptchaValue])

    useEffect(() => {
        setReCaptchaValue(false)
    }, [storeReCaptcha.currentLanguage])

    return (
        <>
            <ReCAPTCHA
                onChange={reCaptchaVerify}
                sitekey={RecaptchaKey}
                hl={storeReCaptcha.currentLanguage}
                asyncScriptOnLoad={handleLoading}
                style={{ display: loading ? 'none' : 'flex' }}
            />
            {loading && <Skeleton variant="rounded" width={'304px'} height={'78px'} sx={{ margin: '0', borderRadius: '12px' }} />}
        </>
    )
})

export default ReCaptcha
