import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'

export const GetFeelGreatProduct = async userStatus => {
    let options = {}
    if (userStatus) {
        options.params = {
            baStatus: userStatus
        }
    }
    const url = `${ServerEnv.MemberCalls2()}/products-v2/ufg/publish/${StoreCountry.Country3()}`

    return await HttpHelper.Get({ url, options })
}
