import { Stack, Typography, styled } from '@mui/material'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'

const SettingItemTemplate = observer((props) => {
    const { titleText, onClickChange, presentValue } = props

    const handleClick = (evt) => {
        evt.preventDefault()
        onClickChange()
    }

    return (
        <Section>
            <Stack
                sx={{
                    mb: '16px',
                    justifyContent: "space-between",
                    flexDirection: "row"
                }}>
                <Typography variant="h3" component="h3" sx={{ fontWeight: 500 }}>
                    {titleText}
                </Typography>

                <Typography variant='h3' component="a" sx={{ color: "#5A8FC3", textDecoration: "underline", cursor: "pointer", fontWeight: 500 }} onClick={handleClick}>
                    <T>change</T>
                </Typography>
            </Stack>
            <Stack>
                <Typography variant="subtitle1" component="p" color={'#8699AF'} fontSize={'14px'}>
                    {presentValue}
                </Typography>
            </Stack>
        </Section>
    )
})

export default SettingItemTemplate


const Section = styled(Stack)`
    @media screen and (max-width: 900px) {
        position: relative;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid #CCDEEF;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
  
`