import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    Skeleton,
    Stack,
    ThemeProvider,
    Typography
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useEffect, useLayoutEffect, useState } from 'react'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import Errors from 'Components/Elements/Errors/Errors'
import { DefaultButton } from './Forms'
import { FormSelector } from './Forms/FormSelector'
import { autorun, runInAction } from 'mobx'
import ReCaptcha from './ReCaptcha'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeUser from 'GlobalStores/User/StoreUser'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'

import { useNavigate } from 'react-router-dom'
import storeReferralCheckout from '../Stores/StoreReferralCheckout'
import TaxInvoice from './TaxInvoice/TaxInvoice'
import { ReactComponent as ExclaimationIcon } from './TaxInvoice/exclaim.svg'
import CheckBoxButton from './Forms/RadioButton/CheckboxButton'
import storeReferral from '../Stores/StoreReferral'
import { get } from 'lodash'
import AddressHistory from './AddressForms/AddressHistory'
import { isOnlySpecialCharactor } from 'Utils/Utils'
import MobileInput from './Forms/MobileInput/MobileInput'
import { uaeAreaCountries } from '../Helpers/UAEHelpers'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const ShippingAddressForm = observer(() => {
    const [loadingForm, setLoadingForm] = useState(false)
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const translate = useTranslate()
    const navigate = useNavigate()

    useEffect(() => {
        const disposer = autorun(() => {
            storeCheckout.checkoutErrors = []
            if (storeAuth.IsAuthorized()) {
                setLoadingForm(true)

                if (storeCheckout.GetShippingForm().length > 0) {
                    const country = storeUser.CustomerData().userData.mainAddress.country

                    storeCheckout.GetShippingForm().map(item => {
                        // if (item.key === 'firstname') {
                        //     const newFullName = storeFormat.GetDetailPage().firstname
                        //     item.value = newFullName ? newFullName : storeUser.CustomerData().userData.humanName.firstName
                        // }

                        // if (item.key === 'lastname') {
                        //     const newFullName = storeFormat.GetDetailPage().firstname
                        //     item.value = newFullName ? newFullName : storeUser.CustomerData().userData.humanName.lastName
                        // }

                        if (item.key === 'fullName') {
                            const newFullName = storeFormat.GetDetailPage().fullname
                            item.value = newFullName ? newFullName : get(storeUser.CustomerData().HumanName(), 'fullName')
                        }

                        if (item.key === 'email') {
                            const newEmail = storeFormat.GetDetailPage().email
                            item.value = newEmail ? newEmail : storeUser.CustomerData().Email()
                        }

                        if (item.key === 'address' && (country === 'UE' || storeFormat.GetDetailPage().address)) {
                            const newAddress = storeFormat.GetDetailPage().address
                            item.value = newAddress
                                ? newAddress
                                : storeUser.CustomerData().userData.mainAddress.address1 +
                                  (storeUser.CustomerData().userData.mainAddress.address2 ? ' ' : '') +
                                  storeUser.CustomerData().userData.mainAddress.address2
                        }

                        if (item.key === 'city_selection' && (country === 'UE' || storeFormat.GetDetailPage().city)) {
                            const newAddress = storeFormat.GetDetailPage().city
                            item.value = newAddress ? newAddress : storeUser.CustomerData().userData.mainAddress.city
                        }

                        if (item.key === 'area_selection' && (country === 'UE' || storeFormat.GetDetailPage().area)) {
                            const newAddress = storeFormat.GetDetailPage().area
                            item.value = newAddress ? newAddress : storeUser.CustomerData().userData.mainAddress.area
                        }

                        if (item.key === 'phone') {
                            const newCallingCode = storeFormat.GetDetailPage().country_calling_code
                            storeCheckout.SetPhoneNumberCode(newCallingCode)
                            if (country === 'UE') {
                                const newPhone = storeFormat.GetDetailPage().phone
                                item.value = newPhone || storeUser.CustomerData().MobilePhone() || ''
                            } else {
                                item.value = storeFormat.GetDetailPage().phone || ''
                            }
                        }

                        if (item.key === 'native_phone_number') {
                            if (country === 'UE') {
                                const newPhone = storeFormat.GetDetailPage()?.native_phone_number
                                item.value = newPhone || ''
                            } else {
                                item.value = storeFormat.GetDetailPage()?.native_phone_number || ''
                            }
                        }

                        if (item.key === 'province' && country === 'UE') {
                            const province = storeFormat.GetDetailPage().city
                            item.value = province ? province : storeUser.CustomerData().userData.mainAddress.city
                        }

                        if (item.key === 'zip_code' && country === 'UE') {
                            const zip_code = storeFormat.GetDetailPage().zip
                            item.value = zip_code ? zip_code : storeUser.CustomerData().userData.mainAddress.zip
                        }

                        if (item.key === 'house_number' && country === 'UE') {
                            const homePhone = storeFormat.GetDetailPage().homePhone
                            item.value = homePhone ? homePhone : storeUser.CustomerData().userData.homePhone
                        }

                        if (item.key === 'shipping_method_selection') {
                            const shippingMethod = storeFormat.GetDetailPage().shippingMethod || item.value
                            item.value = shippingMethod ? shippingMethod : StoreTranslate.IsEnglish() ? 'Standard shipping' : 'شحن قياسي'
                        }

                        if (item.key === 'government_id') {
                            const governmentId = storeFormat.GetDetailPage().government_id
                            item.value = governmentId ? governmentId : get(storeUser.CustomerData(), 'userData.taxTerms.taxId')
                        }

                        return item
                    })
                }
                if (storeReferralCheckout.typeFormAddress === 'new' && storeReferralCheckout.isInit === false) {
                    storeReferralCheckout.isInit = true
                }

                runInAction(() => {
                    storeReferralCheckout.typeFormAddress = 'new'
                })

                setLoadingForm(false)
            }
        })

        return () => {
            disposer()

            runInAction(() => {
                storeCheckout.checkoutErrors = []
                // StoreLoader.SetLoadFormatApi(false)
            })
        }
    }, [])

    const handleSubmit = async () => {
        try {
            runInAction(() => {
                storeCheckout.checkoutErrors = []
                storeElement.showCardFrontDrop = true
            })

            // Prevent address has only special characters
            const addressValue = get(
                storeCheckout.GetShippingForm().find(item => item.key === 'address'),
                'value',
                ''
            )
            if (isOnlySpecialCharactor(addressValue)) {
                storeCheckout.checkoutErrors = ['error_address_invalid_special_characters']
                throw new Error('error_address_invalid_special_characters')
            }

            const responseCreateUuid = await storeReferralCheckout.SubmitShippingForm()
            const uuid = responseCreateUuid.uuid

            if (responseCreateUuid.success === true) {
                navigate(`/checkout/payment?uuid=${uuid}`)

                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeCheckout.checkoutPreparing = false
                }, 1000)
            }

            if (responseCreateUuid.success === false) {
                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeCheckout.checkoutPreparing = false
                    storeCheckout.checkoutErrors = [...storeCheckout.checkoutErrors, ...responseCreateUuid.Message_v2]
                })
            }
        } catch (error) {
            console.log(error.message)
            runInAction(() => {
                storeElement.showCardFrontDrop = false
            })
        }
    }

    const getErrorMessages = () => {
        const countryValue = get(
            (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'country'),
            'value'
        )

        const uaeCountryObj = uaeAreaCountries(countryValue)

        const countryName = get(uaeCountryObj, 'name', '')

        return storeCheckout.checkoutErrors.map(item => {
            // Flat number/Building/Street address cannot be empty.
            let newItem = item
            if (item === 'error_address_street_empty') {
                switch (countryName) {
                    case 'Bahrain':
                        newItem = 'error_bhr_address_empty'
                        break
                    case 'Kuwait':
                        newItem = 'error_kwt_address_empty'
                        break
                    case 'Qatar':
                        newItem = 'error_qar_address_empty'
                        break
                    case 'Saudi Arabia':
                        newItem = 'error_sar_address_empty'
                        break
                    case 'United Arab Emirates':
                        newItem = 'error_uae_address_empty'
                        break
                    default:
                        newItem = 'error_address_empty'
                        break
                }
            }

            if (item === 'error_city_empty') {
                switch (countryName) {
                    case 'Bahrain':
                        newItem = 'error_bhr_city_empty'
                        break
                    case 'Kuwait':
                        newItem = 'error_kwt_city_empty'
                        break
                    case 'Qatar':
                        newItem = 'error_qar_city_empty'
                        break
                    case 'Saudi Arabia':
                        newItem = 'error_sar_city_empty'
                        break
                    default:
                        newItem = 'error_city_empty'
                        break
                }
            }

            return translate(newItem)
        })
    }
    
    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false)
            return
        }

        const countryValue = get(
            (storeCheckout.GetShippingForm() || []).find(item => item.key === 'country'),
            'value'
        )

        const uaeCountryObj = uaeAreaCountries(countryValue)

        const countryName = get(uaeCountryObj, StoreTranslate.IsEnglish() ? 'name' : 'native_name', '')

        const shippingMethod = StoreTranslate.IsEnglish() ? 'Standard shipping' : 'شحن قياسي'

        runInAction(() => {
            storeCheckout.SetCheckoutFormValue('country', countryName)
            storeCheckout.SetCheckoutFormValue('shipping_method_selection', shippingMethod)

            const isChainAddress = get(storeCheckout.CheckOutInstance().Instance(), 'chainAddress.isChainAddress')
            const groupAddress = get(storeCheckout.CheckOutInstance().Instance(), 'chainAddress.groupAddress', []) || []

            if (isChainAddress && groupAddress.includes('country')) {
                const keyToSetInit = groupAddress.slice(groupAddress.indexOf('country') + 1) || []
                keyToSetInit.forEach(key => storeCheckout.SetCheckoutFormValue(key, ''))
            }
        })
    }, [StoreTranslate.CurrentLanguage()])

    const isDisabledGovernmentId = () => {
        return get(storeUser.CustomerData(), 'userData.taxTerms.taxId') || storeFormat.GetDetailPage().government_id
    }

    const renderFormClass = () => {
        const countryValue = get(
            (storeCheckout.GetShippingForm() || []).find(item => item.key === 'country'),
            'value'
        )

        const uaeCountryObj = uaeAreaCountries(countryValue)

        const countryName = get(uaeCountryObj, 'name', '')

        const shippingMethodValue = get(
            (storeCheckout.GetShippingForm() || []).find(item => item.key === 'shipping_method_selection'),
            'value'
        )

        const isAramex = shippingMethodValue === 'Aramex with SNS'

        if (['United Arab Emirates'].includes(countryName) && storeCheckout.GetPhoneNumberCode() !== '+971') {
            return 'shipping-address4'
        } else if (['United Arab Emirates'].includes(countryName) && storeCheckout.GetPhoneNumberCode() === '+971') {
            return 'shipping-address1'
        } else if (['Saudi Arabia'].includes(countryName) && !isAramex) {
            return 'shipping-address2'
        } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName) && !isAramex) {
            return 'shipping-address1'
        } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName) && isAramex) {
            return 'shipping-address3'
        } else if (['Saudi Arabia'].includes(countryName) && !isAramex) {
            return 'shipping-address4'
        } else if (['Saudi Arabia'].includes(countryName) && isAramex) {
            return 'shipping-address3'
        } else {
            return 'shipping-address4'
        }
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Card>
                <FormWrapper>
                    {/* <Typography lineHeight="42px" fontSize={24} fontWeight={700} color={'#003B6F'}>
                        <T>shipping_address</T>
                    </Typography> */}

                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography lineHeight="42px" fontSize={24} fontWeight={700} color={'#003B6F'}>
                            <T>shipping_address</T>
                        </Typography>
                    </Stack>

                    <ThemeSelector countryCapitalize={storeCountry.CountryCapitalize()}>
                        {loadingForm === true ? (
                            <SkeletonLoading />
                        ) : (
                            <PaperStyled id="shipping-address-form" className={`shipping-address--dynamic-form ${renderFormClass()}`}>
                                {!ObjectHelpers.isEmpty(storeCheckout.GetShippingForm()) &&
                                    [...storeCheckout.GetShippingForm()]
                                        .sort((a, b) => {
                                            const countryValue = get(
                                                (storeCheckout.GetShippingForm() || []).find(item => item.key === 'country'),
                                                'value'
                                            )
                                            const uaeCountryObj = uaeAreaCountries(countryValue)

                                            const countryName = get(uaeCountryObj, 'name', '')

                                            if (['United Arab Emirates'].includes(countryName)) {
                                                return 0
                                            }
                                            const keyOrder = ['fullName', 'country', 'email', 'phone']
                                            const aKeyIndex = keyOrder.indexOf(a.key)
                                            const bKeyIndex = keyOrder.indexOf(b.key)

                                            const aKeyWeight = aKeyIndex === -1 ? Infinity : aKeyIndex
                                            const bKeyWeight = bKeyIndex === -1 ? Infinity : bKeyIndex

                                            return aKeyWeight - bKeyWeight
                                        })
                                        .map(item => {
                                            if (storeReferralCheckout.typeFormAddress === 'addressHistory' && !['email', 'phone'].includes(item.key)) {
                                                return false
                                            }

                                            const countryValue = get(
                                                (storeCheckout.GetShippingForm() || []).find(item => item.key === 'country'),
                                                'value'
                                            )

                                            const uaeCountryObj = uaeAreaCountries(countryValue)

                                            const countryName = get(uaeCountryObj, 'name', '')

                                            const shippingMethodValue = get(
                                                (storeCheckout.GetShippingForm() || []).find(item => item.key === 'shipping_method_selection'),
                                                'value'
                                            )

                                            const isAramex = shippingMethodValue === 'Aramex with SNS'

                                            let excludeKeys = ['fullName', 'phone', 'email']

                                            if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                                                if (isAramex) {
                                                    excludeKeys.push('area_selection', 'province_selection', 'city_selection', 'address', 'native_phone_number')
                                                } else {
                                                    excludeKeys.push(
                                                        'area_selection',
                                                        'province_selection',
                                                        'sns_city_code',
                                                        'sns_mailbox_code',
                                                        'native_phone_number'
                                                    )
                                                }
                                            } else if (['Saudi Arabia'].includes(countryName)) {
                                                if (isAramex) {
                                                    excludeKeys.push(
                                                        'area_selection',
                                                        'province_selection',
                                                        'city_selection',
                                                        'address',
                                                        'native_phone_number'
                                                    )
                                                } else {
                                                    excludeKeys.push(
                                                        'area_selection',
                                                        'sns_city_code',
                                                        'sns_mailbox_code',
                                                        'native_phone_number'
                                                    )
                                                }
                                            } else if (['United Arab Emirates'].includes(countryName)) {
                                                excludeKeys.push(
                                                    'province_selection',
                                                    'shipping_method_selection',
                                                    'sns_city_code',
                                                    'sns_mailbox_code',
                                                    'government_id'
                                                )
                                            }

                                            if (excludeKeys.includes(item.key)) {
                                                return false
                                            }

                                            if (item.key === 'government_id' && isDisabledGovernmentId()) item.disabled = true

                                            if (
                                                countryName === 'United Arab Emirates' &&
                                                item.key === 'native_phone_number' &&
                                                storeCheckout.GetPhoneNumberCode() === '+971'
                                            ) {
                                                return false
                                            }

                                            return (
                                                <Box boxSizing={'border-box'} className="Input" key={item.key}>
                                                    {item.key === 'native_phone_number' ? (
                                                        <MobileInput
                                                            currentValue={item.value}
                                                            onChange={value => {
                                                                storeCheckout.SetCheckoutFormValue(item.key, value)
                                                            }}
                                                            item={item}
                                                            InputProps={{
                                                                autoComplete: 'new-password',
                                                                autoCorrect: 'off',
                                                                spellCheck: 'false',
                                                                startAdornment: (
                                                                    <InputAdornment sx={{ marginRight: '5px' }} position="start">
                                                                        <Typography variant="label" fontSize={'14px'} fontWeight={'400'} color="#000000">
                                                                            +971
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    ) : (
                                                        FormSelector(translate, item)
                                                    )}
                                                </Box>
                                            )
                                        })}
                            </PaperStyled>
                        )}

                        {storeCheckout.GetCheckoutErrors().length > 0 && (
                            <Stack mt="40px">
                                <Errors list={getErrorMessages()} toTranslate={false} />
                            </Stack>
                        )}
                    </ThemeSelector>
                    <Stack mt={storeElement.dynamicWidth < 700 ? '30px' : '40px'} alignItems={'center'} gap="16px">
                        <DefaultButton
                            width={storeElement.dynamicWidth < 700 ? '100%' : '340px'}
                            titleText={translate('continue')}
                            isloading={storeElement.showCardFrontDrop.toString()}
                            onClick={handleSubmit}
                        />
                        <Typography 
                            variant='caption3' 
                            color={'#5A8FC3'} 
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => navigate('/')}
                        >
                            <T>back</T>
                        </Typography>
                    </Stack>
                </FormWrapper>
            </Card>
        </ThemeProvider>
    )
})

const SkeletonLoading = observer(() => {
    return (
        <StackSkeletonLoading mt="30px">
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
            <Stack flexDirection={storeElement.dynamicWidth < 700 ? 'column' : 'row'} gap={'20px'} justifyContent={'space-between'}>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
                <Stack width={'100%'}>
                    <Skeleton variant="text" sx={{ fontSize: '30px', width: '40%' }} />
                    <Skeleton variant="rounded" height={50} />
                </Stack>
            </Stack>
        </StackSkeletonLoading>
    )
})

const ThemeSelector = ({ countryCapitalize, children }) => {
    const [theme, setTheme] = useState()

    useLayoutEffect(() => {
        if (!theme) {
            const themeName = `${countryCapitalize}FormTheme`
            import(`./Forms/Theme/${themeName}`).then(module => {
                setTheme(module.FormTheme)
            })
        }
    }, [countryCapitalize, theme])

    return <>{theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null}</>
}

const PaperStyled = styled(Paper)`
    padding-top: 20px !important;
    box-shadow: none;

    @media screen and (max-width: 699px) {
        .MuiBox-root {
            width: 100% !important;

            :nth-of-type(1),
            :nth-of-type(3),
            :nth-of-type(6) {
                margin-right: 0px !important;
            }

            :nth-of-type(6) {
                margin-bottom: 0px !important;
            }
        }
    }

    @media screen and (min-width: 700px) {
        padding-top: 30px !important;
    }
`

const StackSkeletonLoading = styled(Stack)`
    @media screen and (min-width: 700px) {
        width: 600px;
    }

    @media screen and (min-width: 800px) {
        width: 700px;
    }
`

const FormWrapper = styled(Stack)`
    padding: 30px 20px !important;

    @media screen and (min-width: 1180px) {
        padding: 40px !important;
    }
`

const StackAgreeAndVerify = styled(Stack)`
    padding-top: 20px;

    & > div {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 20px;
        & > div {
            width: 50%;
            justify-content: center;
        }
    }
`

const DialogStyled = styled(Dialog)`
    &.tax-invoice-dialog .MuiPaper-root:first-of-type {
        @media screen and (min-width: 768px) {
            min-width: 700px;
        }
    }
`

export default ShippingAddressForm
