import mobiscroll, { Select } from '@mobiscroll/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import DefaultInput from '../DefaultInput'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import './ProvinceSelect.css'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { ThemeProvider } from '@emotion/react'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import styled from '@emotion/styled'
import { reaction } from 'mobx'
import { ReactComponent as DropdownIcon } from '../Assets/dropdown.svg'
import { storeElement } from 'GlobalStores/StoreElement'

const ProvinceSelect2 = observer(props => {
    const selectRef = useRef(null)
    const selectRefParent = useRef(null)
    const [listForRender, setListForRender] = useState([])

    const renderProvinces = async () => {
        try {
            let list = []

            const keyEnglish = 'city_roman'
            const keyNative = 'city_native'

            const { data } = await FeelGreatAPI.GetCityList()

            data.data.map(province => {
                const englishTitle = province[keyEnglish] ? province[keyEnglish].trim() : ''
                const nativeTitle = province[keyNative] ? province[keyNative].trim() : ''
                const title = StoreTranslate.CurrentLanguage() === 'EN' ? englishTitle : nativeTitle

                let multiLangTitle
                multiLangTitle = StoreTranslate.CurrentLanguage() === 'EN' ? `${englishTitle} (${nativeTitle})` : `${nativeTitle} (${englishTitle})`

                list.push({
                    value: title,
                    text: multiLangTitle ? multiLangTitle : title,
                    html:
                        StoreTranslate.CurrentLanguage() === 'EN'
                            ? `<span>${englishTitle}</span><span style="color:#9DA9B9;padding-left:6px;">(${nativeTitle})</span>`
                            : `<span>${nativeTitle}</span><span style="color:#9DA9B9;padding-left:6px;">(${englishTitle})</span>`
                })

                return null
            })

            const bangkokIndex = list.findIndex(l => l.value === 'Bangkok' || l.value === 'กรุงเทพมหานคร')

            // Move Bangkok to the top if found
            if (bangkokIndex !== -1) {
                const bangkokLocation = list.splice(bangkokIndex, 1)[0]
                list.unshift(bangkokLocation)
            }

            setListForRender(list)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        renderProvinces()

        const disposer = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    renderProvinces()
                }
            }
        )

        return () => {
            disposer()
        }
    }, [])

    const handleOnClick = () => {
        selectRef.current?.click()
    }

    const onSelected = (event, inst) => {
        props.onSet(inst.getVal(true))
    }

    const { inputProps, endAdornment, context } = props

    return (
        <ThemeProvider theme={storeElement.theme}>
            <div style={{ display: 'none' }}>
                <Select
                    className={props.provinceKey || 'province'}
                    key={props.provinceKey || 'province'}
                    display="center"
                    touchUi={false}
                    filter={true}
                    height={40}
                    maxWidth={400}
                    data={listForRender}
                    onSet={onSelected}
                    value={props.value}
                    group={{
                        groupWheel: false,
                        header: false
                    }}
                    filterPlaceholderText=''
                    context={'#taxInvoiceDialog'}
                    ref={selectRefParent}>
                    <input ref={selectRef} />
                </Select>
            </div>

            <CustomInputWithEndorment
                onClick={handleOnClick}
                value={props.value}
                label={props.label}
                inputProps={inputProps}
                endAdornment={endAdornment || <DropdownIcon />}
            />
        </ThemeProvider>
    )
})
const CustomInputWithEndorment = styled(DefaultInput)`
    .MuiInputAdornment-root {
        position: absolute;
        right: 15px;
    }
`

export default ProvinceSelect2
