import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { last } from 'lodash'
import HttpHelper from 'Services/HttpHelper'

const GetOrderHistory = (baId, token) => {
    const queryStrings = [`baId=${baId}`, `ushopCountryCode=${StoreCountry.Country3()}`]

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }

    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/etlV2/ordersHistory?${queryStrings.join('&')}`
    })

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${token}`
    }

    return axios.get(url, {
        headers: headers
    })
}

const GetOrderHistoryDetail = (token, orderRef) => {
    let ref = last(orderRef.split('/'))

    const queryStrings = [`orderRef=${ref}`, `ushopCountryCode=${StoreCountry.Country3()}`]

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }

    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/etlV2/orderDetail?${queryStrings.join('&')}`
    })

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${token}`
    }

    return axios.get(url, {
        headers: headers
    })
}

const GetSubscriptions = token => {
    // const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/autoorders` })
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/proxy/${StoreCountry.Country3()}/subscription/list` })

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const GetSubscriptionsItems = (token, href) => {
    const url = HttpHelper.Proxy({ originalUrl: href })

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Application': `asia.ufg.${StoreCountry.Country2()}`
        }
    })
}

const GetAddress = (shipmentOptions, shipToAddress) => {
    let data = {
        shipment_options: shipmentOptions,
        shipToAddress: shipToAddress
    }

    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/hot/format/${StoreCountry.Country3()}/shop/address-format` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const Creditcardaliases = (token, userCountry) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/creditcardaliases/${userCountry}` })

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Application': `asia.ufg.${StoreCountry.Country2()}`
    }

    return axios.get(url, { headers })
}

const GetPeriod = (countryCode2Alpha, joinPeriod) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/topup/period` })

    const data = {
        country: countryCode2Alpha,
        system: 'FG',
        joinPeriod: joinPeriod || ''
    }

    if (ServerEnv.ENV === 'DEV') {
        data.forceTopup = true
    }

    return axios.post(url, data, {
        Headers: {
            'Content-Type': 'applicaton/json'
        }
    })
}

const GetPaymentDetail = (paymentId, baId) => {
    const data = {
        payment_id: paymentId,
        type: baId ? 'shop' : 'enroll',
        ba_id: baId || ''
    }

    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/hot/payment_router/detail/hydra` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const SendEmailV3 = (data, countryCode3Alpha) => {
    let originalUrl = `${ServerEnv.MemberCalls2()}/email-api/email-v3/public/${countryCode3Alpha}/feelGreatUfg`

    switch (countryCode3Alpha) {
        case 'AUS_WILL_LVE':
            originalUrl = `${ServerEnv.MemberCalls2()}/email-api/email-fg-journey/public/${countryCode3Alpha}/feelGreat`
            break

        default:
            delete data['baStatus']
            delete data['action']
            delete data['purchaseType']

            originalUrl = `${ServerEnv.MemberCalls2()}/email-api/email-v3/public/${countryCode3Alpha}/feelGreatUfg`
            break
    }

    const emailApi = HttpHelper.Proxy({
        originalUrl: originalUrl
    })

    axios
        .post(emailApi, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {})
        .catch(error => console.error(error))
}

const SendEmailV4 = (data, countryCode3Alpha) => {
    let originalUrl = `${ServerEnv.MemberCalls2()}/email-api/email-v4/public/${countryCode3Alpha}/feelGreatUfg`

    switch (countryCode3Alpha) {
        case 'AUS_WILL_LVE':
            originalUrl = `${ServerEnv.MemberCalls2()}/email-api/email-fg-journey/public/${countryCode3Alpha}/feelGreat`
            break

        default:
            delete data['baStatus']
            delete data['action']
            delete data['purchaseType']

            originalUrl = `${ServerEnv.MemberCalls2()}/email-api/email-v4/public/${countryCode3Alpha}/feelGreatUfg`
            break
    }

    const emailApi = HttpHelper.Proxy({
        originalUrl: originalUrl
    })

    axios
        .post(emailApi, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {})
        .catch(error => console.error(error))
}

const CancelSubscription = (data, token) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/proxy/autoship-v2/delete` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const SkipDelivery = (data, token) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/proxy/autoship/skip` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const GetCreditAndArBalance = (baId, token) => {
    const queryStrings = [`baId=${baId}`, `ushopCountryCode=${StoreCountry.Country3()}`]

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/etlV2/credit/availableBalance?${queryStrings.join('&')}`
    })

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${token}`
        }
    })
}

const FormatAddress = data => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/hot/format/${StoreCountry.Country3()}/feelGreat/address-format`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const EditSubscriptions = (data, token) => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/proxy/${StoreCountry.Country3()}/subscriptions/edit`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const SendNowSubscriptions = (data, token) => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/proxy/autoship/order`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const CreatePaymentDataForHostedGateway = data => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/hot/payment_router/proxy/insert/${StoreCountry.Country3()}`
    })
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const GatewayPaymentDetail = gatewayRefId => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/hot/payment_router/detail/proxy`
    })
    const data = {
        gateway_ref_id: gatewayRefId,
        is_referral_code: true
    }

    return axios.post(url, data)
}

const GetGeoIPAccessAndBAID = ({ type = 'feelGreat', countryCode3Digit = StoreCountry.Country3(), baID = StoreAuth.GetId() }) => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/geoLocation/checkAllow/?platform=${type}&countryCode=${countryCode3Digit}&baId=${baID}`
    })
    return axios.get(url)
}

const GetCityList = (countryCode3Digit = StoreCountry.Country3()) => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/unishop-fn-misc/city/country/${countryCode3Digit}`
    })
    return axios.get(url)
}

const CreditCardCheck = data => {
    const url = HttpHelper.Proxy({
        originalUrl: `${ServerEnv.MemberCalls2()}/validate/creditCard/checkValidate`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const HydraWorldPayReqForm = (URL, CreditcardNumber) => {
    const url = HttpHelper.Proxy({ originalUrl: `${URL}/ddc` })
    return axios.post(
        url,
        JSON.stringify({
            bin: CreditcardNumber
        })
    )
}

const getPDPA = (countryCode3Digit = StoreCountry.Country3()) => {
    // This copy USHOP PDPA
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/hot/remoteStorage/data/ushop_pdpa_${countryCode3Digit}` })

    return axios.get(url)
}

const getAddressHistory = (countryCode3Digit = StoreCountry.Country3()) => {
    let token = StoreAuth?.GetToken()
    let baId = StoreAuth?.GetId()
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/etlV2/addressHistory?baId=${baId}&ushopCountryCode=${countryCode3Digit}` })

    const headers = {
        'authorization-hydra': `Bearer ${token}`
    }
    return axios.get(url, { headers: headers })
}

const saveAddressHistory = (shipToName, shipToAddress, countryCode3Digit = StoreCountry.Country3()) => {
    let token = StoreAuth?.GetToken()
    let baId = StoreAuth?.GetId()

    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/etlV2/addressHistory?baId=${baId}&ushopCountryCode=${countryCode3Digit}` })

    return axios.post(
        url,
        {
            shipToName: { ...shipToName },
            shipToAddress: { ...shipToAddress }
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${token}`
            }
        }
    )
}

const GetSubscriptionConfig = (countryCode3Digit = StoreCountry.Country3()) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/settings/subscriptionSettings/${countryCode3Digit}` })

    return axios.get(url)
}

const deleteCache = baId => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/etlV2/cache/clearAll?baId=${baId}` })
    return axios.delete(url)
}

export const FeelGreatAPI = {
    GetOrderHistory,
    GetOrderHistoryDetail,
    GetPeriod,
    GetPaymentDetail,
    Creditcardaliases,
    GetSubscriptions,
    GetAddress,
    SendEmailV3,
    SendEmailV4,
    CancelSubscription,
    SkipDelivery,
    GetCreditAndArBalance,
    FormatAddress,
    EditSubscriptions,
    SendNowSubscriptions,
    CreatePaymentDataForHostedGateway,
    GatewayPaymentDetail,
    GetGeoIPAccessAndBAID,
    GetCityList,
    CreditCardCheck,
    GetSubscriptionsItems,
    HydraWorldPayReqForm,
    getPDPA,
    getAddressHistory,
    saveAddressHistory,
    GetSubscriptionConfig,
    deleteCache
}
