import { Box, Card, CardContent, Stack, Typography, ThemeProvider, SvgIcon, createTheme, Modal, Fade } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { DefaultButton, DefaultInput } from './Forms'
import CloseIcon from '@mui/icons-material/Close'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { get } from 'lodash'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import Errors from './Errors'
import { ReactComponent as Exclamation } from './../../../Assets/exclamation.svg'
import storeReferralCheckout from '../Stores/StoreReferralCheckout'
import storeReferral from '../Stores/StoreReferral'
import ReCaptcha from './ReCaptcha'
import { runInAction } from 'mobx'
// import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
// import storeReferral from 'GlobalStores/Referral/StoreReferral'

const ModalLogin = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const [loginFormError, setLoginFormError] = useState([])
    const [reCaptchaVerify, setReCaptchaVerify] = useState(false)

    const validationSchema = yup.object({
        unicityID: yup.string().required(translate('unicity_id_required')),
        password: yup.string().min(4, translate('minimum_four_char_password')).required(translate('password_is_required')),
        keepLoggedIn: yup.boolean()
    })

    const formik = useFormik({
        initialValues: {
            unicityID: '',
            password: '',
            keepLoggedIn: false
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                storeAuth.loadingLogin = true
                const response = await storeAuth.Login(values)
                storeAuth.allowCredit = response.allowCredit
                let isHasCredit = false
                Object.keys(response.allowCredit).map((v, k) => {
                    if (v === 'enable' && response.allowCredit[v] === true) {
                        storeAuth.allowCredit = response.allowCredit
                        storeAuth.allowCredit.enable = true
                        isHasCredit = true
                    }
                    return ''
                })

                if (isHasCredit === true) {
                    storeCredit.Init({ id: storeAuth.GetId(), token: storeAuth.GetToken() })
                }

                // storeCheckout.SetUseMemberPrice(true)
                // storeReferral.reset()

                if (storeAuth.allowCredit?.enable) {
                    storeCredit.Init({ id: storeAuth.GetId(), token: storeAuth.GetToken() })
                }

                // if (props.onCheckout && !storeCheckout.GetIsLoginOnly()) {
                //     props.onCheckout()
                // } else if (['/checkout/create-account'].includes(location.pathname)) {
                //     StoreLoader.SetLoadFormatApi(true)
                //     navigate(`/checkout/shipping${location.search}`)
                // } else {
                //     if (location.pathname !== '/products') {
                //         navigate('/user')
                //     }
                // }

                props.setOpenLoginModal(false)
                window.scrollTo(0, 0)
                setTimeout(() => {
                    storeAuth.loadingLogin = false
                }, 500)
            } catch (e) {
                if (get(e, 'response.data.error.error.status') === 401) {
                    setErrors({ password: translate('login_or_password_incorrect') })
                } else {
                    if (e.message && e.message === 'user_status_not_allowed') {
                        setErrors({
                            password: translate(e.message)
                        })
                    } else {
                        setErrors({
                            password: get(e, 'response.data.displayError.message', '') || get(e, 'response.data.message', 'Server under maintain!')
                        })
                    }
                }
                storeAuth.loadingLogin = false
            }
        }
    })

    useEffect(() => {
        let error = []
        if (formik.touched.unicityID && Boolean(formik.errors.unicityID)) {
            error.push(formik.errors.unicityID)
        }

        if (formik.touched.password && Boolean(formik.errors.password)) {
            error.push(formik.errors.password)
        }

        setLoginFormError(error)
    }, [formik.errors, formik.touched])

    const handleReCaptCha = value => {
        setReCaptchaVerify(value)
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <LoginWrapper
                style={{
                    marginTop: '0px',
                    height: 'fit-content',
                    boxSizing: 'border-box'
                }}>
                <LoginStackWrapper
                    sx={{
                        borderBottomLeftRadius: {
                            xs: '12px',
                            sm: '12px',
                            md: '12px'
                        },
                        borderBottomRightRadius: {
                            xs: '12px',
                            sm: '12px',
                            md: '12px'
                        },
                        boxSizing: 'border-box'
                    }}>
                    <Stack padding={'40px 20px 0 20px'}>
                        <Typography variant="h2" align="center" fontSize={'32px'}>
                            {translate('log_in')}
                        </Typography>
                        <Stack
                            mt="30px"
                            mx="auto"
                            sx={{
                                width: {
                                    xs: '305px',
                                    sm: '370px',
                                    md: '370px'
                                }
                            }}>
                            <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                                <Box noValidate sx={{ maxWidth: '100%' }}>
                                    <DefaultInput
                                        autoComplete="off"
                                        id="unicityID"
                                        name="unicityID"
                                        label={translate('email_or_unicity_id')}
                                        value={formik.values.unicityID}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="email"
                                    />
                                </Box>
                                <Box noValidate sx={{ maxWidth: '100%' }} mt="10px">
                                    <DefaultInput
                                        autoComplete="current-password"
                                        id="password"
                                        name="password"
                                        label={translate('password')}
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </Box>

                                {loginFormError.length ? (
                                    <Stack mt="20px">
                                        <Errors list={loginFormError} />
                                    </Stack>
                                ) : null}

                                <StackAgreeAndVerify justifyContent={'center'}>
                                    <ReCaptcha handleReCaptCha={handleReCaptCha} />
                                </StackAgreeAndVerify>

                                <Box mt="20px" width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <DefaultButton
                                        disabled={!(formik.isValid && formik.dirty && !storeAuth.loadingLogin && reCaptchaVerify)}
                                        type="submit"
                                        fullWidth={true}
                                        titleText={translate('log_in')}
                                        width="275px"
                                        isloading={storeAuth.loadingLogin.toString()}
                                        sx={{
                                            fontFamily: storeElement.theme.typography.fontFamily
                                        }}
                                    />
                                </Box>
                            </form>

                            <Box
                                mt={'20px'}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '40px'
                                }}>
                                <Typography
                                    variant="link"
                                    fontSize={'14px'}
                                    fontWeight={500}
                                    onClick={() => {
                                        if (storeAuth.loadingLogin) return
                                        navigate('/reset-password')
                                    }}
                                    sx={{ cursor: storeAuth.loadingLogin ? 'not-allowed !important' : 'pointer' }}>
                                    <T>forgot_password</T>
                                </Typography>
                            </Box>

                            {/* {loginFormError.length ? (
                                <Stack mt="20px">
                                    <Errors list={loginFormError} />
                                </Stack>
                            ) : null} */}

                            {/* <Box mt="20px" width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
                                <DefaultButton
                                    disabled={!(formik.isValid && formik.dirty && !storeAuth.loadingLogin)}
                                    type="submit"
                                    fullWidth={true}
                                    titleText={translate('log_in')}
                                    width="275px"
                                    isloading={storeAuth.loadingLogin.toString()}
                                    sx={{
                                        fontFamily: storeElement.theme.typography.fontFamily,
                                    }}
                                />
                            </Box>
                            </form> */}
                        </Stack>
                    </Stack>

                    {storeReferral.HasReferral() === false && (
                        <Box sx={{ background: '#FFF1DC', borderRadius: '0 0 12px 12px' }} p={storeElement.dynamicWidth < 700 ? '20px' : '20px 50px 20px 50px'}>
                            <Stack flexDirection={'row'}>
                                <Stack justifyContent={'center'} paddingRight={'20px'}>
                                    <Exclamation />
                                </Stack>

                                <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                                    <Typography variant="caption2" fontSize={'14px'}>
                                        <T>footer_login_description</T>
                                    </Typography>

                                    <Typography mt="5px" variant="caption2" sx={{ color: '#33628C', fontSize: '12px', lineHeight: '18px' }}>
                                        <T>footer_login_sub_description</T>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                </LoginStackWrapper>
            </LoginWrapper>
        </ThemeProvider>
    )
})

const LoginWrapper = styled(Stack)`
    width: 100%;
    @media screen and (min-width: 900px) {
        padding: ${({ isLoginPage }) => (isLoginPage ? '0px 10%' : '')};
    }

    @media screen and (min-width: 1200px) {
        padding: ${({ isLoginPage }) => (isLoginPage ? '0px 20%' : '')};
    }

    @media screen and (min-width: 1600px) {
        padding: ${({ isLoginPage }) => (isLoginPage ? '0px 30%' : '')};
    }
`

const LoginStackWrapper = styled(Stack)`
    background-color: #fff;
    border-radius: 12px;
    height: fit-content;
    width: 100%;

    @media screen and (min-width: 700px) {
        width: 516px;
    }
`

const StackAgreeAndVerify = styled(Stack)`
    & > div {
        width: 100%;
        margin: 20px auto 0 auto;
        justify-content: center;
    }
`

const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 700, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF',
            disabled: '#FFFFFF'
        },
        button: {
            disabled: '#CDDDED'
        }
    },
    typography: {
        fontFamily: storeElement.theme.typography.fontFamily,
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: storeElement.theme.typography.fontFamily,
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px'
        },
        label: {
            color: '#94A2B4',
            fontSize: 12,
            fontWeight: 500
        },
        link: {
            cursor: 'pointer',
            textAlign: 'center',
            fontFamily: storeElement.theme.typography.fontFamily,
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: storeElement.theme.typography.fontFamily,
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        },
        caption2: {
            fontFamily: storeElement.theme.typography.fontFamily,
            fontStyle: 'normal',
            textAlign: 'left',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#003764'
        }
    }
})

export default ModalLogin
