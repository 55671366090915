import { Card, CardContent, Stack, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import ShippingAddressForm from './ShippingAddressForm'
import storeAuth from 'GlobalStores/User/StoreAuth'
import CreateAccountForm from './CreateAccountForm'
import { storeElement } from 'GlobalStores/StoreElement'
import ModalNoReferral from './ModalNoReferral'
import storeReferral from 'GlobalStores/Referral/StoreReferral'
import Login from './Login'
import CardOrderNow from './CardOrderNow'
import FormInfo from './FormInfo'

const ContactAddressForm = observer(() => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <MainWrapper>

                <StackContainer>

                    {/* <FormInfo /> */}
                    {!storeAuth.IsAuthorized() ? <FormInfo /> : <FormInfo />}

                    <FormWrapper>
                        {!storeAuth.IsAuthorized() ? <CreateAccountForm /> : <ShippingAddressForm />}
                    </FormWrapper>

                </StackContainer>

                {storeAuth.IsAuthorized() || storeReferral.HasReferral() ? null : <Login />}

                <ModalNoReferral />

            </MainWrapper>
        </ThemeProvider>
    )
})

const MainWrapper = styled(Stack)`
    background-color: #d2d9e1;
    padding: 20px;
    min-height: calc(100dvh - 64px);
    flex-direction: row;
    justify-content: center;

    @media screen and (min-width: 1180px) {
        padding: 20px 20px 80px 20px;
    }
`

const StackContainer = styled(Stack)`
    flex-direction: column;
    gap: 20px;

    @media screen and (min-width: 1180px) {
        flex-direction: row;
        max-width: 1200px;
        grid-row-gap: 20px;
        row-gap: 20px;
        margin-top: 20px;
    }
`

const FormWrapper = styled(Stack)`
    width: 100%;
    @media screen and (min-width: 1180px) {
        min-width: 705px;
    }
`

export default ContactAddressForm
