import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { get, isEmpty, camelCase, omit } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import Payment from './Checkout/Payment'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreCredit from 'GlobalStores/StoreCredit'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { getOSName } from 'Helpers/Bowser'
import storeReferral from './StoreReferral'
import storeUser from 'GlobalStores/User/StoreUser'
import StoreReferralCart from './StoreReferralCart'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { PaymentAPIs } from './Checkout/PaymentServices/PaymentAPI'
import { ReferralUaeAPIs } from '../Services/ReferralUaeAPIs'
import storeCredit from './StoreCredit'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreReferralFormat from './StoreReferralFormat'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import UAECountryData from '../Components/Forms/Data/UAECountry.json'
import CheckoutLoaderStore from 'GlobalStores/Checkout/CheckoutLoader'
import { uaeAreaCountries } from '../Helpers/UAEHelpers'
import trackingEvents from 'Services/Tracker/TrackingEvents'

class StoreCheckout {
    shippingMethod = 'delivery'

    translate = useTranslate()

    uuid = ''

    tokenForPurchase = ''

    paymentMethod = 'creditcard'

    creditCard = []

    isReadyToPlaceOrder = false

    paymentLoading = false

    paymentErrors = []

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    typeFormAddress = 'addressHistory'
    changeAddress = false
    displayMainAddress = {}
    addressHistorySelected = {}
    listAddress = []
    loading = false
    passedToOrderSummary = false
    isInit = false

    paymentFromEnroll = false

    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'Delivery',
            dict_key: 'delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    value: 'creditcard',
                    dict_key: 'ref_creditcard',
                    for_status: [],
                    icon: 'creditcard-icon'
                }
                // {
                //     id: 2,
                //     title: 'QR payment',
                //     value: 'qr_payment',
                //     dict_key: 'qr_payment',
                //     descripion: {
                //         title: '',
                //         dict_key: 'qr_payment'
                //     },
                //     for_status: [],
                //     icon: 'pp-icon'
                // }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    requestTaxInvoice = false
    invoiceGovernmentId = ''
    invoiceTaxId = ''
    invoiceType = 'individual'
    invoiceForms = []
    invoiceFormsErrors = []
    invoiceInformation = {}
    currentAddressCountry = ''

    GetPaymentLoading() {
        return this.paymentLoading
    }

    UpdateDisplayMainAddress() {
        this.displayMainAddress = this.GetAddressHistorySelected()
    }

    GetDisplayShipToName() {
        return this.displayMainAddress?.ushopShipToName
    }

    GetDisplayMainAddress() {
        return this.displayMainAddress?.displayAddress
    }

    GetDataMainAddress() {
        return this.displayMainAddress
    }

    GetListHistoryAddress() {
        return this.listAddress
    }

    SetAddressHistorySelected(obj) {
        this.addressHistorySelected = obj
    }

    GetAddressHistorySelected() {
        return this.addressHistorySelected
    }

    async GetAddressHistory() {
        runInAction(() => {
            this.loading = true
            storeElement.showCardFrontDrop = true
        })
        try {
            if (storeAuth.IsAuthorized()) {
                const response = await FeelGreatAPI.getAddressHistory()
                const governmentId = get(storeUser.CustomerData(), 'userData.taxTerms.taxId')

                if (response.data) {
                    const filterItemsByCountry = response.data.items
                        ?.filter(item => {
                            return (
                                item.shipToAddress?.country === (storeCountry.Country2() === 'AE' ? 'UE' : storeCountry.Country2()) &&
                                !!item.shipToAddress?.state
                            )
                        })
                        .sort((a, b) => {
                            if (governmentId) return 0
                            const states = ['UAE', 'SAR']
                            const aIsPriority = states.includes(a.shipToAddress?.state)
                            const bIsPriority = states.includes(b.shipToAddress?.state)

                            if (aIsPriority && !bIsPriority) {
                                return -1
                            } else if (!aIsPriority && bIsPriority) {
                                return 1
                            } else {
                                return 0
                            }
                        })

                    if (this.GetAddressHistorySelected().addressHref) {
                        this.displayMainAddress = this.GetAddressHistorySelected()
                    } else {
                        this.displayMainAddress = response.data.mainAddress || get(filterItemsByCountry, '0', null)
                    }

                    const needToFillGovernmentId = filterItemsByCountry.every(item => {
                        return (!['SAR', 'UAE'].includes(item.shipToAddress?.state) || !['UE'].includes(item.shipToAddress?.country)) && !governmentId
                    })

                    runInAction(() => {
                        if (filterItemsByCountry.length === 0 || needToFillGovernmentId) {
                            this.typeFormAddress = 'new'
                        }
                        this.listAddress = filterItemsByCountry
                        this.loading = false
                        storeElement.showCardFrontDrop = false
                    })
                }
            }
        } catch (err) {
            runInAction(() => {
                this.loading = false
                storeElement.showCardFrontDrop = false
            })
            console.log(err)
        }
    }

    async SaveAddressHistory(form) {
        const countryObject = UAECountryData.find(item => item.name.toLowerCase() === form.country.toLowerCase())
        try {
            const data = {
                shipToAddress: {
                    address1: `${form.address}` || '',
                    address2: [form.province, form.area].join(' ') || '',
                    city: form.city || '',
                    country: storeCountry.Country2() === 'AE' ? 'UE' : storeCountry.Country2(),
                    state: countryObject?.country3 || '',
                    zip: form.zip || ''
                },
                shipToName: {
                    fullName: form.fullname,
                    firstName: form.fullname,
                    lastName: ''
                }
            }

            await FeelGreatAPI.saveAddressHistory(data.shipToName, data.shipToAddress)
        } catch (error) {
            console.log('ERROR ADD ADDRESS HISTORY', error)
        }
    }

    GetInvoiceValue(key) {
        const item = this.invoiceForms.find(item => item.key === key)
        if (item) {
            return item.value
        }

        return ''
    }

    SetInvoiceValue(key, value) {
        this.invoiceForms.map(item => {
            if (item.key === key) {
                item.value = value
            }
            return item
        })
    }

    GetTaxInvoiceValues() {
        let values = {}
        this.invoiceForms.map(item => {
            values[item.key] = item.value
            return item
        })

        return values
    }
    //
    checkoutLoading = false
    checkoutPreparing = true
    dataReady = false

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetShippingMethod() {
        return this.shippingMethods.find(item => item.api_value === this.shippingMethod)
    }

    GetShippingMethods() {
        return this.shippingMethods
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    SetPaymentMethod(value) {
        // if (!value) {
        //     this.GetCreditCardForm().map(item => {
        //         console.log('item', item)
        //         return this.SetCreditCard(item, '')
        //     })
        // }
        this.paymentMethod = value
    }

    GetPaymentMethod() {
        return this.paymentMethod
    }

    SetCreditCard(itemToSet, value) {
        this.creditCard.map(item => {
            if (item.key === itemToSet.key) {
                if (value.length === 0) {
                    itemToSet.value = ''
                } else {
                    if (item.args) {
                        const regx = get(itemToSet, 'args.regx', null)
                        let regexPass = false
                        if (regx) {
                            const regex = new RegExp(regx, 'i')
                            if (regex.test(value)) {
                                regexPass = true
                            }
                        } else {
                            regexPass = true
                        }

                        const maxLength = get(itemToSet, 'args.maxLength', null)
                        let maxLengthPass = false
                        if (maxLength) {
                            if (value.length <= maxLength) {
                                maxLengthPass = true
                            }
                        } else {
                            maxLengthPass = true
                        }

                        if (regexPass && maxLengthPass) {
                            item.value = value
                        }
                    } else if (itemToSet.key === 'exp_date') {
                        // item.value = moment(value).format('MM/YY')
                        item.value = [value.month, value.year.slice(2, value.year.length)].join('/')
                    } else {
                        item.value = value
                    }
                }
            }

            return item
        })
        this.IsReadyToPlaceOrder()
    }

    GetCreditCardForm() {
        return this.creditCard
    }

    CreditCardEncrypted = (CreditCard, email) => {
        let rearrangeExpDate = CreditCard.creditCardExpires.split('/')
        rearrangeExpDate = `${parseInt(rearrangeExpDate[1]) + 2000}-${rearrangeExpDate[0]}`

        const newCountry = UAECountryData.find(item => item.name === get(storeFormat.GetDetailPage(), 'country', ''))

        return {
            type: 'AuthorizeAndCapture',
            method: 'CreditCard',
            amount: 'this.terms.total',
            methodDetails: {
                payer: CreditCard.payer,
                creditCardNumber: CreditCard.creditCardNumber,
                creditCardExpires: rearrangeExpDate,
                creditCardSecurityCode: CreditCard.creditCardSecurityCode
            },
            billToAddress: {
                country: !['AE', 'UE'].includes(storeCountry.Country2()) ? storeCountry.Country2() : newCountry ? newCountry.country2 : storeCountry.Country2()
            },
            billToEmail: email
        }
    }

    IsReadyToPlaceOrder() {
        let allFilled = []
        this.creditCard.map(item => {
            if (item.value.length > 0) {
                allFilled.push(true)
            } else {
                allFilled.push(false)
            }
            return item
        })

        this.isReadyToPlaceOrder = !ObjectHelpers.hasFalseElements(allFilled)
    }

    SetErrors(value) {
        runInAction(() => {
            this.paymentErrors = value
        })
    }
    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    // async SubmitPayment({ uuid = '', detailPage, CreditCardEncrypted = {}, products, type = '' }) {
    //     try {
    //         const Pay = new Payment(uuid, detailPage)
    //         const responsePreparePayment = await Pay.PreparePaymentData()

    //         let preparedData = responsePreparePayment?.data?.data

    //         const payments = this.getPaymentData({ preparedData, type, CreditCardEncrypted, Pay })

    //         const allowCreditEnable = StoreAuth.IsAllowCredit()

    //         if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
    //             Pay.hydra.transactions = {
    //                 items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
    //             }
    //         } else {
    //             Pay.hydra.transactions = {
    //                 items: [CreditCardEncrypted]
    //             }
    //         }

    //         let oneTime = await Pay.SubmitOneTimePayment({ payments, type }) // Main

    //         // if (!StoreAuth.isAuthorized && oneTime.hasOwnProperty('is_processing_payment') && oneTime.is_processing_payment) {
    //         //     const formCheckout = storeCheckout.InitializeFormShippingData('enroll')
    //         //     const createCustomerRes = await storeFormAddress.SubmitCreateAccountOnly()
    //         //     const loginResponse = await PaymentAPIs.CustomerLogin(createCustomerRes.ba_id, formCheckout.password)
    //         //     StoreAuth.token = loginResponse.data.token
    //         //     StoreAuth.id = createCustomerRes.ba_id

    //         //     const readyToCreateData = {
    //         //         referenceNo: oneTime.referenceNo,
    //         //         status: 'waiting',
    //         //         type: 'feelGreatReferral',
    //         //         token: `Bearer ${loginResponse.data.token}`,
    //         //         ba_id: createCustomerRes.ba_id
    //         //     }
    //         //     const readyToOrderRes = await PaymentAPIs.UpdateReadyToCreateOrder(readyToCreateData)
    //         // }

    //         if (!oneTime) {
    //             return { success: false }
    //         }

    //         if (Pay.autoship) {
    //             await Pay.AutoshipTHA() // Just Autoship don't need to order to hydra again
    //         }

    //         await Pay.SendEmailTHA()

    //         return {
    //             success: true,
    //             payment_id: oneTime?.invoiceNo || responsePreparePayment.data.data.payment_id,
    //             order_number: responsePreparePayment.data.data.payment_id,
    //             payment_ref: oneTime?.paymentRef
    //         }
    //     } catch (error) {
    //         console.error(error)
    //         runInAction(() => {
    //             StorePaymentStep.PaymentDone = true
    //         })

    //         return { success: false }
    //     }
    // }

    async SubmitPayment({ uuid = '', detailPage, CreditCardEncrypted = {}, products, type = '' }) {

        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            const allowCreditEnable = StoreAuth.GetToken() ? StoreAuth.IsAllowCredit() : null

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }

            if (!StoreAuth.GetToken() && !this.tokenForPurchase) {
                runInAction(() => {
                    CheckoutLoaderStore.orderApiProgress.hydraCreateAccount = 1
                })
                const createAccountResponse = await this.SubmitCreateAccountOnly()
                // const loginResponse = await PaymentAPIs.CustomerLogin(createAccountResponse.ba_id, createAccountResponse.password)
                // StoreAuth.token = loginResponse.data.token
                // StoreAuth.id = createAccountResponse.ba_id
                // await StoreAuth.Login({ unicityID: createAccountResponse.ba_id, password: createAccountResponse.password, isPayent: true })
                runInAction(() => {
                    CheckoutLoaderStore.orderApiProgress.hydraCreateAccount = 2
                })
                this.paymentFromEnroll = true
                runInAction(() => {
                    CheckoutLoaderStore.orderApiProgress.login = 1
                })
                const loginResponse = await PaymentAPIs.CustomerLogin(createAccountResponse.ba_id, createAccountResponse.password)
                this.tokenForPurchase = loginResponse.data.token
                runInAction(() => {
                    CheckoutLoaderStore.orderApiProgress.login = 2
                })
                // StoreAuth.token = loginResponse.data.token
                // StoreAuth.id = createAccountResponse.ba_id
            }

            // await Pay.PostToHydraAndCreateAccount()
            await Pay.PostToHydra({ token: this.tokenForPurchase })

            Pay.SendEmail()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            try {
                trackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: storeCountry.GetCurrencyCode(),
                    category: StoreReferralFormat.paymentOptionSelected,
                    items: products,
                    eventType: trackingEvents.EVENT_TYPE.PURCHASE.toLowerCase(),
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    getPaymentData({ preparedData, type, CreditCardEncrypted, Pay }) {
        if (!preparedData) return []

        let data = []

        const allowCreditEnable = get(StoreAuth, 'allowCredit.enable', false)

        if (type === 'qr') {
            const qrPayment = {
                method: 'qrPayment',
                amount: Number(preparedData.ushop.total_price)
            }
            data.push(qrPayment)

            if (allowCreditEnable && StoreCredit.GetCreditData()?.creditUsed > 0) {
                const productCredit = StoreCredit.GetCreditData()
                const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                data.push(useCredit)
            }
        } else {
            // Prepare credit card data
            let creditCardData = {
                amount: preparedData.ushop.total_price
            }

            if (!isEmpty(CreditCardEncrypted)) {
                creditCardData['method'] = camelCase(CreditCardEncrypted.method)
                creditCardData['payer'] = CreditCardEncrypted.methodDetails.payer
                creditCardData['creditCardNumber'] = CreditCardEncrypted.methodDetails.creditCardNumber
                creditCardData['creditCardSecurityCode'] = CreditCardEncrypted.methodDetails.creditCardSecurityCode
                creditCardData['creditCardExpires'] = CreditCardEncrypted.methodDetails.creditCardExpires
            }

            data.push(creditCardData)
            // END Prepare credit card data

            // When customer have E-Credit

            if (allowCreditEnable && StoreCredit.GetCreditData()?.creditUsed > 0) {
                const productCredit = StoreCredit.GetCreditData()
                const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                data.push(useCredit)
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, useCredit]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }
        }

        return data
    }

    GetPaymentErrors() {
        const filteredError = this.paymentErrors.reduce((acc, current) => {
            return [...acc, this.translate(current)]
        }, [])
        return filteredError
    }

    async CreateUUID(moreStep = false) {
        try {
            runInAction(() => (this.dataReady = false))
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())

            const productItems = StoreReferralCart.getCartItems()

            const formatType = StoreAuth.IsAuthorized() ? 'shop' : 'enroll'
            const data = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName()
            }

            if (storeCountry.CountryLowerCase() === 'singapore') {
                data['shipment_options'] = 'delivery'
            }

            if (storeReferral.HasReferral()) {
                data.referral_id = storeReferral.Customer().CustomerId.toString()
            }

            if (StoreAuth.IsAuthorized()) {
                data.ba_status = StoreAuth.userStatus
                data.login_id = StoreAuth.GetId().toString()
                data.login_name = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName')
                data.login_name_native = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName')
                data.token = StoreAuth.GetToken()
            }

            const response = await PaymentAPIs.CreateUuid(data, storeCountry.Country3())

            return {
                uuid: response.data.uuid,
                period: data.entryPeriod
            }
        } catch (e) {
            runInAction(() => {
                this.dataReady = true
            })
            console.error(e)
        }
    }

    async ContinueToPayment(uuid, form) {
        const response = {
            success: false
        }
        try {
            if (StoreAuth.IsAuthorized()) {
                const formatType = 'shop'
                storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetShippingForm())

                const data = storeCheckout.InitializeFormShippingData(formatType)
                if (uuid) {
                    data.uuid = uuid
                }

                delete data.period
                data.type = 'shop'

                if (this.shippingMethod) {
                    data.shipment_options = this.shippingMethod.api_value
                }

                if (StoreAuth.IsAuthorized()) {
                    data['ba_status'] = StoreAuth.userStatus
                    data['login_id'] = StoreAuth.GetId().toString()
                    data['login_name'] = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName')
                    data['login_name_native'] = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName')
                    data['token'] = StoreAuth.GetToken()
                    if (data.confirm_password) {
                        delete data.confirm_password
                    }
                    if (data['country']) {
                        delete data['country']
                    }

                    delete data.fullname
                    delete data.address
                    delete data.city
                    delete data.state
                    delete data.token
                }

                const updateResponse = await ReferralUaeAPIs.putHotFormatV2(data, storeCountry.Country3())
                if (updateResponse.data.success === false) {
                    response.success = false
                    runInAction(() => {
                        // this.checkoutErrors = updateResponse.data.Message
                        this.checkoutErrors = updateResponse.data.Message_v2
                    })
                } else {
                    this.checkoutErrors = []
                    response.success = true
                }
            }
        } catch (e) {
            if (StoreAuth.IsAuthorized()) {
                if (!StoreAuth.GetId()) {
                    window.location.reload()
                }
            } else {
                response.success = false
                runInAction(() => (this.dataReady = true))
                console.log(e)
            }
        }
        return response
    }

    async SubmitShippingForm(fromQR = false) {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()

            const formatType = 'shop'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetShippingForm())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                login_id: get(storeUser.CustomerData().userData, 'id.unicity', ''),
                login_name: storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName'),
                login_name_native: storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName'),
                ba_status: get(storeAuth, 'userStatus', ''),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                email: formCheckout.email,
                phone: formCheckout.phone,
                token: formCheckout.token,
                shipment_options: 'delivery',
                selectAddressHistory: this.typeFormAddress,
                country_calling_code: storeCheckout.GetPhoneNumberCode()
            }

            const countryValue = get(formCheckout, 'country')
            const shippingMethodValue = get(formCheckout, 'shipping_method')
            const uaeCountryObj = uaeAreaCountries(countryValue)
            const countryName = get(uaeCountryObj, 'name', '')

            const isAramex = shippingMethodValue === 'Aramex with SNS'

            if (isAramex) {
                defaultDataValidate.shipment_options = 'aramex'
            }

            if (this.typeFormAddress === 'addressHistory') {
                const data = {
                    fullname: get(this.GetDataMainAddress(), 'ushopShipToName', ''),
                    shipToAddress: get(this.GetDataMainAddress(), 'shipToAddress', ''),
                    country: 'United Arab Emirates'
                }
                Object.assign(defaultDataValidate, data)
            }

            if (this.typeFormAddress === 'new') {
                Object.assign(defaultDataValidate, formCheckout)
            }

            const excludeKeys = ['shipping_method']

            if (['United Arab Emirates'].includes(countryName)) {
                excludeKeys.push('province', 'sns_city_code', 'sns_mailbox_code', 'government_id')
                if (countryName === 'United Arab Emirates' && defaultDataValidate.country_calling_code === '+971') {
                    excludeKeys.push('native_phone_number')
                }
            } else if (['Saudi Arabia'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'province', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            }

            const isGIDexist = get(storeUser.CustomerData(), 'userData.taxTerms.taxId') || storeFormat.GetDetailPage().government_id ? 1 : 0

            let responseCreateUuid = await PaymentAPIs.CreateUuid(omit(defaultDataValidate, excludeKeys), storeCountry.Country3(), isGIDexist)

            if (responseCreateUuid.data.success === true) {
                runInAction(() => {
                    this.SetUuid(responseCreateUuid.data.uuid)
                    this.passedToOrderSummary = true
                    if (this.typeFormAddress === 'new' && !fromQR) {
                        this.SaveAddressHistory(formCheckout)
                    }
                })
            }

            if (responseCreateUuid) {
                return responseCreateUuid.data
            }
        } catch (err) {
            console.log('ERROR SubmitShippingForm', err)
        }
    }

    async UpdateItemdata() {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            let productItems = StoreReferralCart.getCartItems()
            productItems.map(item => {
                if (item.item_code === '32015') {
                    item.option = 'onetime'
                }
                return item
            })

            productItems = productItems.filter(item => item.qty > 0)

            const formatType = 'shop'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetShippingForm())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                login_id: get(storeUser.CustomerData().userData, 'id.unicity', ''),
                login_name: storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName'),
                login_name_native: storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName'),
                ba_status: get(storeAuth, 'userStatus', ''),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                email: formCheckout.email,
                phone: formCheckout.phone,
                token: formCheckout.token,
                shipment_options: 'delivery',
                selectAddressHistory: this.typeFormAddress,
                country_calling_code: storeCheckout.GetPhoneNumberCode(),
                uuid: this.uuid
            }

            const countryValue = get(formCheckout, 'country')
            const shippingMethodValue = get(formCheckout, 'shipping_method')

            const isAramex = shippingMethodValue === 'Aramex with SNS'

            if (isAramex) {
                formCheckout.shipment_options = 'aramex'
            }

            if (this.typeFormAddress === 'addressHistory') {
                const data = {
                    fullname: get(this.GetDataMainAddress(), 'ushopShipToName', ''),
                    shipToAddress: get(this.GetDataMainAddress(), 'shipToAddress', '')
                }
                Object.assign(defaultDataValidate, data)
            }

            if (this.typeFormAddress === 'new') {
                Object.assign(defaultDataValidate, omit(formCheckout, ['uuid']))
            }

            const excludeKeys = ['shipping_method']

            const uaeCountryObj = uaeAreaCountries(countryValue)

            const countryName = get(uaeCountryObj, 'name', '')

            if (['United Arab Emirates'].includes(countryName)) {
                excludeKeys.push('province', 'sns_city_code', 'sns_mailbox_code', 'government_id')
                if (countryName === 'United Arab Emirates' && defaultDataValidate.country_calling_code === '+971') {
                    excludeKeys.push('native_phone_number')
                }
            } else if (['Saudi Arabia'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number', 'government_id')
                } else {
                    excludeKeys.push('area', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number', 'government_id')
                }
            } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'province', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            }

            let omitedData = omit(defaultDataValidate, excludeKeys)

            if (omitedData.shipToAddress === '') {
                omitedData.shipToAddress = storeFormat.GetDetailPage().shipToAddress
            }

            if (omitedData.fullname === '') {
                omitedData.fullname = storeFormat.GetDetailPage().fullname
            }

            await PaymentAPIs.UpdatePurchaseType(omitedData, storeCountry.Country3())

            // if (responseCreateUuid.data.success === true) {
            //     runInAction(() => {
            // storeReferralCheckout.SetUuid(responseCreateUuid.data.uuid)
            // this.passedToOrderSummary = true
            //     })
            // }

            // if (responseCreateUuid) {
            //     return responseCreateUuid.data
            // }
        } catch (err) {
            console.log('ERROR SubmitShippingForm', err)
        }
    }

    async UpdateItemdataCreateAccount() {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()
            productItems.map(item => {
                if (item.item_code === '32015') {
                    item.option = 'onetime'
                }
                return item
            })

            const formatType = 'enroll'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetCheckoutForms())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                shipment_options: 'delivery',
                selectAddressHistory: 'new',
                country_calling_code: storeCheckout.GetPhoneNumberCode(),
                referral_id: storeReferral.HasReferral().toString(),
                uuid: this.uuid
            }

            delete formCheckout.fullName
            delete formCheckout.state
            delete formCheckout.uuid
            delete formCheckout.zip

            const countryValue = get(formCheckout, 'country')
            const shippingMethodValue = get(formCheckout, 'shipping_method')

            const isAramex = shippingMethodValue === 'Aramex with SNS'

            if (isAramex) {
                formCheckout.shipment_options = 'aramex'
            }

            Object.assign(defaultDataValidate, formCheckout)

            const excludeKeys = ['shipping_method']

            const uaeCountryObj = uaeAreaCountries(countryValue)

            const countryName = get(uaeCountryObj, 'name', '')

            if (['United Arab Emirates'].includes(countryName)) {
                excludeKeys.push('province', 'sns_city_code', 'sns_mailbox_code', 'government_id')
                if (countryName === 'United Arab Emirates' && defaultDataValidate.country_calling_code === '+971') {
                    excludeKeys.push('native_phone_number')
                }
            } else if (['Saudi Arabia'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number', 'government_id')
                } else {
                    excludeKeys.push('area', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number', 'government_id')
                }
            } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'province', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            }
            let omitedData = omit(defaultDataValidate, excludeKeys)

            if (omitedData.shipToAddress === '') {
                omitedData.shipToAddress = storeFormat.GetDetailPage().shipToAddress
            }

            if (omitedData.fullname === '') {
                omitedData.fullname = storeFormat.GetDetailPage().fullname
            }

            await PaymentAPIs.UpdatePurchaseType(omitedData, storeCountry.Country3())

            // if (responseCreateUuid.data.success === true) {
            //     runInAction(() => {
            // storeReferralCheckout.SetUuid(responseCreateUuid.data.uuid)
            // this.passedToOrderSummary = true
            //     })
            // }

            // if (responseCreateUuid) {
            //     return responseCreateUuid.data
            // }
        } catch (err) {
            console.log('ERROR SubmitShippingForm', err)
        }
    }

    async SubmitCreateAccountForm() {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()

            const formatType = 'enroll'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetCheckoutForms())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            storeCheckout.CheckOutInstance().FormToFormat('shop', storeCheckout.GetShippingForm())
            storeCheckout.InitializeFormShippingData('shop')

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                shipment_options: 'delivery',
                selectAddressHistory: 'new',
                country_calling_code: storeCheckout.GetPhoneNumberCode(),
                referral_id: storeReferral.HasReferral().toString()
            }

            delete formCheckout.address1
            delete formCheckout.address2
            delete formCheckout.fullName
            delete formCheckout.state
            delete formCheckout.uuid
            delete formCheckout.zip

            const countryValue = get(formCheckout, 'country')
            const shippingMethodValue = get(formCheckout, 'shipping_method')
            const uaeCountryObj = uaeAreaCountries(countryValue)
            const countryName = get(uaeCountryObj, 'name', '')

            Object.assign(defaultDataValidate, formCheckout)

            const isAramex = shippingMethodValue === 'Aramex with SNS'

            if (isAramex) {
                defaultDataValidate.shipment_options = 'aramex'
            }

            const excludeKeys = ['shipping_method']

            if (['United Arab Emirates'].includes(countryName)) {
                excludeKeys.push('province', 'sns_city_code', 'sns_mailbox_code', 'government_id')
                if (countryName === 'United Arab Emirates' && defaultDataValidate.country_calling_code === '+971') {
                    excludeKeys.push('native_phone_number')
                }
            } else if (['Saudi Arabia'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'province', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            }

            let responseCreateUuid = await PaymentAPIs.CreateUuid(omit(defaultDataValidate, excludeKeys), storeCountry.Country3())

            if (responseCreateUuid.data.success === true) {
                runInAction(() => {
                    storeReferralCheckout.SetUuid(responseCreateUuid.data.uuid)
                    this.passedToOrderSummary = true
                })
            }

            if (responseCreateUuid) {
                return responseCreateUuid.data
            }
        } catch (err) {
            console.log('ERROR SubmitShippingForm', err)
        }
    }

    async SubmitCreateAccountOnly() {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()

            const formatType = 'enroll'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetCheckoutForms())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                shipment_options: 'delivery',
                country_calling_code: storeCheckout.GetPhoneNumberCode(),
                referral_id: storeReferral.HasReferral().toString(),
                uuid: this.uuid
            }

            delete formCheckout.address1
            delete formCheckout.address2
            delete formCheckout.fullName
            delete formCheckout.state
            delete formCheckout.uuid
            delete formCheckout.zip

            Object.assign(defaultDataValidate, formCheckout)

            const countryValue = get(formCheckout, 'country')
            const shippingMethodValue = get(formCheckout, 'shipping_method')

            const isAramex = shippingMethodValue === 'Aramex with SNS'

            if (isAramex) {
                defaultDataValidate.shipment_options = 'aramex'
            }

            const excludeKeys = ['shipping_method']

            const uaeCountryObj = uaeAreaCountries(countryValue)

            const countryName = get(uaeCountryObj, 'name', '')

            if (['United Arab Emirates'].includes(countryName)) {
                excludeKeys.push('province', 'sns_city_code', 'sns_mailbox_code', 'government_id')
                if (countryName === 'United Arab Emirates' && defaultDataValidate.country_calling_code === '+971') {
                    excludeKeys.push('native_phone_number')
                }
            } else if (['Saudi Arabia'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                if (isAramex) {
                    excludeKeys.push('area', 'province', 'city', 'address', 'native_phone_number')
                } else {
                    excludeKeys.push('area', 'province', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                }
            }

            let responseCreateUuid = await PaymentAPIs.CreateAccountOnly(omit(defaultDataValidate, excludeKeys), storeCountry.Country3())

            if (responseCreateUuid.data.success === true) {
                return {
                    ba_id: responseCreateUuid.data.ba_id,
                    password: defaultDataValidate.password
                }
            }

            // if (responseCreateUuid) {
            //     return responseCreateUuid.data
            // }
        } catch (err) {
            console.log('ERROR SubmitShippingForm', err)
        }
    }

    CheckOutofStockCartItems() {
        // return true mean out of stock
        const cartItems = StoreReferralCart.items

        if (cartItems?.length > 0) {
            const outOfStockItems = []

            cartItems.forEach(item => {
                const optionSelected = item?.options?.[StoreReferralFormat.paymentOptionSelected]
                const isFoundOOSTag = optionSelected?.system_tags?.some(tag => tag.name === 'out_of_stock')

                if (isFoundOOSTag) {
                    const itemName = StoreTranslate.UseLanguage(optionSelected?.item_name)
                    outOfStockItems.push(itemName)
                }
            })

            return outOfStockItems
        } else {
            return []
        }
    }

    GetCurrentAddressCountry = () => {
        return this.currentAddressCountry
    }

    SetCurrentAddressCountry = value => {
        this.currentAddressCountry = value
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

const storeReferralCheckout = new StoreCheckout()

export default storeReferralCheckout
