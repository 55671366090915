import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import HttpHelper from 'Services/HttpHelper'

const domain = ServerEnv.MemberCalls2()

const putHotFormatV2 = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address`
    console.log('putHotFormatV2')
    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const createUuid = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

const putAddress = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreatUfg/address`
    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const putAddressAddItemQty = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address?isSkipValidate=1`

    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const getHotFormatV2 = (uuid, countryCode3Alpha, calc) => {
    const url = `${domain}/hot/format/${countryCode3Alpha}/feelGreatUfg/detail-v2/${uuid}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

const postHotFormatWithConfirmPassword = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/formatV2/${countryCode3Alpha}/feelGreat/address?password2=1`

    return axios.put(HttpHelper.Proxy({ originalUrl: url }), data)
}

const validateAddress = (data, countryCode3Alpha) => {
    const url = `${domain}/hot/format/${countryCode3Alpha}/shop/v2/address`

    return axios.post(HttpHelper.Proxy({ originalUrl: url }), data)
}

export const FormatAPIs = {
    putAddressAddItemQty,
    getHotFormatV2,
    putHotFormatV2,
    createUuid,
    putAddress,
    postHotFormatWithConfirmPassword,
    validateAddress
}
