import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, Box, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { useEffect } from 'react'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import LogoPayment from 'Components/Elements/LogoPayment/LogoPayment'
import CustomDialogV2 from '../Dialog/CustomDialogV2'
import { ReactComponent as SpinnerIcon } from '../Assets/button-icon-spinner.svg'
import { runInAction } from 'mobx'
import { NumberHelpers } from 'Helpers/NumberHelpers'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeReferral from 'Components/Pages/Referral/Uae/Stores/StoreReferral'
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ItemSubscriptionsMobile = observer(props => {
    return (
        <>
            <Stack gap="20px" width="100%">
                {props.order.map((items, k) => (
                    <ItemProducts key={k} products={items} payment={props.payment} getOrder={props.getOrder} />
                ))}
            </Stack>
        </>
    )
})

const ItemProducts = ({ products, payment, getOrder }) => {
    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [typeDialog, setTypeDialog] = useState('')
    const [flavorQTY, setFlavorQTY] = useState([])
    const [address, setAddress] = useState('')
    const [skipDate, setSkipDate] = useState('')

    const navigate = useNavigate()
    const translate = useTranslate()

    const isShowEditSubscription = (key = '') => {
        if (!storeReferral.GetSubscriptionConfigData('settings.isEnable')) {
            return !['JPN'].includes(storeCountry.Country3())
        }

        return storeReferral.GetSubscriptionConfigData(`${key}.isEnable`)
    }

    const nextShipDate = new Date(products?.recurrence?.dateStarts).toLocaleDateString('en-uk', { year: 'numeric', month: 'short', day: 'numeric' })
    const creditCardNetwork = payment[payment.length - 1]?.methodDetails?.creditCardNetwork
    const creditCardNumber = String(products?.transactions?.items[0].methodDetails?.alias).slice(-4)
    const OrderTotal = products?.terms?.total
    const shipping = products?.terms?.freight?.amount === 0 ? 'Free' : products?.terms?.freight?.amount
    const currency = products?.currency
    const dateOfmonth = products?.recurrence?.schedule?.dayOfMonth
    const subscriptionID = products?.id?.unicity
    const dateCreated = products.dateCreated
    const lastRunDate = products?.recurrence?.lastRunDate
    const lastOrder = products?.recurrence?.lastOrderId?.unicity
    const { shipToAddress } = products

    const isEnableSetting = storeReferral.GetSubscriptionConfigData('settings.isEnable')
    const isEnableSendNow = storeReferral.GetSubscriptionConfigData('sendNow.isEnable')
    const isEnableSkipNextDelivery = storeReferral.GetSubscriptionConfigData('skipNextDelivery.isEnable')

    useEffect(() => {
        if (openDialog === true) {
            let scrollY = window.scrollY
            StoreSubScriptions.scrollY = scrollY
            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                let body = document.getElementById('root')
                if (body) {
                    body.style.position = 'fixed'
                    body.style.overflowY = 'hidden'
                    body.style.webkitOverflowScrolling = 'touch'
                    // disableBodyScroll(body)
                }
            }
        } else {
            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                let body = document.getElementById('root')
                if (body) {
                    body.style.position = 'relative'
                    body.style.overflowY = 'scroll'
                    // enableBodyScroll(body)
                }
            }
        }
    }, [openDialog])

    useEffect(() => {
        return () => {
            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                let body = document.getElementById('root')
                if (body) {
                    body.style.position = 'relative'
                    body.style.overflowY = 'scroll'
                    // enableBodyScroll(body)
                }
            }
        }
    }, [])

    useEffect(() => {
        const date = addMonths(products?.recurrence?.dateStarts, 1)
        const skipDate = new Date(date).toLocaleDateString('en-uk', { year: 'numeric', month: 'short', day: 'numeric' })
        setSkipDate(skipDate)

        const formatAndSetAddress = async () => {
            try {
                setAddress(products.display_address)
                // const newData = {
                //     shipment_options: 'delivery',
                //     shipToAddress: JSON.stringify(shipToAddress)
                // }
                // const res = await FeelGreatAPI.FormatAddress(newData)
                // if (res.data.success) {
                //     setAddress(res.data.display_address)
                // }
            } catch (error) {
                console.log(error)
            }
        }

        formatAndSetAddress()
    }, [shipToAddress, products?.recurrence?.dateStarts])

    useEffect(() => {
        runInAction(() => {
            const newData = products.productItem.map(item => {
                return {
                    qty: item.quantity,
                    item_code: item.item_code
                }
            })
            setFlavorQTY(newData)
        })

        return () => {
            runInAction(() => {
                setFlavorQTY([])
            })
        }
    }, [products.productItem])

    const handleOpenDialog = type => {
        setOpenDialog(true)
        setTypeDialog(type)
    }

    const handleCancel = () => {
        setLoading(true)
        StoreSubScriptions.SetSubscriptionsList(products)
        setTimeout(() => {
            setLoading(false)
            navigate('/user/subscriptions/cancel-subscriptions')
        }, 1000)
    }

    const generateDaysList = daysInCurrentMonth => {
        let suffix

        switch (daysInCurrentMonth) {
            case 1:
                suffix = 'st'
                break
            case 2:
                suffix = 'nd'
                break
            case 3:
                suffix = 'rd'
                break
            default:
                suffix = 'th'
        }

        return `${daysInCurrentMonth}${suffix} ${translate('of_th_month')}`
    }

    const addMonths = (date, months) => {
        const outputDate = new Date(date)
        const d = outputDate.getDate()

        outputDate.setMonth(outputDate.getMonth() + +months)
        if (outputDate.getDate() !== d) {
            outputDate.setDate(0)
        }

        return outputDate
    }

    const getAlignmentCondition = () => {
        if (!isEnableSetting) return 'space-between'

        if (isEnableSendNow && isEnableSkipNextDelivery) {
            return 'space-between'
        } else if (isEnableSendNow && !isEnableSkipNextDelivery) {
            return 'flex-start'
        } else if (!isEnableSendNow && isEnableSkipNextDelivery) {
            return 'flex-end'
        } else {
            return 'flex-start'
        }
    }

    const getButtonTitle = (key = '', defaults) => {
        return StoreTranslate.UseLanguage(storeReferral.GetSubscriptionConfigData(`${key}.text`) || { english: '', native: '' }) || defaults
    }

    return (
        <>
            <Card>
                {loading && <CardDisable />}
                {/* <Box bgcolor="#ffffff" borderRadius="12px 12px 0px 0px" padding="0px 20px">
                    {products.productItem.map((item, index) => {
                        return (
                            <Box key={index}>
                                <Stack flexDirection="row" justifyContent="space-between" padding="20px 0px" width="100%">
                                    <Stack gap="15px" flexDirection="row" width="70%">
                                        <img src={item?.image_url} alt={item?.image_url} width={60} height={60} />

                                        <Stack gap="13px">
                                            <Typography variant="title">
                                                {item?.quantity} x {StoreTranslate.UseLanguage(item?.item_name)}
                                            </Typography>
                                            <Typography variant="caption4">{StoreTranslate.UseLanguage(item?.flavor?.text)}</Typography>
                                        </Stack>
                                    </Stack>

                                    {isShowEditSubscription() && (
                                        <Stack flexDirection="row" justifyContent="end" height="100%">
                                            {index === 0 && (
                                                <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_flavor')}>
                                                    <T>change</T>
                                                </Typography>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                                {index === products.productItem.length - 1 ? '' : <Box borderBottom="1px solid #CCDEEF" />}
                            </Box>
                        )
                    })}
                </Box> */}

                <Box bgcolor="#ffffff" borderRadius="12px 12px 0px 0px" padding="0px 20px">
                    {products.productItem.length > 0
                        ? products.productItem.map((item, index) => {
                              return (
                                  <Box key={index}>
                                      <Stack flexDirection="row" justifyContent="space-between" padding="20px 0px" width="100%">
                                          <Stack gap="15px" flexDirection="row" width="70%">
                                              <img src={item?.image_url} alt={item?.image_url} width={60} height={60} />

                                              {item.flavor ? (
                                                  <Stack gap="13px">
                                                      <Typography variant="title">
                                                          {item?.quantity} x {StoreTranslate.UseLanguage(item?.flavor?.description)}
                                                      </Typography>
                                                      <Typography variant="caption4">{StoreTranslate.UseLanguage(item?.flavor?.text)}</Typography>
                                                  </Stack>
                                              ) : (
                                                  <Stack gap="13px" flexDirection="row" alignItems="center" height="100%">
                                                      <Typography variant="title">
                                                          {item?.quantity} x {StoreTranslate.UseLanguage(item.item_name)}
                                                      </Typography>
                                                  </Stack>
                                              )}
                                          </Stack>

                                          {isShowEditSubscription('changeFlavor') && item.flavor && (
                                              <Stack flexDirection="row" justifyContent="end" height="100%">
                                                  {index === 0 && (
                                                      <Typography
                                                          variant="link"
                                                          textAlign="right"
                                                          color="#079FF4"
                                                          onClick={() => handleOpenDialog(item.flavor ? 'change_flavor' : 'change_product')}>
                                                          {isEnableSetting ? getButtonTitle('changeFlavor', <T>change</T>) : <T>change</T>}
                                                      </Typography>
                                                  )}
                                              </Stack>
                                          )}
                                      </Stack>
                                      {index === products.productItem.length - 1 ? '' : <Box borderBottom="1px solid #CCDEEF" />}
                                  </Box>
                              )
                          })
                        : products.lines.items.map((item, index) => {
                              return (
                                  <Box key={index}>
                                      <Stack flexDirection="row" justifyContent="space-between" padding="20px 0px" width="100%">
                                          <Stack gap="15px" flexDirection="row" width="70%" sx={{ alignItems: 'center' }}>
                                              <img
                                                  src={'https://ushop-media.unicity.com/images/product/JPN_35637_image_e59e11337649a09611ee3781a4304600.jpg'}
                                                  alt={'not found'}
                                                  height={60}
                                              />

                                              <Stack alignContent={'center'} display={'flex'} height={'100%'} justifyContent={'center'}>
                                                  <Typography variant="title">
                                                      {item?.quantity} x {item.catalogSlide.content.description}
                                                  </Typography>
                                              </Stack>
                                          </Stack>

                                          {isShowEditSubscription('changeFlavor') && (
                                              <Stack flexDirection="row" justifyContent="end" height="100%">
                                                  {index === 0 && (
                                                      <Typography
                                                          variant="link"
                                                          textAlign="right"
                                                          color="#079FF4"
                                                          onClick={() => handleOpenDialog('change_flavor')}>
                                                          {isEnableSetting ? getButtonTitle('changeFlavor', <T>change</T>) : <T>change</T>}
                                                      </Typography>
                                                  )}
                                              </Stack>
                                          )}
                                      </Stack>
                                  </Box>
                              )
                          })}
                </Box>

                <Box padding="0px 20px" bgcolor="#fdeded" sx={{ borderRadius: isShowEditSubscription('cancelSubscription') ? '' : '0px 0px 12px 12px' }}>
                    <Stack borderBottom="1px solid #CCDEEF" padding="20px 0px" gap="10px">
                        <Stack flexDirection="row" justifyContent="space-between" mb="10px">
                            <Typography variant="title" color="#153862">
                                <T>fg_subscription_id</T>
                            </Typography>
                            <Typography variant="caption4" textAlign="right" color="#003B6F">
                                {subscriptionID}
                            </Typography>
                        </Stack>

                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="caption4" color="#003B6F">
                                <T>fg_date_created</T>
                            </Typography>
                            <Typography variant="caption4" textAlign="right" color="#003B6F">
                                {dateCreated}
                            </Typography>
                        </Stack>

                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="caption4" color="#003B6F">
                                <T>fg_last_run_date</T>
                            </Typography>
                            <Typography variant="caption4" textAlign="right" color="#003B6F">
                                {lastRunDate ? lastRunDate : '-'}
                            </Typography>
                        </Stack>

                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="caption4" color="#003B6F">
                                <T>fg_last_order</T>
                            </Typography>
                            <Typography variant="caption4" textAlign="right" color="#003B6F">
                                {lastOrder ? lastOrder : '-'}
                            </Typography>
                        </Stack>

                        <Stack flexDirection="row" justifyContent={getAlignmentCondition()} mt="10px">
                            {isShowEditSubscription('sendNow') && (
                                <Typography variant="link" color="#079FF4" onClick={() => handleOpenDialog('send_now')}>
                                    {isEnableSetting ? getButtonTitle('sendNow', <T>send_now</T>) : <T>send_now</T>}
                                </Typography>
                            )}
                            {isShowEditSubscription('skipNextDelivery') && (
                                <Typography variant="link" color="#079FF4" onClick={() => handleOpenDialog('skip_next_delivery')}>
                                    {isEnableSetting ? getButtonTitle('skipNextDelivery', <T>skip_next_delivery_button</T>) : <T>skip_next_delivery_button</T>}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>

                    <Box borderBottom="1px solid #CCDEEF" padding="20px 0px">
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="title" color="#153862">
                                <T>subscription_dialog_header</T>
                            </Typography>
                            {isShowEditSubscription('changeAutoShipDate') && (
                                <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_date')}>
                                    {isEnableSetting ? getButtonTitle('changeAutoShipDate', <T>change</T>) : <T>change</T>}
                                </Typography>
                            )}
                        </Stack>

                        <Stack mt="20px" gap="10px">
                            <Typography variant="caption4" color="#003B6F">
                                {translate('ship_date_detail').replace('{{date}}', generateDaysList(dateOfmonth))}
                            </Typography>
                            <Typography variant="caption4" color="#003B6F">
                                <T>next_ship_date</T>: {nextShipDate}
                            </Typography>
                        </Stack>
                    </Box>

                    <Box borderBottom="1px solid #CCDEEF" padding="20px 0px">
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="title" color="#153862">
                                <T>ship_to</T>:
                            </Typography>
                            {isShowEditSubscription('changeShipToAddress') && (
                                <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_address')}>
                                    {isEnableSetting ? getButtonTitle('changeShipToAddress', <T>change</T>) : <T>change</T>}
                                </Typography>
                            )}
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between" mt="20px">
                            <Typography variant="caption4" color="#003B6F">
                                {products.shipToName.fullName}
                            </Typography>
                            <Typography variant="caption4" textAlign="right" color="#003B6F" width="50%">
                                {address}
                            </Typography>
                        </Stack>
                    </Box>

                    <Stack padding="20px 0px" gap="20px">
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="title" color="#153862">
                                <T>payment</T>:
                            </Typography>
                            {isShowEditSubscription('changeCreditCard') && (
                                <Typography variant="link" textAlign="right" color="#079FF4" onClick={() => handleOpenDialog('change_payment')}>
                                    {isEnableSetting ? getButtonTitle('changeCreditCard', <T>change</T>) : <T>change</T>}
                                </Typography>
                            )}
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                            <LogoPayment type={creditCardNetwork} />
                            <Typography variant="caption" textAlign="right" color="#003B6F">
                                xxxx - xxxx - xxxx - {creditCardNumber}
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="title" color="#153862" textAlign="left">
                                <T>order_total</T>
                            </Typography>
                            <Typography variant="title" color="#153862" textAlign="right">
                                {NumberHelpers.currency({
                                    currency: storeCountry.GetCurrencyCode(),
                                    number: OrderTotal,
                                    symbol: storeCountry.GetCurrencySymbol()
                                })}
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography variant="caption4" textAlign="left" color="#003B6F">
                                <T>shipping</T>
                            </Typography>
                            <Typography variant="caption4" textAlign="right" color="#003B6F">
                                {shipping}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>

                {isShowEditSubscription('cancelSubscription') && (
                    <Box bgcolor="#ffffff" borderRadius="0 0 12px 12px" padding="30px 40px">
                        <Stack flexDirection="row" justifyContent="center" gap="20px">
                            {loading ? (
                                <ButtonIconSpinner />
                            ) : (
                                <Typography variant="link" color="#079FF4" onClick={() => handleCancel()}>
                                    {isEnableSetting ? getButtonTitle('cancelSubscription', <T>cancel_subscription</T>) : <T>cancel_subscription</T>}
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                )}

                <CustomDialogV2
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    type={typeDialog}
                    getOrder={getOrder}
                    products={products}
                    dateOfmonth={dateOfmonth}
                    flavorQTY={flavorQTY}
                    skipDate={skipDate}
                />
            </Card>
        </>
    )
}

const CardDisable = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 65%;
    border-radius: 12px;
    z-index: 1;
`

const Card = styled(Box)`
    border-radius: 12px;
    position: relative;
    width: 100%;
    box-shadow: 0px 1px 2px rgba(14, 35, 56, 0.2);
`

const ButtonIconSpinner = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    circle {
        stroke: #079ff4;
    }
    path {
        stroke: #c7ddfb;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default ItemSubscriptionsMobile
