import { createTheme } from '@mui/material'

export const MainTemplate = createTheme({
    palette: {
        primary: {
            main: '#153862'
        },
        secondary: {
            main: '#ffffff',
            disabled: '#fff'
        },
        background: {
            default: '#D1D9E2',
            main: '#D1D9E2'
        },
        button: {
            main: '#5A8FC4',
            disabled: 'rgba(90, 143, 196, .3)'
        },
        flavor: {
            main: '#023764'
        }
    },
    typography: {
        h2: {
            fontSize: 60,
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#0B3B61',
            fontStyle: 'normal',
            '@media (max-width: 430px)': {
                fontSize: '33px'
            }
        },
        h3: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            color: '#0B3B61'
        },
        h4: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '16px'
            }
        },
        h5: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            color: '#0B3B61'
        },
        h6: {
            fontSize: 40,
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#0B3B61',
            fontStyle: 'normal',
            '@media (max-width: 430px)': {
                fontSize: '28px'
            }
        },
        h7: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '32px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '24px'
            }
        },
        body1: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '36px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '24px'
            }
        },
        body2: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '16px'
            }
        },
        body3: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '14px'
            }
        },
        caption: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px'
        },
        caption2: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '14px'
            }
        },
        caption3: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#023764',
            '@media (max-width: 430px)': {
                fontSize: '14px'
            }
        },
        caption4: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            color: '#6687A2'
        },
        caption5: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '18px',
            color: '#003B6F',
            lineHeight: '25px'
        },
        caption6: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#6687A2'
        },
        caption7: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '10px',
            color: '#023764',
            opacity: 0.3
        },
        label: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px'
        },
        link: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            textDecoration: 'underline',
            color: '#0B3B61',
            cursor: 'pointer'
        },
        link2: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '28px',
            textDecoration: 'underline',
            color: '#0B3B61',
            cursor: 'pointer'
        },
        link3: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            textDecoration: 'underline',
            color: '#079FF4',
            cursor: 'pointer'
        },
        muted: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            opacity: '0.4'
        },
        through: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            textDecoration: 'line-through',
            color: '#009ECF'
        },
        error: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            color: '#FE5D7C'
        },
        title: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            color: '#023764',
            lineHeight: '27px'
        },
        title2: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            color: '#003B6F',
            lineHeight: '30px'
        },
        title3: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '32px',
            color: '#003B6F',
            lineHeight: '35px',
            '@media (max-width: 430px)': {
                fontSize: '30px'
            }
        },
        button: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: '18px',
            color: '#ffffff'
        },
        strike: {
            fontSize: "10px",
            color: "#FF820E",
            fontWeight: 400,
            lineHeight: "12px",
            fontFamily: "Poppins",
            textDecorationLine: "line-through",
        },
        default: {
            fontFamily: 'Poppins',
            color: "#003B6F",
            fontWeight: "400"
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&.has-shadow': {
                        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)!important'
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: 'none',
                    '&.card--with-picture': {
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        '@media (max-width: 768px)': {
                            paddingLeft: 15,
                            paddingRight: 15
                        }
                    },
                    '&.card--form': {
                        border: 'none',
                        boxShadow: '0px 1px 2px rgba(14, 35, 56, 0.2)',
                        borderRadius: 12
                    },
                    '&.card--products': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    },
                    '&.card--payment': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    },
                    '&.card--menu': {
                        backgroundColor: '#003b6f',
                        borderRadius: 12,
                        minHeight: 200,
                        height: 'fit-content'
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '&.logo': {
                        paddingTop: '25px',
                        paddingBottom: '25px',
                        minHeight: '0'
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '&.menu-login': {
                        '.MuiMenu-paper': {
                            border: '1px solid #F1F5F8',
                            top: '60px!important',
                            boxShadow: '0px 4px 18px rgba(0,0,0,.05)',
                            width: '360px'
                        },
                        '.MuiList-root': {
                            padding: '30px'
                        },
                        '.MuiButtonBase-root': {
                            '&.MuiMenuItem-root': {
                                padding: '0px',
                                '&:hover': {
                                    backgroundColor: 'transparent!important'
                                }
                            }
                        }
                    },
                    '&.menu-select-country': {
                        '.MuiMenu-paper': {
                            border: '1px solid #F1F5F8',
                            top: '60px!important',
                            boxShadow: '0px 4px 18px rgba(0,0,0,.05)',
                            width: '320px'
                        },
                        '.MuiList-root': {
                            padding: '30px 30px 20px 30px'
                        },
                        '.MuiButtonBase-root': {
                            '&.MuiMenuItem-root': {
                                padding: '0px',
                                '&:hover': {
                                    backgroundColor: 'transparent!important'
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '380px'
                }
            }
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {}
                }
            }
        }
    }
})
