import { Backdrop, Box, CircularProgress, InputAdornment, InputBase, Stack, ThemeProvider, Typography, styled } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import StoreReferral from '../../Stores/StoreReferral'
import PopperPopup from './PopperPopup/PopperPopup'
import { Customers as CustomersApi } from 'Services/Cores/Customers/Customers'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { ReactComponent as ArrowRightIcon } from './Assets/arrow-long-right.svg'

import StoreAuth from 'GlobalStores/User/StoreAuth'
import { reaction } from 'mobx'
import { storeElement } from 'GlobalStores/StoreElement'
import ReferralInput from './ReferralInput'

const ReferralBar = observer(props => {
    const translate = useTranslate()
    const params = useParams()

    const [openBackDrop, setOpenBackDrop] = useState(false)
    const [currentBAId, setCurrentBAId] = useState('')

    const [touched, setTouched] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const location = useLocation()
    const navigate = useNavigate()
    const [readyToRender, setReadyToRender] = useState(false)

    const GetCustomerData = async (customerId = null) => {
        if (Boolean(customerId) === false) return

        setOpenBackDrop(true)

        try {
            const referralResponse = await CustomersApi.getAllowReferrer(customerId)

            const item = {
                ...referralResponse.data,
                unicity: referralResponse.data.baId,
                href: '',
                customerSite: {
                    href: ''
                },
                id: {
                    unicity: referralResponse.data.baId
                },
                profilePicture: {
                    href: ''
                },
                joinDate: '',
                status: '',
                type: '',
                entryPeriod: ''
            }

            const isAllow = get(referralResponse.data, 'isAllow')
            const invalidType = get(referralResponse.data, 'reason.type')
            const baId = get(referralResponse.data, 'baId')
            setCurrentBAId(baId)

            if (isAllow) {
                if (referralResponse?.data?.reason?.message) {
                    if (invalidType === 'baIdMinLength') {
                        throw new Error('invalid_code_entered')
                    }

                    throw new Error(referralResponse?.data?.reason?.message)
                }
                localStorage.removeItem('from-logout')
                StoreReferral.SetReferralData(item, get(item, 'baStatus', ''))
                StoreReferral.SetCheckedReferralParam(true)
            } else {
                localStorage.removeItem('from-logout')
                if (invalidType === 'invalidId') {
                    throw new Error('invalid_code_entered')
                } else {
                    throw new Error(referralResponse?.data?.reason?.message)
                }
            }
        } catch (error) {
            console.error('error', error)
            localStorage.removeItem('from-logout')
            StoreReferral.SetCheckedReferralParam(true)
            StoreReferral.SetReferralCheckError(error?.message)
        }
        setReadyToRender(true)
        setOpenBackDrop(false)
    }

    const isShowBox = () => {
        let path = location.pathname.split('/')[1]

        if (path.length > 0) {
            return false
        }

        if (StoreReferral.GetReferralHistory()) return true

        return readyToRender && !StoreAuth.IsAuthorized()
    }

    useEffect(() => {
        if (params.customerId && !StoreAuth.IsAuthorized()) {
            GetCustomerData(params.customerId)
        }
    }, [params.customerId])

    useEffect(() => {
        const id = get(StoreReferral.GetReferral(), 'id', '')

        if (StoreReferral.HasReferral() === false && Boolean(StoreAuth.IsAuthorized()) === true && Boolean(id) === true) {
            GetCustomerData(id)
        }

        reaction(
            () => StoreReferral.HasReferral(),
            (newVal, oldVal, disposer) => {
                if (newVal !== false && oldVal === false) {
                    isShowBox()
                    disposer.dispose()
                }
            }
        )

        reaction(
            () => StoreReferral.GetCheckedReferralParam(),
            (newValue, oldValue, disposer) => {
                if (newValue === true) {
                    navigate('/')
                    disposer.dispose()
                }
            }
        )

        reaction(
            () => StoreAuth.IsAuthorized(),
            (newValue, oldValue, disposer) => {
                if (newValue === true) {
                    StoreReferral.reset()
                    StoreReferral.referralHistory = null
                    setReadyToRender(false)
                    disposer.dispose()
                }
            }
        )
    }, [])

    return (
        <ThemeProvider theme={storeElement.theme}>
            {isShowBox() && (
                <Stack bgcolor={'#153862'} pt="18px" pb="18px">
                    <Stack color={'#ffffff'} justifyContent="center" textAlign="center">
                        {!StoreReferral.HasReferral() && !StoreReferral.HasReferralHistory() ? (
                            <Stack justifyContent={'center'} flexDirection={'row'}>
                                <ReferralInput currentBAId={currentBAId} onSubmit={GetCustomerData} />
                                <div style={{ display: 'flex', marginLeft: '24px', height: '36px' }}>
                                    <PopperPopup title={translate('what_is_member_code')} caption={translate('member_invite_code_caption')} />
                                </div>
                            </Stack>
                        ) : null}

                        {Boolean(StoreReferral.GetReferralCheckError()) && (
                            <Stack mt="10px">
                                <Typography variant="label" color="#FFD058" alignSelf="center">
                                    {translate(StoreReferral.GetReferralCheckError())
                                        ? `${translate(StoreReferral.GetReferralCheckError())}: ${currentBAId}`
                                        : StoreReferral.GetReferralCheckError()}
                                </Typography>

                                {/* <Typography display={!refervalid ? 'block' : 'none'} mt={'8px'} variant="label" color={'#FFD058'}>
                    {`${translate(StoreReferral.GetReferralCheckError())}: ${refCode}`}
                </Typography> */}
                            </Stack>
                        )}

                        {StoreReferral.HasReferral() || StoreReferral.HasReferralHistory() ? (
                            <Stack justifyContent={'center'} flexDirection={'row'}>
                                <Typography variant="caption" color="#ffffff" alignSelf="center" fontSize={'14px'}>
                                    <T>referred_by</T>{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {
                                            get(StoreReferral.GetReferral(), 'displayName.english', '') ||
                                            get(StoreReferral.GetReferralHistory(), 'displayName.english', '')
                                        }
                                    </span>
                                </Typography>
                                <div div style={{ display: 'flex', marginLeft: '10px', height: '36px' }}>
                                    <PopperPopup
                                        title={translate('subtitle_invited_you_to_member').replace(
                                            '{{referral_name}}',
                                            get(StoreReferral.GetReferral(), 'displayName.english', '') ||
                                            get(StoreReferral.GetReferralHistory(), 'displayName.english', '')
                                        )}
                                        caption={translate('member_invite_code_caption')}
                                    />
                                </div>
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>
            )}
            <BackdropShow isOpen={openBackDrop} />
        </ThemeProvider>
    )
})

const BackdropShow = ({ isOpen }) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1 + 1300 // 1300 from  Login modal's zIndex
            }}
            open={isOpen}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

const ReferInput = ({ touched, setTouched, refervalid = false, onSubmitRef, customerId = '', placeholderMessage, errorMessage, setErrorMessage }) => {
    const [refCode, setRefcode] = useState(customerId)
    const translate = useTranslate()

    const submitRefCode = async e => {
        e.preventDefault()
        await onSubmitRef(refCode)
    }

    return (
        <ReferInputWrapper>
            <form className="mainInput" onSubmit={submitRefCode}>
                <CustomRefInput
                    autoComplete="off"
                    name="refCode"
                    placeholder={placeholderMessage}
                    endAdornment={
                        <InputAdornment position="end">
                            <ArrowRightIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    onSubmitRef(refCode)
                                }}
                            />
                        </InputAdornment>
                    }
                    value={refCode}
                    onChange={e => {
                        setRefcode(e.target.value)
                        setTouched(false)
                        setErrorMessage('')
                    }}
                    style={
                        touched
                            ? {
                                  color: !refervalid ? '#FFD058' : '#ffffff',
                                  border: !refervalid ? '1px solid #FFD058' : '1px solid #ffffff'
                              }
                            : {}
                    }
                />
            </form>
            {touched && (
                <Typography display={!refervalid ? 'block' : 'none'} mt={'8px'} variant="label" color={'#FFD058'}>
                    {`${translate(StoreReferral.GetReferralCheckError())}: ${refCode}`}
                </Typography>
            )}
        </ReferInputWrapper>
    )
}

const CustomRefInput = styled(InputBase)(({ theme, refervalid }) => ({
    width: '287px',
    height: '36px',
    opacity: 0.6,
    borderRadius: '6px',
    fontFamily: 'Inter, NotoSansThai',
    fontStyle: 'normal',
    fontWeight: 400,

    lineHeight: '16px',

    padding: '10px 16px',

    input: {
        fontSize: '12px'
    },

    border: '1px solid #ffffff',
    color: '#ffffff'

    // border: refervalid ? '1px solid #FD5C5C' : '1px solid #ffffff',
    // color: refervalid ? '#FD5C5C' : '#ffffff',
}))

const ReferInputWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    .mainInput {
        display: flex;
        flex-direction: column;
    }
`

export default ReferralBar

// dynamic referral bar older version

// import { Backdrop, CircularProgress, Stack, ThemeProvider, Typography } from '@mui/material'
// import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
// import { get, includes, last, set } from 'lodash'
// import { observer, useLocalObservable } from 'mobx-react-lite'
// import { useEffect, useState } from 'react'
// import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import StoreReferral from '../../Stores/StoreReferral'
// import PopperPopup from './PopperPopup/PopperPopup'
// import { Customers as CustomersApi } from 'Services/Cores/Customers/Customers'
// import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

// import StoreAuth from 'GlobalStores/User/StoreAuth'
// import { autorun, reaction, toJS } from 'mobx'
// import { storeElement } from 'GlobalStores/StoreElement'
// import ReferralInput from './ReferralInput'
// import storeAuth from 'GlobalStores/User/StoreAuth'
// import { Store } from '@mui/icons-material'

// const ReferralBar = observer(props => {
//     const pageShowReferralBox = ['', 'login'] // Referral Main Page Each Country

//     const translate = useTranslate()
//     const params = useParams()
//     console.log('ram', params)
//     const location = useLocation()
//     const navigate = useNavigate()

//     const [openBackDrop, setOpenBackDrop] = useState(false)
//     const [currentBAId, setCurrentBAId] = useState('')
//     const [readyToRender, setReadyToRender] = useState(false)

//     const localObserver = useLocalObservable(() => ({
//         showReferral: false
//     }))

//     const GetCustomerData = async (customerId = null) => {

//         if (Boolean(customerId) === false) return

//         setOpenBackDrop(true)

//         try {
//             const referralResponse = await CustomersApi.getAllowReferrer(customerId)

//             const item = {
//                 ...referralResponse?.data,
//                 unicity: referralResponse.data.baId,
//                 href: '',
//                 customerSite: {
//                     href: ''
//                 },
//                 id: {
//                     unicity: referralResponse.data.baId
//                 },
//                 profilePicture: {
//                     href: ''
//                 },
//                 joinDate: '',
//                 status: '',
//                 type: '',
//                 entryPeriod: ''
//             }

//             const isAllow = get(referralResponse?.data, 'isAllow')
//             const invalidType = get(referralResponse?.data, 'reason.type')
//             const baId = get(referralResponse?.data, 'baId')
//             setCurrentBAId(baId)

//             if (isAllow) {
//                 if (referralResponse?.data?.reason?.message) {
//                     if (invalidType === 'baIdMinLength') {
//                         throw new Error('invalid_code_entered')
//                     }

//                     throw new Error(referralResponse?.data?.reason?.message)
//                 }

//                 StoreReferral.SetReferralData(item, get(item, 'baStatus', ''))
//                 StoreReferral.SetCheckedReferralParam(true)
//             } else {
//                 if (invalidType === 'invalidId') {
//                     throw new Error('invalid_code_entered')
//                 } else {
//                     throw new Error(referralResponse?.data?.reason?.message)
//                 }
//             }
//         } catch (error) {
//             console.error('error', error)
//             StoreReferral.SetCheckedReferralParam(true)
//             StoreReferral.SetReferralCheckError(error?.message)
//         }
//         setReadyToRender(true)
//         setOpenBackDrop(false)
//     }

//     const isShowBox = () => {
//         let path = location.pathname.split('/')[1]
//         const isLoginPage = path === 'login' // Login Page show referral bar always

//         console.log('path', path)
//         console.log('isLoginPage', isLoginPage)
//         console.log('readyToRender', readyToRender)
//         console.log('!includes(pageShowReferralBox, path)', includes(pageShowReferralBox, path))
//         // if (!includes(pageShowReferralBox, path) && params.customerId) {
//         //     return false
//         // }

//         if (StoreReferral.GetReferralHistory()) return true

//         // return (readyToRender && !StoreAuth.IsAuthorized()) || isLoginPage === false
//         return includes(pageShowReferralBox, path) && !storeAuth.IsAuthorized() || isLoginPage === true
//     }

//     console.log('isShowBox', isShowBox())

//     useEffect(() => {
//         if (params.customerId && !StoreAuth.IsAuthorized()) {
//             GetCustomerData(params.customerId)
//             // navigate('/')
//         }
//     }, [])

//     useEffect(() => {
//         const id = get(StoreReferral.GetReferral(), 'id', '')

//         if (StoreReferral.HasReferral() === false && Boolean(StoreAuth.IsAuthorized()) === true && Boolean(id) === true) {
//             GetCustomerData(id)
//         }

//         reaction(
//             () => StoreReferral.HasReferral(),
//             (newVal, oldVal, disposer) => {
//                 if (newVal !== false && oldVal === false) {
//                     isShowBox()
//                     disposer.dispose()
//                 }
//             }
//         )

//         // const disposerClearReferralIfAuth = reaction(
//         //     () => StoreAuth.IsAuthorized(),
//         //     newVal => {
//         //         if (newVal === true) {
//         //             StoreReferral.reset()
//         //             disposerClearReferralIfAuth()
//         //         }
//         //     }
//         // )

//         reaction(
//             () => StoreReferral.GetCheckedReferralParam(),
//             (newValue, oldValue, disposer) => {
//                 if (newValue === true) {
//                     navigate('/referral')
//                     disposer.dispose()
//                 }
//             }
//         )

//         reaction(
//             () => storeAuth.IsAuthorized(),
//             (newValue, oldValue, disposer) => {
//                 if (newValue === true) {
//                     StoreReferral.referralHistory = null
//                     setReadyToRender(false)
//                     disposer.dispose()
//                 }
//             }
//         )
//     }, [])

//     return (
//         <ThemeProvider theme={storeElement.theme}>
//             <Stack>
//                 {isShowBox() && (
//                     <Stack bgcolor={'#153862'} pt="18px" pb="18px">
//                         <Stack color={'#ffffff'} justifyContent="center">
//                             {StoreReferral.HasReferral() || StoreReferral.HasReferralHistory() ? (
//                                 <Stack justifyContent={'center'} flexDirection={'row'}>
//                                     <Typography variant="caption" color="#ffffff" alignSelf="center" fontSize={'14px'}>
//                                         <T>referred_by</T>{' '}
//                                         <span style={{ fontWeight: 'bold' }}>
//                                             {StoreTranslate.UseLanguage(
//                                                 get(StoreReferral.GetReferral(), 'displayName', '') ||
//                                                 get(StoreReferral.GetReferralHistory(), 'displayName', '')
//                                             ) ||
//                                                 get(StoreReferral.GetReferral(), 'displayName.english', '') ||
//                                                 get(StoreReferral.GetReferral(), 'displayName.native', '') ||
//                                                 get(StoreReferral.GetReferralHistory(), 'displayName.english', '') ||
//                                                 get(StoreReferral.GetReferralHistory(), 'displayName.native', '')}
//                                         </span>
//                                     </Typography>
//                                     <div div style={{ display: 'flex', marginLeft: '10px', height: '36px' }}>
//                                         <PopperPopup
//                                             title={translate('subtitle_invited_you_to_member').replace(
//                                                 '{{referral_name}}',
//                                                 StoreTranslate.UseLanguage(
//                                                     get(StoreReferral.GetReferral(), 'displayName', '') ||
//                                                     get(StoreReferral.GetReferralHistory(), 'displayName', '')
//                                                 )
//                                             )}
//                                             caption={translate('member_invite_code_caption')}
//                                         />
//                                     </div>
//                                 </Stack>
//                             ) : null}

//                             {StoreReferral.HasReferral() === false && StoreReferral.HasReferralHistory() === false ? (
//                                 <Stack justifyContent={'center'} flexDirection={'row'}>
//                                     <ReferralInput currentBAId={currentBAId} onSubmit={GetCustomerData} />
//                                     <div div style={{ display: 'flex', marginLeft: '10px', height: '36px' }}>
//                                         <PopperPopup title={translate('what_is_member_code')} caption={translate('member_invite_code_caption')} />
//                                     </div>
//                                 </Stack>
//                             ) : null}

//                             {Boolean(StoreReferral.GetReferralCheckError()) && (
//                                 <Stack mt="10px">
//                                     <Typography variant="caption" color="#FFD058" alignSelf="center">
//                                         <span style={{ fontWeight: 'bold' }}>
//                                             {translate(StoreReferral.GetReferralCheckError())
//                                                 ? `${translate(StoreReferral.GetReferralCheckError())}: ${currentBAId}`
//                                                 : StoreReferral.GetReferralCheckError()}
//                                         </span>
//                                     </Typography>
//                                 </Stack>
//                             )}
//                         </Stack>
//                     </Stack>
//                 )}
//             </Stack>
//             <BackdropShow isOpen={openBackDrop} />
//         </ThemeProvider>
//     )
// })

// const BackdropShow = ({ isOpen }) => {
//     return (
//         <Backdrop
//             sx={{
//                 color: '#fff',
//                 zIndex: theme => theme.zIndex.drawer + 1 + 1300 // 1300 from  Login modal's zIndex
//             }}
//             open={isOpen}>
//             <CircularProgress color="inherit" />
//         </Backdrop>
//     )
// }

// export default ReferralBar
