import { observer } from 'mobx-react-lite'
import { Stack, styled } from '@mui/material'
// import LoginElem from '../Elements/Login/Login'
import LoginCard from './LoginCard'

const LoginPage = observer(() => {
    return (
        <LoginPageWrapper>
            <LoginCard />
        </LoginPageWrapper>
    )
})

export default LoginPage

const LoginPageWrapper = styled(Stack)`
    * {
        box-sizing: border-box;
    }

    align-items: center;
    background: #d1d9e2;
    width: 100%;
    min-height: calc(100vh - (64px + 60px)); // header + footer
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    @media screen and (min-width: 768px) {
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 30px;
    }
`
