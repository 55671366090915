import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, ThemeProvider, Typography } from '@mui/material'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { DefaultButton, DefaultInput } from '../Forms'
import { useEffect, useState } from 'react'
import { ReactComponent as LockIcon } from './Lock.svg'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { autorun, reaction, toJS } from 'mobx'
import storeReferralCheckout from '../../Stores/StoreReferralCheckout'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import styled from '@emotion/styled'
import ModalSearch from '../Forms/ModalSearch/ModalSearch'
import CountryBox from '../Forms/CountryBox/CountryBox'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import BranchSelect from '../Forms/BranchSelect/BranchSelect'
import ProvinceSelect2 from '../Forms/ProvinceSelect/ProvinceSelect2'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreReferralCart from '../../Stores/StoreReferralCart'
import StorePeriods from 'GlobalStores/StorePeriods'
import { TaxInvoiceApis } from './TaxInvoiceApis'
import Errors from '../Errors'
import { capitalize } from 'lodash'
import { getBrowserOS } from 'Helpers/Bowser'
import storeUser from 'GlobalStores/User/StoreUser'
import { storeElement } from 'GlobalStores/StoreElement'
import RadioButton from '../Forms/RadioButton/RadioButton'

function RadioCustom(props) {
    return (
        <Radio
            sx={{
                color: 'primary.main',
                '&.Mui-checked': {
                    color: 'primary.main'
                }
            }}
            {...props}
        />
    )
}

const TaxInvoice = observer(props => {
    const translate = useTranslate()
    const [isLoading, setIsLoading] = useState(false)

    const inputs = ['fullName', 'branch_number', 'zip_code', 'province', 'district', 'sub_district', 'street', 'house_number', 'company_name']

    useEffect(() => {
        storeReferralCheckout.invoiceFormsErrors = []
        autorun(disposer => {
            if (storeCheckout.GetShippingForm().length > 0) {
                if (storeReferralCheckout.invoiceType === 'individual') {
                    initIndividualForm()
                } else {
                    initCorporateForm()
                }

                disposer.dispose()
            }
        })

        const disposerRectionTaxType = reaction(
            () => storeReferralCheckout.invoiceType,
            newValue => {
                storeReferralCheckout.invoiceForms = []
                if (newValue === 'individual') {
                    initIndividualForm()
                } else {
                    initCorporateForm()
                }
            }
        )

        return () => {
            disposerRectionTaxType()
        }
    }, [])

    const initIndividualForm = () => {
        storeReferralCheckout.invoiceForms = toJS(storeCheckout.GetShippingForm())
    }

    const initCorporateForm = () => {
        let form = toJS(storeCheckout.GetShippingForm())
        form = form.filter(item => item.key !== 'fullName')

        form.unshift({
            key: 'branch_number',
            type: 'BranchSelect',
            value: '',
            args: null,
            label: 'branch_number'
        })
        form.unshift({
            key: 'company_name',
            type: 'text',
            value: '',
            args: null,
            label: 'company_name'
        })
        storeReferralCheckout.invoiceForms = form
    }

    const SaveTaxInvoice = async () => {
        storeReferralCheckout.invoiceFormsErrors = []
        let data = toJS(storeReferralCheckout.GetTaxInvoiceValues())
        let payerNo = ''
        delete data.email
        delete data.phone

        if (storeReferralCheckout.invoiceType === 'individual') {
            data.government_id = storeReferralCheckout.invoiceGovernmentId
            payerNo = data.government_id
        } else {
            data.tax_id = storeReferralCheckout.invoiceTaxId
            payerNo = data.tax_id
        }

        const postData = {
            medium: 'Internet',
            agent: 'uFeelgreat',
            platform: getBrowserOS().name,
            type: !storeAuth.isAuthorized ? 'enroll' : 'shop',
            items: JSON.stringify(toJS(StoreReferralCart.getCartItems())),
            period: StorePeriods.periods.entryPeriod,
            tax_invoice: 1,
            tax_invoice_type: capitalize(storeReferralCheckout.invoiceType),
            tax_invoice_zip_code: data.zip_code,
            tax_invoice_province: data.province,
            tax_invoice_district: data.district,
            tax_invoice_sub_ditrict: data.sub_district,
            tax_invoice_street: data.street,
            tax_invoice_house_no: data.house_number
        }

        if (storeReferralCheckout.invoiceType === 'corporate') {
            postData.tax_invoice_branch_no = data.branch_number
            postData.tax_invoice_company_name = data.company_name
            postData.tax_invoice_payer_no = payerNo
        } else {
            postData.tax_invoice_gov_id = payerNo
            postData.tax_invoice_full_name = data.fullName
        }

        if (storeAuth.isAuthorized) {
            postData.ba_status = storeAuth.userStatus
            postData.login_id = storeAuth.GetId()
            postData.login_name = storeUser.CustomerData().Name()
            postData.login_name_native = storeUser.CustomerData().NativeName()
            postData.token = storeAuth.GetToken()
        }

        try {
            setIsLoading(true)
            const response = await TaxInvoiceApis.ValidateTaxInvoice(postData)
            if (response.data.success === false) {
                let errors = response.data.Message_v2
                errors.map(item => {
                    storeReferralCheckout.invoiceFormsErrors.push(translate(item))
                })
            } else {
                storeReferralCheckout.invoiceFormsErrors = []
                storeReferralCheckout.invoiceInformation = postData
                props.setCloseModal(true)
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            {storeReferralCheckout.invoiceForms.length > 0 ? (
                <>
                    <Stack>
                        <Stack padding="30px">
                            <Typography variant="h2">{translate('request_for_full_tax_invoice')}</Typography>
                        </Stack>
                        <Stack
                            padding={{
                                xs: '0px 30px'
                            }}>
                            <FormControl>
                                <RadioGroup>
                                    <RadioGroupStackStyled>
                                        <FormControlLabel
                                            value="individual"
                                            control={<RadioButton />}
                                            label={<Typography variant="h4">{translate('individual')}</Typography>}
                                            checked={storeReferralCheckout.invoiceType === 'individual'}
                                            onClick={() => (storeReferralCheckout.invoiceType = 'individual')}
                                        />
                                        <FormControlLabel
                                            value="corporate"
                                            control={<RadioButton />}
                                            label={<Typography variant="h4">{translate('corporate')}</Typography>}
                                            checked={storeReferralCheckout.invoiceType === 'corporate'}
                                            onClick={() => (storeReferralCheckout.invoiceType = 'corporate')}
                                        />
                                    </RadioGroupStackStyled>
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row'
                            }}
                            justifyContent={{
                                xs: 'flex-start'
                            }}
                            gap={{
                                xs: '10px',
                                sm: '10px',
                                md: '40px'
                            }}
                            padding={{
                                xs: '20px',
                                sm: '20px',
                                md: '25px 40px'
                            }}
                            bgcolor="#E9F1FA"
                            mt={{
                                xs: '30px',
                                sm: '40px'
                            }}
                            alignItems={{
                                xs: 'flex-start',
                                sm: 'center'
                            }}>
                            <Stack width={'max-content'} style={{ textWrap: 'nowrap' }}>
                                <Typography variant="h3">
                                    {storeReferralCheckout.invoiceType === 'individual' ? translate('government_id') : translate('taxpayer_id')}:
                                </Typography>
                            </Stack>
                            <Stack width={'100%'}>
                                <CustomInputWithEndorment
                                    label={null}
                                    type="text"
                                    value={
                                        storeReferralCheckout.invoiceType === 'individual'
                                            ? storeReferralCheckout.invoiceGovernmentId
                                            : storeReferralCheckout.invoiceTaxId
                                    }
                                    onChange={e => {
                                        if (e.target.value.length <= 13) {
                                            if (/[0-9]/.test(e.target.value) || e.target.value === '') {
                                                if (storeReferralCheckout.invoiceType === 'individual') {
                                                    storeReferralCheckout.invoiceGovernmentId = e.target.value
                                                } else {
                                                    storeReferralCheckout.invoiceTaxId = e.target.value
                                                }
                                            }
                                        }
                                    }}
                                    sx={{ '.MuiInputBase-input': { background: '#FFFFFF' } }}
                                    inputProps={{ style: { fontSize: '16px', color: '#000000' } }}
                                    endAdornment={<LockIcon />}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        paddingTop={{
                            xs: '30px 0px',
                            sm: '20px 0px',
                            md: '30px 0px'
                        }}>
                        <Stack
                            paddingTop={'30px'}
                            paddingLeft={{
                                xs: '20px',
                                md: '30px'
                            }}
                            paddingBottom={'20px'}>
                            <Typography variant="h3">{translate('tax_invoice_address')}:</Typography>
                        </Stack>
                        <FormStack
                            padding={{
                                xs: '0px 20px',
                                md: '0px 30px'
                            }}
                            className={`invoice-form ${storeReferralCheckout.invoiceType === 'corporate' ? 'tax-invoice-corporate-form' : ''}`}>
                            <Grid container>
                                {storeReferralCheckout.invoiceForms.map((item, index) => {
                                    if (!inputs.includes(item.key)) return null
                                    if (item.type === 'CountryBox') {
                                        return (
                                            <Grid item key={item.key}>
                                                <CountryBox
                                                    label={translate('country')}
                                                    value={
                                                        StoreTranslate.CurrentLanguage() === 'EN'
                                                            ? storeCountry.CountryCapitalize()
                                                            : storeCountry.CountryNative()
                                                    }
                                                />
                                            </Grid>
                                        )
                                    } else if (item.type === 'BranchSelect') {
                                        return (
                                            <Grid item key={item.key}>
                                                <BranchSelect
                                                    label={translate(item.key)}
                                                    onSet={value => {
                                                        storeReferralCheckout.SetInvoiceValue(item.key, value)
                                                    }}
                                                    value={storeReferralCheckout.GetInvoiceValue('branch_number')}
                                                    inputProps={{
                                                        style: {
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    } else {
                                        if (item.key === 'zip_code') {
                                            return (
                                                <Grid item key={item.key}>
                                                    <ModalSearch
                                                        label={translate(item.key)}
                                                        options={[]}
                                                        value={storeReferralCheckout.GetInvoiceValue(item.key)}
                                                        onChange={e => {
                                                            let allowNumber = e.target.value.replace(/[^0-9]/g, '')
                                                            storeReferralCheckout.SetInvoiceValue(item.key, allowNumber)
                                                        }}
                                                        onSet={value => {
                                                            if (StoreTranslate.CurrentLanguage() === 'EN') {
                                                                storeReferralCheckout.SetInvoiceValue('zip_code', value.zip)
                                                                storeReferralCheckout.SetInvoiceValue('province', value.city_roman)
                                                                storeReferralCheckout.SetInvoiceValue('district', value.sub1_roman)
                                                                storeReferralCheckout.SetInvoiceValue('sub_district', value.sub2_roman)
                                                            } else {
                                                                storeReferralCheckout.SetInvoiceValue('zip_code', value.zip)
                                                                storeReferralCheckout.SetInvoiceValue('province', value.city_native)
                                                                storeReferralCheckout.SetInvoiceValue('district', value.sub1_native)
                                                                storeReferralCheckout.SetInvoiceValue('sub_district', value.sub2_native)
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        } else if (item.key === 'province') {
                                            return (
                                                <Grid item key={item.key}>
                                                    <ProvinceSelect2
                                                        label={translate(item.key)}
                                                        onSet={value => {
                                                            storeReferralCheckout.SetInvoiceValue(item.key, value)
                                                        }}
                                                        value={storeReferralCheckout.GetInvoiceValue(item.key)}
                                                        inputProps={{
                                                            style: {
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        provinceKey={'province-invoice'}
                                                        context="taxInvoiceDialog"
                                                    />
                                                </Grid>
                                            )
                                        } else {
                                            return (
                                                <Grid item key={item.key}>
                                                    <DefaultInput
                                                        label={translate(item.key)}
                                                        type="text"
                                                        value={storeReferralCheckout.GetInvoiceValue(item.key)}
                                                        onChange={e => {
                                                            if (item.args?.regx) {
                                                                const regex = new RegExp(item.args.regx)
                                                                if (regex.test(e.target.value)) {
                                                                    storeReferralCheckout.SetInvoiceValue(item.key, e.target.value)
                                                                }
                                                            } else {
                                                                storeReferralCheckout.SetInvoiceValue(item.key, e.target.value)
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    }
                                })}
                            </Grid>
                        </FormStack>

                        {storeReferralCheckout.invoiceFormsErrors.length > 0 && (
                            <FormStack
                                mb="40px"
                                padding={{
                                    xs: '0px 20px',
                                    md: '0px 30px'
                                }}>
                                <Errors list={storeReferralCheckout.invoiceFormsErrors} />
                            </FormStack>
                        )}
                        <FormStack
                            mb="30px"
                            padding={{
                                xs: '0px 20px',
                                md: '0px 30px'
                            }}
                            className="invoice-form-actions">
                            <Grid container>
                                <Grid
                                    item
                                    sx={{ textAlign: { xs: 'center', sm: 'left' }, marginTop: { xs: '15px', sm: '0' } }}
                                    alignItems={'center'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                                    disabled={isLoading}>
                                    <Typography
                                        variant="h3"
                                        sx={{ textDecoration: 'underline', fontWeight: 400, cursor: 'pointer' }}
                                        onClick={() => {
                                            storeReferralCheckout.invoiceFormsErrors = []
                                            props.onRequestTaxInvoiceModalCancel()
                                        }}>
                                        {translate('cancel')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <DefaultButton
                                        onClick={() => SaveTaxInvoice()}
                                        disabled={isLoading}
                                        loading={isLoading}
                                        titleText={
                                            <Typography variant="h3" sx={{ color: '#fff' }}>
                                                {translate('save')}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </FormStack>
                    </Stack>
                </>
            ) : (
                <Stack justifyContent={'center'} alignItems={'center'} minHeight={'80vh'}>
                    <IconSpinner />
                </Stack>
            )}
        </ThemeProvider>
    )
})

const CustomInputWithEndorment = styled(DefaultInput)`
    .MuiInputAdornment-root {
        position: absolute;
        right: 15px;
    }
`

const FormStack = styled(Stack)`
    &.invoice-form {
        .MuiGrid-root {
            width: 100%;
            margin-bottom: 20px;
            @media screen and (min-width: 600px) {
                justify-content: space-between;
            }
            &.MuiGrid-item {
                @media screen and (min-width: 600px) {
                    width: calc(50% - 10px);
                    &:nth-of-type(1) {
                        width: 100%;
                    }
                }
            }
        }
        &.tax-invoice-corporate-form {
            .MuiGrid-root {
                &.MuiGrid-item {
                    @media screen and (min-width: 600px) {
                        width: calc(50% - 10px);
                        &:nth-of-type(1) {
                            width: calc(50% - 10px);
                        }
                    }
                }
            }
        }
    }
    &.invoice-form-actions {
        .MuiGrid-root {
            justify-content: center;
            align-items: center;
            flex-direction: column-reverse;
            @media screen and (min-width: 600px) {
                flex-direction: row;
                justify-content: space-between;
            }
            &.MuiGrid-item {
                width: 100%;
                @media screen and (min-width: 600px) {
                    width: calc(50% - 10px);
                }
            }
        }
    }
`

const RadioGroupStackStyled = styled(Stack)`
    justify-content: space-between;
    flex-direction: row;

    @media screen and (min-width: 600px) {
        justify-content: flex-start;
        grid-column-gap: 120px;
        column-gap: 120px;
        gap: 120px;
    }
`

export default TaxInvoice
