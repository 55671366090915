import { Checkbox } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactComponent as CheckBoxIcon } from './CheckBox.svg'
import { ReactComponent as CheckBoxCheckedIcon } from './CheckBoxChecked.svg'

const CheckBoxButton = observer(props => {

    return <Checkbox {...props} icon={<CheckBoxIcon />} checkedIcon={<CheckBoxCheckedIcon />} />
})

export default CheckBoxButton