import { Avatar, Button, Stack, Typography, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import StoreUser from 'GlobalStores/User/StoreUser'
import { ReactComponent as SpinnerIcon } from './Assets/Spinner.svg'
import { ReactComponent as PersonIcon } from './Assets/person-icon.svg'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'


const ProfilePhotoSetting = observer((props) => {
    const { onClickChange, onClickRemove, isLoading } = props
    const PROFILE_PICTURE = StoreUser.CustomerData().ProfilePicture('original')

    return (
        <Section showdivider={"true"}>
            <Stack mb={'16px'}>
                <Typography variant="h3" component="h3" fontWeight={500}>
                    <T>profile_photo</T>
                </Typography>
            </Stack>
            <Stack mb={'32px'}>
                <Typography variant="subtitle1" component="p" color={'#8699AF'} fontSize={'14px'}>
                    <T>profile_photo_caption</T>
                </Typography>
            </Stack>
            <Stack sx={{
                flexDirection: 'row',

            }}>
                <Stack
                    sx={{
                        pr: {
                            xs: '32px',
                            md: '48px'
                        },
                        justifyContent: "center",
                        opacity: isLoading ? 0.3 : 1,
                        position: 'relative',
                        '& .MuiAvatar-root': {
                            cursor: 'default'
                        }
                    }}
                >
                    {PROFILE_PICTURE ? (
                        <Avatar
                            sx={{
                                bgcolor: 'transparent',
                                height: '100px',
                                width: '100px',
                                boxShadow: '0px 1px 3px rgba(68, 68, 78, 0.5)',
                                borderRadius: '20px',
                                border: '2.5px solid white',
                                opacity: '',
                            }}
                        >
                            <img src={PROFILE_PICTURE} width={'100%'} height={'100%'} />
                            {isLoading
                                && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <IconSpinnerStyled fontSize={'25px'} className="spinning" />
                                    </div>
                                )}
                        </Avatar>
                    ) : (
                        <Avatar
                            sx={{
                                height: '100px',
                                width: '100px',
                                boxShadow: '0px 1px 3px rgba(68, 68, 78, 0.5)',
                                borderRadius: '20px',
                                border: '2.5px solid white',
                                opacity: '',
                                position: 'relative',
                                backgroundColor: "#A0BBDC",
                                '& > svg': {
                                    fill: '#FFFFFF!important',
                                    width: "65px!important",
                                    height: "65px!important"
                                }
                            }}
                        >

                            <PersonIcon />

                            {isLoading
                                ? (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <IconSpinnerStyled fontSize={'25px'} className="spinning" />
                                    </div>
                                ) : null}

                        </Avatar>
                    )}


                </Stack>
                <Stack sx={{
                    flexDirection: 'row',
                    flexWrap: {
                        xs: 'wrap',
                        sm: 'nowrap'
                    },
                    gap: '24px',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <Stack
                        sx={{
                            width: "100%",
                            maxWidth: "234px",
                            height: "50px",
                            padding: "8px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid #5A8FC3",
                            backgroundColor: "#F6F9FD",
                            color: "#5A8FC4",
                            cursor: 'pointer',
                            textTransform: 'none',
                            userSelect: 'none'

                        }}
                        onClick={onClickChange}
                    >
                        <Typography variant='h4' color={"#5A8FC4"}><T>change_photo</T></Typography>

                    </Stack>
                    <Stack
                        sx={{
                            width: "100%",
                            maxWidth: "234px",
                            height: "50px",
                            padding: "8px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid #5A8FC3",
                            backgroundColor: "#F6F9FD",
                            color: "#5A8FC4",
                            cursor: 'pointer',
                            textTransform: 'none',
                            userSelect: 'none'
                        }}
                        onClick={onClickRemove}

                    >
                        <Typography variant='h4' color={"#5A8FC4"}><T>remove_photo</T></Typography>

                    </Stack>
                </Stack>
            </Stack>
        </Section>
    )
}
)
export default ProfilePhotoSetting

const Section = styled(Stack)`
    @media screen and (max-width: 900px) {
        position: relative;
    }

    border-bottom: 1px solid #CCDEEF;
    padding-bottom: 24px;
    margin-bottom: 24px;

`

const IconSpinnerStyled = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 35px;
    height: 35px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`