import { Card, CardContent, Stack, ThemeProvider, Typography, styled } from '@mui/material'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import React from 'react'

const CardOrderNow = observer(props => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack>
                <CardWrapper>
                    <ContentWrapper>
                        <Typography lineHeight="42px" fontSize={24} fontWeight={700} color={'#003B6F'}>
                            <T>ordernow</T>
                        </Typography>
                        <OrderNowDescription mt="20px">
                            <T>ordernow_desc</T>
                        </OrderNowDescription>
                    </ContentWrapper>
                </CardWrapper>
            </Stack>
        </ThemeProvider>
    )
})

const CardWrapper = styled(Card)`
    width: 100%;

    @media screen and (min-width: 1180px) {
        width: 400px;
    }
`

const ContentWrapper = styled(CardContent)`
    padding: 20px !important;

    @media screen and (min-width: 1180px) {
        padding: 30px !important;
    }
`

const OrderNowDescription = styled(Typography)`
    font-size: 16px !important;
    font-weight: 400;
    color: #33628c;
`

export default CardOrderNow
