import { Stack } from '@mui/material'
import OrderHistory, { OrderHistoryWrapperMobileLayout } from 'Components/Elements/OrderHistory/OrderHistoryWrapper'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import UserAccount from '../UserAccount'

const OrderHistoryPage = observer(() => {
    return (
        <Stack>
            {storeElement.dynamicWidth <= 834 ? (
                <OrderHistoryWrapperMobileLayout></OrderHistoryWrapperMobileLayout>
            ) : (
                <UserAccount>
                    <OrderHistory />
                </UserAccount>
            )}
        </Stack>
    )
})

export default OrderHistoryPage
