import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Stack,
    ThemeProvider,
    Typography,
    createTheme,
    styled
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import './ModalSelect.css'
import SelectWheel from './SelectWheel'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import DefaultInput from '../DefaultInput'
import { useEffect } from 'react'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import Flag from 'Components/Elements/Flag'
import { ReactComponent as Lock } from '../Assets/Union.svg'

let DialogWrapper = Dialog
const ModalSelect = observer(({ placeholder, ...props }) => {
    const translate = useTranslate()
    const [open, setOpen] = useState(false)
    const [isShowTitle, setIsShowTitle] = useState(false)
    const [value, setValue] = useState(props.value || '')
    const [themeStyle, setThemeStyle] = useState(ModalSelectTheme)
    const onDataSet = () => {
        props.onSet(value)
        setOpen(false)
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden !important'
            document.getElementById('root').style.touchAction = 'none'
            document.getElementsByTagName('body')[0].style.touchAction = 'none'
        } else {
            document.body.style.overflow = ''
            document.getElementById('root').style.touchAction = ''
            document.getElementsByTagName('body')[0].style.touchAction = ''
        }
    }, [open])

    useEffect(() => {
        return () => {
            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                let body = document.getElementById('root')
                if (body) {
                    body.style.position = 'relative'
                }
            }
        }
    }, [])

    return (
        <ThemeProvider theme={themeStyle}>
            <Stack id="dialog-modal">
                {props.InputProps ? (
                    <UInputText
                        value={props.value}
                        variant="outlined"
                        label={
                            props.label.length > 0 ? (
                                <Typography variant="label" color="rgba(21, 56, 98, 0.5)">
                                    <T>{props.label}</T>
                                </Typography>
                            ) : (
                                ''
                            )
                        }
                        id={`address-${props.keys}`}
                        InputProps={props.InputProps}
                        onClick={() => {
                            if (props.disabled) return
                            setOpen(true)
                        }}
                        inputlabelprops={{
                            shrink: true
                        }}
                        inputProps={{ 'aria-autocomplete': 'none', autoComplete: 'new-password', spellCheck: 'false' }}
                        placeholder={placeholder}
                        disabled={props.disabled}
                    />
                ) : (
                    <DefaultInput
                        id={`address-${props.keys}`}
                        onClick={() => {
                            if (props.disabled) return
                            setOpen(true)
                        }}
                        value={props.value}
                        label={props.label}
                        disabled={props.disabled}
                        placeholder={placeholder}
                    />
                )}
                <DialogWrapper disablePortal open={open} onClose={() => setOpen(false)} scroll="paper">
                    {isShowTitle && (
                        <DialogTitle>
                            -- <T>{`select_${props.keys}`}</T> --
                            {/* -- <T>select_state_province</T> -- */}
                        </DialogTitle>
                    )}
                    <DialogContent>
                        <SelectWheel
                            onSet={selectedValue => {
                                setValue(selectedValue)
                            }}
                            optionSet={props.options}
                            value={props.value}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} borderRight={'1px solid #e3e8ed'}>
                            <Button onClick={() => setOpen(false)} disableRipple disableFocusRipple sx={{ width: '100% !important' }}>
                                <T>cancel</T>
                            </Button>
                        </Box>
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} ml="0px!important">
                            <Button onClick={onDataSet} className="ok-button" disableRipple disableFocusRipple sx={{ width: '100% !important' }}>
                                <T>ok</T>
                            </Button>
                        </Box>
                    </DialogActions>
                </DialogWrapper>
            </Stack>
        </ThemeProvider>
    )
})

const DefaultDialogWrapper = styled(Dialog)(({ theme }) => {
    return {
        '.MuiDialog-paper': {
            '.MuiDialogTitle-root': {
                fontSize: '16px',
                padding: '16px 24px 0px',
                textAlign: 'center'
                // display: 'none !important'
            }
        }
    }
})

const ModalSelectTheme = createTheme({
    typography: {
        fontFamily: 'Prompt, sans-serif!important',
        option: {
            fontSize: '1.833333em',
            fontFamily: 'Prompt, sans-serif!important',
            padding: '0.5em'
        }
    },
    components: {
        MuiDialog: {
            defaultProps: {
                PaperComponent: 'div'
            },
            styleOverrides: {
                paper: {
                    backgroundColor: '#f7f7f7',
                    width: '270px',
                    borderRadius: '7px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    zIndex: '2'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    border: '1px solid #e3e8ed',
                    padding: 0
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    with: '100% !important',
                    fontFamily: 'Prompt, sans-serif!important',
                    color: '#22aaec',

                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&.ok-button': {
                        fontWeight: 'bold'
                    }
                }
            }
        }
    }
})

const TaiwanModalSelectTheme = createTheme({
    ...ModalSelectTheme,
    components: {
        ...ModalSelectTheme.components,
        MuiDialog: {
            defaultProps: {
                PaperComponent: 'div'
            },
            styleOverrides: {
                paper: {
                    backgroundColor: '#f7f7f7',
                    width: '300px',
                    borderRadius: '7px',
                    '@media (min-width: 768px)': {
                        width: '350px'
                    }
                }
            }
        }
    }
})

const UInputText = styled(DefaultInput)`
    &.MuiFormControl-root.MuiTextField-root {
        width: 100%;

        .MuiInputBase-root {
            padding: 24px 16px;
            border: 1px solid #ccdeef;
            background-color: ${props => (props.disabled ? '#f8fafc !important' : '')};
            border-radius: 6px;
            height: 50px;
            .MuiInputBase-input {
                padding-left: 8px;
                color: #153862;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                padding: 0;
            }

            .MuiOutlinedInput-notchedOutline,
            .MuiTypography-label {
                display: none;

                legend {
                    display: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
            }
        }
        .MuiTypography-label {
            display: none;
        }
    }
`

export default ModalSelect
