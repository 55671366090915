import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogContentText,
    Fade,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Modal,
    Paper,
    Stack,
    ThemeProvider,
    Typography
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useEffect, useLayoutEffect, useState } from 'react'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import Errors from 'Components/Elements/Errors/Errors'
import { DefaultButton } from './Forms'
import { FormSelector } from './Forms/FormSelector'
import { runInAction, toJS } from 'mobx'
import ReCaptcha from './ReCaptcha'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import storeAuth from 'GlobalStores/User/StoreAuth'

import { useNavigate } from 'react-router-dom'
import storeReferralCheckout from '../Stores/StoreReferralCheckout'
// import storeFormAddress from '../Stores/StoreFormAddress'
import TaxInvoice from './TaxInvoice/TaxInvoice'
import { ReactComponent as ExclaimationIcon } from './TaxInvoice/exclaim.svg'
import CheckBoxButton from './Forms/RadioButton/CheckboxButton'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import ModalLogin from './ModalLogin'
import StoreReferralFormat from '../Stores/StoreReferralFormat'
import StoreReferralCart from '../Stores/StoreReferralCart'
import storeReferral from '../Stores/StoreReferral'
import { get } from 'lodash'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { isOnlySpecialCharactor } from 'Utils/Utils'
import MobileInput from './Forms/MobileInput/MobileInput'

import UAECountry from './Forms/Data/UAECountry.json'
import { uaeAreaCountries } from '../Helpers/UAEHelpers'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

const CreateAccountForm = observer(() => {
    const [reCaptchaVerify, setReCaptchaVerify] = useState(false)
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const translate = useTranslate()
    const navigate = useNavigate()

    const handleReCaptCha = value => {
        setReCaptchaVerify(value)
    }

    useEffect(() => {
        runInAction(() => {
            storeCheckout.duplicatedEmail = false
            storeCheckout.duplicatedPhone = false
            storeCheckout.checkoutErrors = []
        })
        storeFeelGreat.GetCheckoutForms().createYourAccount?.inputs.map(item => {
            storeCheckout.SetItemToForm(item)
        })
        storeCheckout.GetCheckoutForms().map(item => {
            if (item.key === 'shipping_method_selection' && !item.value) {
                item.value = StoreTranslate.IsEnglish() ? 'Standard shipping' : 'شحن قياسي'
            }
            storeCheckout.SetItemToForm(item)
        })
        storeCheckout.ReArrangeForm()
    }, [])

    useEffect(() => {
        setReCaptchaVerify(storeAuth.IsAuthorized())
    }, [storeAuth.IsAuthorized()])

    const handleSubmit = async () => {
        try {
            runInAction(() => {
                storeCheckout.duplicatedEmail = false
                storeCheckout.duplicatedPhone = false
                storeCheckout.checkoutErrors = []
                storeElement.showCardFrontDrop = true
            })

            // Prevent address has only special characters
            const addressValue = get(
                storeCheckout.GetCheckoutForms().find(item => item.key === 'address'),
                'value',
                ''
            )
            if (isOnlySpecialCharactor(addressValue)) {
                storeCheckout.checkoutErrors = ['error_address_invalid_special_characters']
                throw new Error('error_address_invalid_special_characters')
            }

            const responseCreateAccount = await storeReferralCheckout.SubmitCreateAccountForm()
            const uuid = responseCreateAccount.uuid

            if (responseCreateAccount.success === true) {
                navigate(`/checkout/payment?uuid=${uuid}`)

                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeCheckout.checkoutPreparing = false
                }, 1000)
            }

            if (responseCreateAccount.success === false) {
                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeCheckout.checkoutPreparing = false

                    // const keyFiltered = ['fullname', 'email', 'password', 'phone']

                    // const errors = responseCreateAccount.Message_v2

                    // const filteredErrorsForInfoForm = errors.filter(error => keyFiltered.some(k => error.includes(k)))

                    // const filteredErrorsForMainForm = errors.filter(error => !keyFiltered.some(k => error.includes(k)))

                    // storeCheckout.checkoutErrors = filteredErrorsForMainForm
                    // storeCheckout.checkoutErrorsFormInfo = filteredErrorsForInfoForm
                    storeCheckout.checkoutErrors = responseCreateAccount.Message_v2

                    if (responseCreateAccount.isDuplicatedEmail === true) {
                        storeCheckout.duplicatedEmail = true
                    }

                    if (responseCreateAccount.isDuplicatedPhone) {
                        storeCheckout.duplicatedPhone = true
                    }

                    // if (responseCreateAccount.isDuplicatedPhone === true) {
                    //     storeCheckout.checkoutErrors = ['duplicated_phone']
                    // }
                })
            }
        } catch (error) {
            console.log(error.message)
            runInAction(() => {
                storeElement.showCardFrontDrop = false
            })
        }
    }

    const [isModalTaxInvoiceDescriptionOpen, setIsModalTaxInvoiceDescriptionOpen] = useState(false)
    const [isModalTaxInvoiceRequestOpen, setIsModalTaxInvoiceRequestOpen] = useState(false)

    const onRequestTaxInvoiceModal = () => {
        if (isModalTaxInvoiceRequestOpen === false) {
            if (storeReferralCheckout.requestTaxInvoice === false) {
                setIsModalTaxInvoiceRequestOpen(true)
            } else {
                storeReferralCheckout.requestTaxInvoice = false
            }
        } else {
            setIsModalTaxInvoiceRequestOpen(false)
            storeReferralCheckout.requestTaxInvoice = false
        }
    }

    const setCloseModal = () => {
        setIsModalTaxInvoiceRequestOpen(false)
        storeReferralCheckout.requestTaxInvoice = true
    }

    const onClickShowModalLogin = () => {
        setOpenLoginModal(true)
    }

    const onClickShowModalNoRef = () => {
        runInAction(() => {
            storeElement.showNoReferralModal = true
        })
    }

    const getErrorMessages = () => {
        const countryValue = get(
            (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'country'),
            'value'
        )
        const uaeCountryObj = uaeAreaCountries(countryValue)

        return storeCheckout.checkoutErrors.map(item => {
            // Flat number/Building/Street address cannot be empty.
            let newItem = item

            if (item === 'error_address_street_empty') {
                switch (get(uaeCountryObj, 'name', '')) {
                    case 'Bahrain':
                        newItem = 'error_bhr_address_empty'
                        break
                    case 'Kuwait':
                        newItem = 'error_kwt_address_empty'
                        break
                    case 'Qatar':
                        newItem = 'error_qar_address_empty'
                        break
                    case 'Saudi Arabia':
                        newItem = 'error_sar_address_empty'
                        break
                    case 'United Arab Emirates':
                        newItem = 'error_uae_address_empty'
                        break
                    default:
                        newItem = 'error_address_empty'
                        break
                }
            }

            if (item === 'error_city_empty') {
                switch (countryValue) {
                    case 'Bahrain':
                        newItem = 'error_bhr_city_empty'
                        break
                    case 'Kuwait':
                        newItem = 'error_kwt_city_empty'
                        break
                    case 'Qatar':
                        newItem = 'error_qar_city_empty'
                        break
                    case 'Saudi Arabia':
                        newItem = 'error_sar_city_empty'
                        break
                    default:
                        newItem = 'error_city_empty'
                        break
                }
            }

            return translate(newItem)
        })
    }

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false)
            return
        }

        const countryValue = get(
            (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'country'),
            'value'
        )

        const uaeCountryObj = uaeAreaCountries(countryValue)

        const countryName = get(uaeCountryObj, StoreTranslate.IsEnglish() ? 'name' : 'native_name', '')

        const shippingMethod = StoreTranslate.IsEnglish() ? 'Standard shipping' : 'شحن قياسي'

        runInAction(() => {
            storeCheckout.SetCheckoutFormValue('country', countryName)
            storeCheckout.SetCheckoutFormValue('shipping_method_selection', shippingMethod)

            const isChainAddress = get(storeCheckout.CheckOutInstance().Instance(), 'chainAddress.isChainAddress')
            const groupAddress = get(storeCheckout.CheckOutInstance().Instance(), 'chainAddress.groupAddress', []) || []

            if (isChainAddress && groupAddress.includes('country')) {
                const keyToSetInit = groupAddress.slice(groupAddress.indexOf('country') + 1) || []
                keyToSetInit.forEach(key => storeCheckout.SetCheckoutFormValue(key, ''))
            }
        })
    }, [StoreTranslate.CurrentLanguage()])

    const renderFormClass = () => {
        const countryValue = get(
            (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'country'),
            'value'
        )

        const shippingMethodValue = get(
            (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'shipping_method_selection'),
            'value'
        )

        const isAramex = shippingMethodValue === 'Aramex with SNS'

        const uaeCountryObj = uaeAreaCountries(countryValue)

        const countryName = get(uaeCountryObj, 'name', '')

        if (['United Arab Emirates'].includes(countryName)) {
            return 'shipping-address1'
        } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName) && !isAramex) {
            return 'shipping-address1'
        } else if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName) && isAramex) {
            return 'shipping-address3'
        } else if (['Saudi Arabia'].includes(countryName) && !isAramex) {
            return 'shipping-address1'
        } else if (['Saudi Arabia'].includes(countryName) && isAramex) {
            return 'shipping-address3'
        } else {
            return 'shipping-address1'
        }
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Card sx={{ marginTop: storeAuth.IsAuthorized() && storeElement.dynamicWidth < 1180 ? '15px' : 0 }}>
                <CardContent
                    sx={{
                        padding: {
                            xs: '30px 30px !important',
                            sm: '30px 30px !important',
                            lg: '40px 40px !important'
                        }
                    }}>
                    <Typography lineHeight="42px" fontSize={24} fontWeight={700} color={'#003B6F'}>
                        <T>shipping_address</T>
                    </Typography>

                    <ThemeSelector countryCapitalize={storeCountry.CountryCapitalize()}>
                        <PaperStyled id="uae-create-account-form" className={`create-account--dynamic-form ${renderFormClass()}`}>
                            {!ObjectHelpers.isEmpty(storeCheckout.GetCheckoutForms()) &&
                                storeCheckout.GetCheckoutForms().map(item => {
                                    const countryValue = get(
                                        (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'country'),
                                        'value'
                                    )

                                    const shippingMethodValue = get(
                                        (storeCheckout.GetCheckoutForms() || []).find(item => item.key === 'shipping_method_selection'),
                                        'value'
                                    )

                                    const isAramex = shippingMethodValue === 'Aramex with SNS'

                                    // const createAccount = document.getElementById('uae-create-account-form')

                                    const uaeCountryObj = uaeAreaCountries(countryValue)

                                    const countryName = get(uaeCountryObj, 'name', '')

                                    // if (createAccount && ['United Arab Emirates'].includes(countryName)) {
                                    //     if (!createAccount.classList.contains('shipping-address1')) {
                                    //         createAccount.classList.add('shipping-address1')
                                    //     }
                                    //     createAccount.classList.remove('shipping-address2')
                                    //     createAccount.classList.remove('shipping-address3')
                                    // } else if (createAccount && ['Bahrain', 'Kuwait', 'Qatar'].includes(countryName) && !isAramex) {
                                    //     if (!createAccount.classList.contains('shipping-address1')) {
                                    //         createAccount.classList.add('shipping-address1')
                                    //     }
                                    //     createAccount.classList.remove('shipping-address2')
                                    //     createAccount.classList.remove('shipping-address3')
                                    // } else if (createAccount && ['Bahrain', 'Kuwait', 'Qatar'].includes(countryName) && isAramex) {
                                    //     if (!createAccount.classList.contains('shipping-address3')) {
                                    //         createAccount.classList.add('shipping-address3')
                                    //     }
                                    //     createAccount.classList.remove('shipping-address1')
                                    //     createAccount.classList.remove('shipping-address2')
                                    // } else if (createAccount && ['Saudi Arabia'].includes(countryName) && !isAramex) {
                                    //     if (!createAccount.classList.contains('shipping-address1')) {
                                    //         createAccount.classList.add('shipping-address1')
                                    //     }
                                    //     createAccount.classList.remove('shipping-address2')
                                    //     createAccount.classList.remove('shipping-address3')
                                    // } else if (createAccount && ['Saudi Arabia'].includes(countryName) && isAramex) {
                                    //     if (!createAccount.classList.contains('shipping-address3')) {
                                    //         createAccount.classList.add('shipping-address3')
                                    //     }
                                    //     createAccount.classList.remove('shipping-address1')
                                    //     createAccount.classList.remove('shipping-address2')
                                    // }

                                    const excludeKeys = ['fullName', 'email', 'password', 'phone']

                                    if (['Bahrain', 'Kuwait', 'Qatar'].includes(countryName)) {
                                        if (isAramex) {
                                            excludeKeys.push('area_selection', 'province_selection', 'city_selection', 'address', 'native_phone_number')
                                        } else {
                                            excludeKeys.push('area_selection', 'province_selection', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                                        }
                                    } else if ('United Arab Emirates'.includes(countryName)) {
                                        excludeKeys.push(
                                            'province_selection',
                                            'shipping_method_selection',
                                            'sns_city_code',
                                            'sns_mailbox_code',
                                            'government_id'
                                        )
                                    } else if (['Saudi Arabia'].includes(countryName)) {
                                        if (isAramex) {
                                            excludeKeys.push(
                                                'area_selection',
                                                'province_selection',
                                                'city_selection',
                                                'address',
                                                'native_phone_number'
                                            )
                                        } else {
                                            excludeKeys.push('area_selection', 'sns_city_code', 'sns_mailbox_code', 'native_phone_number')
                                        }
                                    }

                                    if (excludeKeys.includes(item.key)) {
                                        return false
                                    }

                                    if (
                                        countryName === 'United Arab Emirates' &&
                                        item.key === 'native_phone_number' &&
                                        storeCheckout.GetPhoneNumberCode() === '+971'
                                    ) {
                                        return false
                                    }

                                    return (
                                        <Box boxSizing={'border-box'} className="Input" key={item.key}>
                                            {item.key === 'native_phone_number' ? (
                                                <MobileInput
                                                    currentValue={item.value}
                                                    onChange={value => {
                                                        storeCheckout.SetCheckoutFormValue(item.key, value)
                                                    }}
                                                    item={item}
                                                    InputProps={{
                                                        autoComplete: 'new-password',
                                                        autoCorrect: 'off',
                                                        spellCheck: 'false',
                                                        startAdornment: (
                                                            <InputAdornment sx={{ marginRight: '5px' }} position="start">
                                                                <Typography variant="label" fontSize={'14px'} fontWeight={'400'} color="#000000">
                                                                    +971
                                                                </Typography>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            ) : (
                                                FormSelector(translate, item)
                                            )}
                                        </Box>
                                    )
                                })}
                        </PaperStyled>

                        {storeCheckout.GetCheckoutErrors().length > 0 && (
                            <Stack mt="40px">
                                <Errors list={getErrorMessages()} toTranslate={false} />
                            </Stack>
                        )}
                        {(storeCheckout.duplicatedEmail || storeCheckout.duplicatedPhone) && (
                            <Stack width="100%" mt="20px">
                                <Errors>
                                    <Box textAlign={'center'} pl="30px" pr="30px">
                                        <Box>
                                            <Typography variant="error">
                                                {storeCheckout.duplicatedPhone ? <T>duplicated_phone_and_go_login</T> : <T>duplicated_email_and_go_login</T>}
                                            </Typography>{' '}
                                            <Typography
                                                variant="link"
                                                onClick={() => setOpenLoginModal(true)}
                                                color={'#FE5D7C'}
                                                fontWeight="bold"
                                                sx={{ cursor: 'pointer' }}>
                                                <T>login</T>
                                            </Typography>
                                            <Typography variant="error">?</Typography>
                                        </Box>
                                    </Box>
                                </Errors>
                            </Stack>
                        )}
                    </ThemeSelector>

                    <Modal
                        open={openLoginModal}
                        onClose={() => setOpenLoginModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Fade in={openLoginModal}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 'calc(50% - 40px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    padding: '0px',
                                    outline: 'none'
                                }}>
                                <ModalLogin setOpenLoginModal={setOpenLoginModal} />
                            </Box>
                        </Fade>
                    </Modal>

                    <Grid
                        gridTemplateColumns={'1fr'}
                        sx={{
                            display: storeElement.dynamicWidth < 768 ? 'flex' : 'grid',
                            flexDirection: 'column',
                            marginTop: '30px'
                        }}>
                        {/* <Box
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            boxSizing={'border-box'}
                            marginRight={'20px'}
                            className="Input">
                            <StackAgreeAndVerify
                                justifyContent={{
                                    xs: 'center',
                                    sm: 'flex-start'
                                }}>
                                <ReCaptcha handleReCaptCha={handleReCaptCha} />
                            </StackAgreeAndVerify>
                        </Box> */}

                        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={'16px'}>
                            <DefaultButton
                                width={storeElement.dynamicWidth < 700 ? '100%' : '350px'}
                                titleText={translate('continue')}
                                isloading={storeElement.showCardFrontDrop.toString()}
                                // disabled={reCaptchaVerify ? false : true}
                                onClick={storeReferral.HasReferral() ? handleSubmit : onClickShowModalNoRef}
                            />
                            <Typography 
                                variant='caption3' 
                                color={'#5A8FC3'} 
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => navigate('/')}
                            >
                                <T>back</T>
                            </Typography>
                        </Box>
                        
                    </Grid>
                </CardContent>
            </Card>
            {/* <DialogStyled
                open={isModalTaxInvoiceDescriptionOpen}
                onClose={() => setIsModalTaxInvoiceDescriptionOpen(false)}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                scroll="body"
                className="tax-invoice-dialog"
                keepMounted>
                <DialogContent>
                    <Box>
                        <Typography id="keep-mounted-modal-title" variant="h2" component="h2">
                            {translate('request_for_full_tax_invoice')}
                        </Typography>
                        <Typography
                            id="keep-mounted-modal-description"
                            sx={{ marginTop: '30px' }}
                            variant="h4"
                            component={'h4'}
                            dangerouslySetInnerHTML={{ __html: translate('request_for_full_tax_invoice_desc') }}
                        />
                    </Box>
                </DialogContent>
            </DialogStyled>
            <DialogStyled
                open={isModalTaxInvoiceRequestOpen}
                onClose={() => {}}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                scroll="paper"
                disableEscapeKeyDown={true}
                className="tax-invoice-dialog"
                keepMounted>
                <DialogContent
                    sx={{
                        padding: '0px'
                    }}>
                    <DialogContentText id="taxInvoiceDialog">
                        <TaxInvoice onRequestTaxInvoiceModalCancel={onRequestTaxInvoiceModal} setCloseModal={setCloseModal} />
                    </DialogContentText>
                </DialogContent>
            </DialogStyled> */}
        </ThemeProvider>
    )
})

const ThemeSelector = ({ countryCapitalize, children }) => {
    const [theme, setTheme] = useState()

    useLayoutEffect(() => {
        if (!theme) {
            const themeName = `${countryCapitalize}FormTheme`
            import(`./Forms/Theme/${themeName}`).then(module => {
                setTheme(module.FormTheme)
            })
        }
    }, [countryCapitalize, theme])

    return <>{theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : null}</>
}

const PaperStyled = styled(Paper)`
    padding-top: 20px !important;
    box-shadow: none;

    @media screen and (max-width: 699px) {
        .MuiBox-root {
            width: 100% !important;

            :nth-of-type(1),
            :nth-of-type(3),
            :nth-of-type(6) {
                margin-right: 0px !important;
            }

            :nth-of-type(6) {
                margin-bottom: 0px !important;
            }
        }
    }

    @media screen and (min-width: 700px) {
        padding-top: 30px !important;
    }
`

const StackSkeletonLoading = styled(Stack)`
    @media screen and (min-width: 700px) {
        width: 600px;
    }

    @media screen and (min-width: 800px) {
        width: 700px;
    }
`

const StackAgreeAndVerify = styled(Stack)`
    & > div {
        width: 100%;
        margin: 0 auto;
        justify-content: flex-start;
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
    }

    /* @media screen and (min-width: 1024px) {
        flex-direction: row;
        column-gap: 20px;
        & > div {
            width: 50%;
            justify-content: center;
        }
    } */
`

const DialogStyled = styled(Dialog)`
    &.tax-invoice-dialog .MuiPaper-root:first-of-type {
        @media screen and (min-width: 768px) {
            min-width: 700px;
        }
    }
`

export default CreateAccountForm
