import { observer } from 'mobx-react-lite'
import { Stack, Typography, ThemeProvider } from '@mui/material'
import styled from '@emotion/styled'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { useNavigate } from 'react-router-dom'

const SettingCard = observer(props => {
    const navigate = useNavigate()

    return (
        <ThemeProvider theme={storeElement.theme}>
            <SettingCardStyled
                margin={props.margin || ''}
                display={props.display || 'inherit'}
                padding={storeElement.settingCard.padding || props.padding || ''}
                className={storeElement.userLayout.className || 'settingCard'}
                bgcolor={'#ffffff'}>
                {/* <TypographyTitleStyled variant="h3" textAlign={props.titleAlign || 'left'} component={'h4'}>
                    {props.title}
                </TypographyTitleStyled> */}
                {props.subTitle && (
                    <Typography textAlign={props.subTitleAlign || 'left'} mt="30px" variant="subtitle4">
                        {props.subTitle}
                    </Typography>
                )}
                {props.children}
            </SettingCardStyled>
        </ThemeProvider>
    )
})

const SettingCardStyled = styled(Stack)`
    //MOBILE DEFUALT
    height: 100%;
    // background-color: #ffffff;
    border-radius: 12px;

    &.feelgreat--orderhistory {
        background-color: transparent;
    }

    @media screen and (min-width: 900px) {
        background-color: transparent;
    }
`

const TypographyTitleStyled = styled(Typography)`
    font-size: 24px;
    line-height: 32px;

    @media screen and (min-width: 900px) {
        font-size: 32px;
        line-height: 42px;
    }
`

export default SettingCard
