import { Box, Stack, Typography, Tab, Tabs, Fade, Backdrop, ThemeProvider } from '@mui/material'
import { ReactComponent as InfoIcon } from './Assets/info.svg'
import { ReactComponent as Cross } from './Assets/cross.svg'
import Modal from '@mui/material/Modal'
import TabElement from '../TabElement'
import { useEffect, useState } from 'react'
import CountryItem from './CountryItem'
import StoreCountry from '../../../GlobalStores/Cores/County/StoreCountry'
import { get, lowerCase, startsWith, upperCase } from 'lodash'
import CollapseSelectCountry from './CollapseSelectCountry'
import { ServerEnv } from 'Configs/ServerEnv'
import Mustache from 'mustache'
import { useTranslateStaticComponents } from 'GlobalStores/Cores/Translator/useTranslate'
import storeCountry from '../../../GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeReCaptcha from 'GlobalStores/StoreReCaptcha'
import storeReferral from 'GlobalStores/Referral/StoreReferral'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { storeElement } from 'GlobalStores/StoreElement'

const { observer } = require('mobx-react-lite')

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: {
        xs: '100%',
        sm: '80%'
    },
    width: {
        xs: '100%',
        sm: '80%'
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: { xs: '0px', sm: '8px' },
    border: 'none',

    '&:focus': {
        outline: 'none'
    },
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
        display: 'none'
    }
}

const SelectCountryModal = observer(({ handleClose, open }) => {
    const [value, setValue] = useState(0)
    const [width, setWidth] = useState(window.innerWidth)
    const [isMobile, setIsMobile] = useState(false)

    const translateStaticComponents = useTranslateStaticComponents()

    useEffect(() => {
        getCountriesData()
        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    useEffect(() => {
        setValue(
            StoreCountry.RawCountriesData.findIndex(country => {
                const result = country.list.some(item => item.short === StoreCountry.Country3())
                return result
            })
        )
    }, [open])

    useEffect(() => {
        if (width < 900) {
            setIsMobile(true)
        }
        if (width >= 900) {
            if (value === null) {
                setValue(0)
            }
            setIsMobile(false)
        }
    }, [width, value])

    const getCountriesData = async () => {
        try {
            await StoreCountry.RawSupportedMarketData2()
        } catch (error) {
            console.log(error)
        }
    }

    const updateDimensions = () => {
        setWidth(window.innerWidth)
    }

    const handleChangeTab = (event, index) => {
        setValue(index)
    }

    const a11yProps = index => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`
        }
    }

    const handleClickChangeLanguage = (country, language, defaultLang = '') => {
        const shortCountry = (country.short || '').toLowerCase()
        const shortCurrentCountry = (storeCountry.Country3() || '').toLowerCase()

        storeReCaptcha.setCurrentLanguage(lowerCase(country?.country), defaultLang || language)
        if (shortCountry === shortCurrentCountry) {
            const url = new URL(window.location.href)
            url.searchParams.set('lang', (defaultLang || language || '').toLowerCase())
            window.history.pushState({}, '', url.toString())
            StoreTranslate.SetCurrentLanguage(upperCase(defaultLang || language))

            // * switch city option list language
            if (shortCurrentCountry === 'twn') {
                storeCheckout.CheckOutInstance().Instance().setCityOptionList(true)
            }

            handleClose()
            return
        }

        const url = get(country, `${ServerEnv.ENV ? 'live' : 'test'}`, '')

        if (!url) {
            handleClose()
            return
        }

        let isExternal = startsWith(url, 'https')
        const countryCode = get(country, 'short', '')
        let resultUrl = ''

        if (countryCode !== 'uae' && !/domain/.test(country.live)) {
            isExternal = true
        }

        if (!isExternal) {
            resultUrl = url
                .replace('{{{domain}}}', ServerEnv.ENV ? `https://fg.unicity.com` : 'http://localhost:3000')
                .replace('{{{language}}}', isExternal ? (defaultLang || language).toLowerCase() : `?lang=${(defaultLang || language).toLowerCase()}`)
        } else {
            resultUrl = url.replace('{{{language}}}', isExternal ? (defaultLang || language).toLowerCase() : `?lang=${(defaultLang || language).toLowerCase()}`)
        }

        // let resultUrl = Mustache.render(url, {
        //     domain: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : ''),
        //     country: countryCode.toLowerCase(),
        //     language: isExternal ? (defaultLang || language).toLowerCase() : `?lang=${(defaultLang || language).toLowerCase()}`
        // })

        if (storeReferral.referralData) {
            const baId = get(storeReferral.referralData, 'baId', '')
            if (/\?/.test(resultUrl)) {
                resultUrl = resultUrl.split('?')
                resultUrl = `${resultUrl[0]}/${baId}?${resultUrl[1]}`
            } else {
                if (/home/.test(resultUrl)) {
                    resultUrl = `${resultUrl.replace('home', 'c')}/${baId}`
                } else {
                    resultUrl = `/c/${baId}`
                }
            }
        }
        window.open(resultUrl, isExternal ? '_blank' : '_self')
    }

    return (
        <ThemeProvider theme={/thailand\/referral/.test(window.location.pathname) ? storeElement.theme : MainTemplate}>
            <Modal
                open={open}
                onClose={handleClose}
                className="modal-select-country"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 350
                }}>
                <Fade in={open}>
                    <Box sx={style}>
                        <Stack
                            sx={{
                                position: 'relative',
                                padding: '50px 30px',
                                overflowY: 'scroll',
                                '::-webkit-scrollbar': {
                                    display: 'none'
                                }
                            }}
                            className="modal-wrapper">
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    cursor: 'pointer'
                                }}
                                onClick={handleClose}>
                                <Cross />
                            </div>

                            {/* Header */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        md: 'row'
                                    },
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    marginBottom: {
                                        xs: '0px',
                                        md: '30px'
                                    }
                                }}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: {
                                            xs: '18px',
                                            md: '20px',
                                            lg: '24px'
                                        },
                                        color: '#153862',
                                        textAlign: {
                                            xs: 'left',
                                            md: 'left'
                                        },
                                        marginBottom: {
                                            xs: '20px',
                                            md: '0'
                                        }
                                    }}>
                                    {translateStaticComponents('select_country_language')}
                                </Typography>
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        backgroundColor: '#E9F1FA',
                                        padding: '8px 17px',
                                        borderRadius: '6px'
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }}>
                                        <InfoIcon />
                                    </Box>
                                    <Typography
                                        variant="label"
                                        sx={{
                                            fontSize: {
                                                xs: '12px',
                                                md: '10px',
                                                lg: '12px'
                                            },
                                            color: '#153862'
                                        }}>
                                        {translateStaticComponents('change_country_description')}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Tab */}

                            {isMobile ? (
                                <Box>
                                    <CollapseSelectCountry value={value} setValue={setValue} handleClickChangeCountry={handleClickChangeLanguage} />
                                </Box>
                            ) : (
                                <Box>
                                    <TabElement value={value}>
                                        <Box className="tab-bar">
                                            <Tabs value={value} onChange={handleChangeTab}>
                                                {StoreCountry.RawCountriesData.map((e, index) => (
                                                    <Tab
                                                        label={
                                                            <Typography
                                                                variant="h2"
                                                                fontSize={{
                                                                    xs: '12px',
                                                                    sm: '14px'
                                                                }}>
                                                                {translateStaticComponents(e.continent)}
                                                            </Typography>
                                                        }
                                                        {...a11yProps(index)}
                                                        key={`tab-${index}`}
                                                    />
                                                ))}
                                            </Tabs>
                                        </Box>
                                        {StoreCountry.RawCountriesData.map((e, index) => (
                                            <TabElement.TabPanel value={value} index={index} key={`tab-content-${index}`}>
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: {
                                                            xs: '1fr',
                                                            sm: '1fr 1fr',
                                                            md: '1fr 1fr 1fr ',
                                                            lg: '1fr 1fr 1fr 1fr'
                                                        },
                                                        gap: '20px',
                                                        height: '100%',
                                                        minWidth: '100%'
                                                    }}>
                                                    {value === index &&
                                                        e.list.map((country, index) => {
                                                            const mm = country.maintenance_mode
                                                            if (mm.maintenance_mode) {
                                                                if (/developing/.test(mm.text.english) || /developing/.test(mm.text.native)) {
                                                                    if (/fg.unicity.com/.test(window.location.hostname)) {
                                                                        return false
                                                                    }
                                                                }
                                                            }
                                                            return (
                                                                <CountryItem
                                                                    country={country}
                                                                    key={`country-${index}`}
                                                                    handleClick={handleClickChangeLanguage}
                                                                />
                                                            )
                                                        })}
                                                </Box>
                                            </TabElement.TabPanel>
                                        ))}
                                    </TabElement>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </ThemeProvider>
    )
})

export default SelectCountryModal
