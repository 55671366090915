import { makeAutoObservable } from 'mobx'

class StoreSharingSocial {
    loadingSharing = false

    constructor() {
        makeAutoObservable(this)
    }
    /**
     * @returns {Customer} Customer
     */

}
const storeSharingSocial = new StoreSharingSocial()
export default storeSharingSocial
