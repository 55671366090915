import { Box, Modal, Fade, Stack, Typography, ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ModalLogin from './ModalLogin'
import { reaction, runInAction } from 'mobx'
import StoreReferral from '../Stores/StoreReferral'
import { storeElement } from 'GlobalStores/StoreElement'
import { ReactComponent as Exclamation } from './../../../Assets/exclamation.svg'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

const ModalNoReferral = observer((props) => {

    const onClose = () => {
        runInAction(() => {
            storeElement.showNoReferralModal = !storeElement.showNoReferralModal
        })
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Modal
                open={storeElement.showNoReferralModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={() => onClose()}>
                <Fade in={storeElement.showNoReferralModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 'calc(50% - 40px)',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            padding: storeElement.dynamicWidth < 768 ? '20px' : '20px 50px 20px 50px',
                            outline: 'none',
                            background: '#FFF1DC', 
                            borderRadius: '12px',
                            minWidth: '345px',
                            width: storeElement.dynamicWidth > 768 && 'max-content'
                        }}>
                            <Stack flexDirection={'row'}>
                                <Stack justifyContent={'center'} paddingRight={'20px'}>
                                    <Exclamation />
                                </Stack>

                                <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                                    <Typography
                                        variant="caption2"
                                        fontSize={'14px'}
                                    >
                                        <T>footer_login_description</T>
                                    </Typography>

                                    <Typography
                                        mt="5px"
                                        variant="caption2"
                                        sx={{ color: '#33628C', fontSize: '12px', lineHeight: '18px' }}
                                    >
                                        <T>footer_login_sub_description</T>
                                    </Typography>
                                </Stack>
                            </Stack>
                    </Box>
                </Fade>
            </Modal>
        </ThemeProvider>
    )
})

export default ModalNoReferral
