import { Box, Stack, styled, ThemeProvider, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import StoreAuth from 'GlobalStores/User/StoreAuth'

import { DefaultButton, DefaultInput } from '../Forms'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { get } from 'lodash'
import { storeElement } from 'GlobalStores/StoreElement'
import Errors from '../Errors'
import storeCredit from 'GlobalStores/StoreCredit'

import { observer, useLocalObservable } from 'mobx-react-lite'
import CardFrontDrop from '../CardFrontDrop'
import CustomLoginTheme from './CustomLoginTheme'
import { ReactComponent as Exclamation } from './Assets/exclamation.svg'
import storeReferral from '../../Stores/StoreReferral'
import LoginWrapper from '../../LoginWrapper'
import ReCaptcha from '../ReCaptcha'

const LoginCard = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const [showContactReferral, setShowContactReferral] = useState(false)
    const [showLoginFooter, setShowLoginFooter] = useState(props.showFooter)
    const isDev = /localhost/.test(window.location.hostname)

    const localObserver = useLocalObservable(() => ({
        loginFormError: [],
        recaptchaVerified: false
    }))

    const handleReCaptCha = value => {
        localObserver.recaptchaVerified = value
    }

    const validationSchema = yup.object({
        unicityID: yup.string().required(translate('unicity_id_required')),
        password: yup.string().min(4, translate('minimum_four_char_password')).required(translate('password_is_required')),
        keepLoggedIn: yup.boolean()
    })

    const formik = useFormik({
        initialValues: isDev
            ? {
                  unicityID: '',
                  password: '',
                  keepLoggedIn: false
              }
            : {
                  unicityID: '',
                  password: '',
                  keepLoggedIn: false
              },
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            storeElement.showCardFrontDrop = true
            try {
                StoreAuth.loadingLogin = true
                const response = await StoreAuth.Login(values)
                StoreAuth.allowCredit = response.allowCredit
                let isHasCredit = false

                Object.keys(response.allowCredit).map((v, k) => {
                    if (v === 'enable' && response.allowCredit[v] === true) {
                        StoreAuth.allowCredit = response.allowCredit
                        StoreAuth.allowCredit.enable = true
                        isHasCredit = true
                    }
                    return ''
                })

                if (isHasCredit === true) {
                    storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
                }

                StoreCheckout.SetUseMemberPrice(true)

                if (StoreAuth.allowCredit?.enable) {
                    storeCredit.Init({ id: StoreAuth.GetId(), token: StoreAuth.GetToken() })
                }

                localStorage.removeItem('from-logout')

                navigate('/user')

                setTimeout(() => {
                    StoreAuth.loadingLogin = false
                    storeElement.showCardFrontDrop = false
                }, 500)
            } catch (e) {
                if (get(e, 'response.data.error.error.status') === 401) {
                    setErrors({ password: translate('login_or_password_incorrect') })
                } else {
                    if (e.message && e.message === 'user_status_not_allowed') {
                        setErrors({
                            password: translate(e.message)
                        })
                    } else {
                        setErrors({
                            password: get(e, 'response.data.displayError.message', '') || get(e, 'response.data.message', 'Server under maintain!')
                        })
                    }
                }
                StoreAuth.loadingLogin = false
                storeElement.showCardFrontDrop = false
            }
        }
    })

    useEffect(() => {
        let error = []
        if (formik.touched.unicityID && Boolean(formik.errors.unicityID)) {
            error.push(formik.errors.unicityID)
        }

        if (formik.touched.password && Boolean(formik.errors.password)) {
            error.push(formik.errors.password)
        }

        localObserver.loginFormError = error
    }, [formik.errors, formik.touched])

    useEffect(() => {
        if (!!StoreAuth.IsAuthorized() === true) {
            navigate('/')
        }
        if (!storeReferral.HasReferral() && !localStorage.getItem('from-logout')) {
            storeReferral.SetCheckedReferralParam(false)
            setShowContactReferral(true)
        } else {
            setShowContactReferral(false)
        }
    }, [])

    return (
        <ThemeProvider theme={CustomLoginTheme}>
            <LoginWrapper>
                <LoginLayoutWrapper
                    style={{
                        height: 'fit-content',
                        width: '100%'
                    }}>
                    <LoginStackWrapper
                        showContactReferral={showContactReferral}
                        sx={
                            showContactReferral
                                ? { padding: '0px 20px !important', borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }
                                : {
                                      borderBottomLeftRadius: {
                                          xs: '12px',
                                          sm: showLoginFooter === false || !props.canSignup ? '12px' : '0px',
                                          md: showLoginFooter === false || !props.canSignup ? '12px' : '0px'
                                      },
                                      borderBottomRightRadius: {
                                          xs: '12px',
                                          sm: showLoginFooter === false || !props.canSignup ? '12px' : '0px',
                                          md: showLoginFooter === false || !props.canSignup ? '12px' : '0px'
                                      },
                                      boxSizing: 'border-box'
                                  }
                        }>
                        <CardFrontDrop positionTop={0} />
                        <FormWrapper mt="40px" mb="20px" mx="auto">
                            <Stack
                                sx={{
                                    mb: '40px'
                                }}>
                                <Typography variant="h3" align="center" lineHeight={'35px'}>
                                    <T>log_in</T>
                                </Typography>
                            </Stack>

                            <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                                <Box noValidate sx={{ maxWidth: '100%', 'div.MuiBox-root span': { display: 'block', fontSize: '14px', lineHeight: '15px' } }}>
                                    <DefaultInput
                                        autoComplete="off"
                                        id="unicityID"
                                        name="unicityID"
                                        label={translate('email_or_unicity_id')}
                                        value={formik.values.unicityID}
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        type="email"
                                    />
                                </Box>
                                <Box
                                    noValidate
                                    sx={{ maxWidth: '100%', 'div.MuiBox-root span': { display: 'block', fontSize: '14px', lineHeight: '15px' }, mt: '20px' }}>
                                    <DefaultInput
                                        autoComplete="current-password"
                                        id="password"
                                        name="password"
                                        label={translate('password')}
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                    />
                                </Box>

                                <Stack mt="20px" display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ReCaptcha handleReCaptCha={handleReCaptCha} />
                                </Stack>

                                {localObserver.loginFormError.length ? (
                                    <Stack
                                        mt="20px"
                                        sx={{
                                            '& >div.MuiStack-root': { mt: 0, pt: '12px', pb: '12px' },
                                            '& .MuiStack-root .text_error_box:not(:last-of-type) .MuiBox-root': { pb: '12px', mb: '12px' }
                                        }}>
                                        <Errors list={localObserver.loginFormError} />
                                    </Stack>
                                ) : null}

                                <Box
                                    mt="20px"
                                    width="100%"
                                    sx={{ display: 'flex', justifyContent: 'center', '.MuiButtonBase-root.Mui-disabled': { color: '#fff' } }}>
                                    <DefaultButton
                                        disabled={!(formik.isValid && formik.dirty && !StoreAuth.loadingLogin && localObserver.recaptchaVerified)}
                                        type="submit"
                                        fullWidth={true}
                                        titleText={translate('log_in')}
                                        isloading={StoreAuth.loadingLogin.toString()}
                                        sx={{ backgroundColor: '#5A8FC3' }}
                                    />
                                </Box>
                            </form>

                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: '20px',
                                    justifyContent: 'center',
                                    marginBottom: '0px'
                                }}>
                                <Typography
                                    variant="link"
                                    onClick={() => {
                                        if (StoreAuth.loadingLogin) return
                                        navigate('/reset-password')
                                    }}
                                    sx={{ cursor: StoreAuth.loadingLogin ? 'not-allowed !important' : 'pointer', fontWeight: '500', lineHeight: '20px' }}>
                                    {`${translate('forgot_password')}`.split('?')[0]}
                                </Typography>
                            </Box>
                        </FormWrapper>

                        {/* <LoginFooter /> */}
                    </LoginStackWrapper>
                    {showContactReferral && (
                        <LoginStackWrapper
                            sx={{
                                borderTopLeftRadius: '0px',
                                borderTopRightRadius: '0px',
                                backgroundColor: {
                                    xs: '#FFF1DC'
                                },
                                width: '100%',
                                padding: '0px'
                            }}>
                            <NoReferralContact />
                        </LoginStackWrapper>
                    )}
                </LoginLayoutWrapper>
            </LoginWrapper>
        </ThemeProvider>
    )
})

export const NoReferralContact = observer(() => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack flexDirection={'row'} padding={'20px'} justifyContent={'center'}>
                <Stack flexDirection={'row'}>
                    <Stack justifyContent={'center'} paddingRight={'20px'}>
                        <Exclamation />
                    </Stack>

                    <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography variant="caption2" fontSize={'14px'} sx={{ color: '#003B6F' }}>
                            <T>footer_login_description</T>
                        </Typography>

                        <Typography mt="5px" variant="caption2" sx={{ color: '#33628C', fontSize: '12px', lineHeight: '18px' }}>
                            <T>footer_login_sub_description</T>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
})

const LoginLayoutWrapper = styled(Stack)`
    & > .MuiStack-root {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width: 375px) {
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        padding: 0px 10%;
    }

    @media screen and (min-width: 1200px) {
        padding: 0px 20%;
    }

    @media screen and (min-width: 1600px) {
        padding: 0px 30;
    }
`

// White Area
const LoginStackWrapper = styled(Stack)`
    background-color: #fff;
    border-radius: 12px;
    height: fit-content;
    width: 100%;
    overflow: hidden;
    position: relative;

    & > div:last-of-type {
        padding-bottom: ${({ showContactReferral }) => (showContactReferral ? '0px' : '20px')};
    }
`

const FormWrapper = styled(Stack)`
    width: 100%;

    @media screen and (min-width: 375px) {
        width: 300px;
    }
    @media screen and (min-width: 600px) {
        width: 370px;
    }
`

export default LoginCard
