import { makeAutoObservable, toJS } from 'mobx'
import StoreReferralFormat from './StoreReferralFormat'
import { get } from 'lodash'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeUser from 'GlobalStores/User/StoreUser'
import storeReferral from './StoreReferral'
import storeReferralCheckout from './StoreReferralCheckout'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

class storeReferralCart {
    isProductPage = false
    items = []
    cartBottle = {
        id: 32015,
        item_code: '32015',
        qty: 0,
        option: 'onetime' // onetime, subscribe
    }

    bottleItem = {
        id: '32015',
        item_code: '32015',
        name: 'Diamond Bottle',
        qty: 0,
        type: 'single'
    }

    showBottle = false

    cartBottleQty = 0

    totalPrice = 0
    shippingPriceWithoutMethod = 0

    overseasShippingLimit = (countryName) => {

        switch (countryName) {
            case 'Kuwait':
                return 3
            case 'Qatar':
                return 3
            case 'Saudi Arabia':
                return 3
            case 'United Arab Emirates':
                return 3
            default:
                return 3
        }
    }

    showWarningNFRQtyLimitModal = false

    GetBottleQty() {
        return this.cartBottleQty
    }

    increaseQty(id, qty = 1) {

        const selectItem = this.items.find(item => item.id === id)
        const isSingle = selectItem.type === 'single'
        const overseasShippingLimit = this.GetOverseasShippingLimit(storeReferralCheckout.GetCurrentAddressCountry())

        const qty_sellable = get(selectItem, ['options', isSingle ? 'onetime' : StoreReferralFormat.paymentOptionSelected, 'qty'])
        const itemNoLimitList = ['32015']

        this.items = this.items.map(item => {
            if (item.id === id) {

                if (item.qty >= qty_sellable) {
                    return item
                }
                const itemQty = item.qty

                if (((itemQty >= overseasShippingLimit) && overseasShippingLimit) && !itemNoLimitList.includes(id)) {

                    if (this.isProductPage === false) {
                        this.SetShowWarningNFRQtyModal(true)
                    }

                    item.qty = overseasShippingLimit
                    return item
                }

                if (itemQty === qty_sellable) {
                    return item
                }

                if (item.qty >= 0) {
                    item.qty += qty
                }
            }

            return item
        })
    }

    decreaseQty(id, qty = 1) {
        const selectItem = this.items.find(item => item.id === id)
        const isSingle = selectItem.type === 'single'
        const qty_sellable = get(selectItem, ['options', isSingle ? 'onetime' : StoreReferralFormat.paymentOptionSelected, 'qty'])
        const itemsToRemove = []
        this.items = this.items.map(item => {
            if (item.id === id) {
                if (qty_sellable === 0) {
                    return item
                }
                if (item.qty > 1) {
                    item.qty -= qty
                } else {
                    if (isSingle) {
                        if (item.qty > 0) {
                            item.qty -= qty
                        }
                    }
                }
                if (item.qty === 0 && item.id !== '32015') {
                    itemsToRemove.push(item.id)
                }
            }

            return item
        })

        itemsToRemove.forEach(id => {
            this.removeItem(id)
        })
    }

    assignQty(id, qty = 1) {
        const overseasShippingLimit = this.GetOverseasShippingLimit(storeReferralCheckout.GetCurrentAddressCountry())

        const itemNoLimitList = ['32015']

        this.items = this.items.map(item => {
            const isSingle = item.type === 'single'
            const qty_sellable = get(item, ['options', isSingle ? 'onetime' : StoreReferralFormat.paymentOptionSelected, 'qty'])

            if (item.id === id) {
                if ((qty > overseasShippingLimit && overseasShippingLimit) && !itemNoLimitList.includes(id)) {
                    // AddressCountryCartLimit more precedence than qty_sellable
                    item.qty = overseasShippingLimit

                    if (this.isProductPage === false) {
                        this.SetShowWarningNFRQtyModal(true)
                    }


                } else if (qty > qty_sellable) {
                    item.qty = qty_sellable
                } else {
                    item.qty = qty
                }
            }

            return item
        })
    }

    removeItem(id) {
        this.items = this.items.filter(item => item.id.toString() !== id.toString())
    }

    addItem(item) {
        this.items.push(item)
    }

    clearCart() {
        this.items = []
    }

    getTotalItems(noBottle = true) {
        let total = 0
        this.items.forEach(item => {
            if (noBottle) {
                if (item.id !== '32015') {
                    total += item.qty
                }
            } else {
                total += item.qty
            }
        })
        return total
    }

    getTotalPrice() {
        let total = 0

        const paymentOption = StoreReferralFormat.paymentOptionSelected

        this.items.forEach(item => {
            const isSingle = item.type === 'single'
            total += get(item, ['options', isSingle ? 'onetime' : paymentOption, 'price', this.hasDiscount() ? 'member' : 'retail'], 0) * item.qty
        })

        return total
    }

    TotalPriceIncludeShipping(countryValue) {
        let total = 0

        this.shippingPriceWithoutMethod = storeCheckout.CheckOutInstance().Instance().SpecialAreasDeliveryFeeCondition(countryValue)

        const paymentOption = StoreReferralFormat.paymentOptionSelected

        this.items.forEach(item => {
            const isSingle = item.type === 'single'
            if (isSingle) {
                const freeItems = storeFeelGreat.GetContent('freeItems')
                if (freeItems) {
                    const freeItem = freeItems.freeItemProductListFormat.items.find(freeItem => freeItem.id === item.item_code)
                    if (freeItem) {
                        total += get(freeItem, ['options', 'onetime', 'price', this.hasDiscount() ? 'member' : 'retail'], 0) * item.qty
                    }
                }
            } else {
                total += get(item, ['options', isSingle ? 'onetime' : paymentOption, 'price', this.hasDiscount() ? 'member' : 'retail'], 0) * item.qty
            }
        })

        return total + this.shippingPriceWithoutMethod
    }

    GetShippingPriceWithoutMethod() {
        return this.shippingPriceWithoutMethod
    }

    getCartItems() {
        const items = []
        this.items.map(item => {
            if (item.qty > 0) {
                const item_code = get(item, ['options', StoreReferralFormat.paymentOptionSelected, 'item_code'], '')
                const qty = item.qty

                items.push({
                    item_code: item_code || item.item_code,
                    qty: qty,
                    option: StoreReferralFormat.paymentOptionSelected
                })
            }
        })

        return items
    }

    getTotalPV() {
        let total = 0

        const paymentOption = StoreReferralFormat.paymentOptionSelected

        this.items.forEach(item => {
            const isSingle = item.type === 'single'
            total += get(item, ['options', isSingle ? 'onetime' : paymentOption, 'pv'], 0) * item.qty
        })
        return total
    }

    hasDiscount() {
        const statusAllow = storeCountry.GetAllowDiscountStatus()
        const baStatus = storeUser.GetBaStatus()
        //const hasReferral = false

        // TODO:
        const hasReferral = storeReferral.HasReferral()

        if (statusAllow.includes(baStatus) || (hasReferral && baStatus !== 'C')) {
            return true
        }
        return false
    }

    TotalQty() {
        let total = 0
        this.items.forEach(item => {
            total += item.qty
        })
        return total
    }

    GetOverseasShippingLimit = country => {
        return this.overseasShippingLimit(country)
    }

    SetShowWarningNFRQtyModal = val => {
        this.showWarningNFRQtyLimitModal = val
    }

    IsShowWarningNFRQtyModal = () => {
        return this.showWarningNFRQtyLimitModal
    }

    ResetAllItemQtytoOne = () => {
        this.items = this.items.map(item => {
            item.qty = 1
            const product = get(item, ['options', StoreReferralFormat.paymentOptionSelected])
            if (product && product.qty === 0) {
                item.qty = 0
            } else {
                item.qty = 1
            }

            return item
        })

        this.items = this.items.filter(item => item.id !== '32015')
    }

    IsSoldOut() {
        let isSoldOut = false
        this.items.forEach(item => {
            if (item.id === '32015') return
            const isSingle = item.type === 'single'
            const qty_sellable = get(item, ['options', isSingle ? 'onetime' : StoreReferralFormat.paymentOptionSelected, 'qty'])
            if (qty_sellable === 0) {
                isSoldOut = true
            }
        })
        return isSoldOut
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const StoreReferralCart = new storeReferralCart()
export default StoreReferralCart
