import { makeAutoObservable, runInAction } from 'mobx'
import get from 'lodash/get'
import feelGreateAPI from 'GlobalStores/FeelGreat/APIs'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import Customer from 'GlobalStores/User/Customer'

class StoreReferral {
    referralData = null
    referralCode = ''
    loadingReferCode = false
    currentReferralData = null

    instance = null

    constructor() {
        makeAutoObservable(this)
    }
    /**
     * @returns {Customer} Customer
     */
    Customer() {
        if (!this.instance) {
            this.instance = new Customer(this.referralData)
        }
        return this.instance
    }

    SetReferralData(userRef, status) {
        runInAction(() => {
            this.referralData = {
                ...this.referralData,
                ...userRef,
                shortStatus: status
            }
        })
    }

    async GetReferralCode(token) {
        try {
            this.loadingReferCode = true
            let response = await feelGreateAPI.getReferralCodes(token)
            this.referralCode = get(response, 'data.items[0].referralCode', '')
            this.referralData = get(response, 'data.items[0]', null)
            this.loadingReferCode = false
        } catch (error) {
            console.log('getReferralCode error => ', error)
            this.loadingReferCode = false
        }
    }

    UsePrice() {
        if (StoreAuth.GetId()) {
            return 'after'
        } else if (get(this.referralData, 'id.unicity', '').length > 0) {
            return 'after'
        } else {
            return 'retail'
        }
    }

    GetReferal() {
        return this.referralData
    }

    HasReferral() {
        const id = get(this.referralData, 'baId', '')
        return id && !ObjectHelpers.isEmpty(this.referralData.displayName) ? id : false
    }

    reset() {
        this.referralData = null
        this.instance = null
    }

    DisplayName() {
        return get(this.referralData, 'displayName', null)
    }
}
const storeReferral = new StoreReferral()
export default storeReferral
