import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { runInAction } from 'mobx'
import { useState } from 'react'
import { useEffect, useRef } from 'react'
import HttpHelper from 'Services/HttpHelper'
import StoreCountry from '../../../GlobalStores/Cores/County/StoreCountry'
import { useMemo } from 'react'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import BypassRedirectPath from '../../../Data/BypassRedirectPath.json'
export function useCountries() {
    const once = useRef(true)
    const [country, setCountry] = useState()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    useEffect(() => {
        if (once.current) {
            once.current = false
            GetCountries()
        }
        return () => {
            once.current = false
        }
    }, [])

    useEffect(() => {
        if (country) {

            runInAction(() => {
                StoreCountry.fetching = false
                StoreLoader.SetApiLoaded('country', true)
                StoreCountry.SetCountry(country)
            })
        }
    }, [country])

    const GetCountries = async () => {
        if (StoreCountry.data.length === 0) {
            const url = `${ServerEnv.MemberCalls2(true)}/settings/countryFeelGreatV2/enable?t=${new Date().getTime()}`
            try {
                StoreCountry.fetching = true
                const response = await axios.get(HttpHelper.Proxy({ originalUrl: url }))
                runInAction(() => {
                    StoreCountry.SetCountryList(response.data.data)
                })

                let currentCountry = StoreCountry.CountryList().find(item => checkCountry(item))
                if (currentCountry) {
                    const mm = currentCountry.maintenance_mode
                    if (mm.maintenance_mode) {
                        if (/developing/.test(mm.text.english) || /developing/.test(mm.text.native)) {
                            if (/fg.unicity.com/.test(window.location.hostname)) {
                                window.location.replace(`/uae`)
                            }
                        }
                    }
                    // redirect if have country in path
                    if (currentCountry) {
                        setCountry(currentCountry)
                        return
                    }

                    // redirect default country
                    if (!qs.get('market')) {
                        let savedCountry = localStorage.getItem('currentCountry')
                        let country = savedCountry || 'australia'
                        window.location.replace(`/${country}${window.location.pathname}${window.location.search}`)
                        return
                    }
                } else {
                    if (!BypassRedirectPath.includes(PathHelper.Segment(1))) {
                        redirectCountry()
                    }
                }
            } catch (e) {
                console.error(e)
                StoreCountry.fetching = false
            }
        }
    }

    const redirectCountry = () => {
        const pathnames = window.location.pathname.split('/')
        let newUrl = ''
        const path = window.location.pathname

        if (/^\/feature/.test(window.location.pathname)) {
            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}/uae`
        } else if (/^\/epic/.test(window.location.pathname)) {
            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}/${pathnames[4]}/uae`
        } else if (/\/c\//.test(window.location.pathname)) {
            newUrl = `${window.location.protocol}//${window.location.host}/${path.replace(/\/c\//, 'uae/')}`
        } else {
            newUrl = `${window.location.protocol}//${window.location.host}/uae`
        }
        window.location.replace(newUrl)
    }

    const checkCountry = item => {
        let country = PathHelper.CountryFromPathname()

        if (country.length === 0) {
            window.location.replace(PathHelper.BuildBasename('australia'))
        }

        return [item.country, item.short, item.shorter].some(path => (path || '').replace(/\s/g, '').toLowerCase() === country)
    }

    return country
}
