import { ServerEnv } from 'Configs/ServerEnv'
import httpHelper from 'Services/HttpHelper'

const ValidateTaxInvoice = postData => {
    const url = httpHelper.Proxy({ originalUrl: `${ServerEnv.MemberCalls2()}/hot/formatV2/THA/feelGreatReferral/address?validateTaxInvoice=1` })

    return httpHelper.Post({
        url: url,
        data: {
            ...postData
        }
    })
}

export const TaxInvoiceApis = {
    ValidateTaxInvoice
}
