import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { get, isEmpty } from 'lodash'
import AutoAddress from './AutoAddress/AutoAddress'
import CountryBox from './CountryBox/CountryBox'
import DefaultInput from './DefaultInput'
import ModalSelect from './ModalSelect/ModalSelect'
import ModalSearch from './ModalSearch/ModalSearch'
import ModalExpSelect from './ModalExpSelect/ModalExpSelect'
import ProvinceSelect from './ProvinceSelect/ProvinceSelect'
import storeReferralCheckout from '../../Stores/StoreReferralCheckout'
import { ReactComponent as DropdownIcon } from './Assets/dropdown.svg'
import { ReactComponent as Lock } from './Assets/Union.svg'
import { ReactComponent as Down } from './Assets/Down.svg'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import UAECountry from './Data/UAECountry.json'
import { InputAdornment } from '@mui/material'
import Flag from 'Components/Elements/Flag'
import DefaultTextFields from './DefaultTextFields'
import DefaultFloatError from './DefaultFloatError'
import storeAuth from 'GlobalStores/User/StoreAuth'
import DefaultPhoneNumberCountry from './ModalPhoneNumberCode/DefaultPhoneNumberCountry'
import CitySelect from './CitySelect/CitySelect'

import AllCountries from './Data/AllCountries.json'
import { uaeAreaCountries } from '../../Helpers/UAEHelpers'

export function FormSelector(translate, item, onChange, helpertextcardtype, formattedValue, onKeyUp) {
    switch (item.type) {
        default:
            let defaultType = 'text'
            if (['zip_code', 'zip', 'phone', 'cvv', 'card_number'].includes(item.key)) {
                defaultType = 'tel'
            }
            if (item.key === 'email') {
                defaultType = 'email'
            }
            if (item.key === 'zip_code') {
                let options = []

                return (
                    <ModalSearch
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetShippingForm(), value)
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetCheckoutForms(), value)
                        }}
                        options={options}
                        value={item.value}
                        onChange={e => {
                            let allowNumber = e.target.value.replace(/[^0-9]/g, '')
                            if (onChange) {
                                onChange(item, allowNumber)
                            } else {
                                StoreCheckout.SetCheckoutFormValue(item.key, allowNumber)
                            }
                        }}
                        itemKeys={StoreCheckout.CheckOutInstance().AutoAddressDisplayKeys()}
                    />
                )
            }
            if (item.key === 'province') {
                return (
                    <ProvinceSelect
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }}
                        value={item.value.toUpperCase()}
                        endAdornment={<DropdownIcon />}
                    />
                )
            }
            if (item.key === 'state' || (item.key === 'prefecture' && !storeCountry.Country3() === 'ARE')) {
                const options = []
                StoreCheckout.CheckOutInstance()
                    .Instance()
                    .StatesList()
                    .map(item => {
                        options.push(item.value)
                    })
                if (!item.value) {
                    item.value = ''
                }
                return (
                    <ModalSelect
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }}
                        options={options}
                        value={item.value.toUpperCase()}
                    />
                )
            }

            if (item.key === 'city' && !storeCountry.Country3() === 'ARE') {
                const options = StoreCheckout.CheckOutInstance()
                    .Instance()
                    .getCityOptionList()
                    .map(item => {
                        return item.value
                    })

                return (
                    <ModalSelect
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }}
                        options={options}
                        value={item.value.toUpperCase()}
                    />
                )
            }
            if (item.key === 'email' && !storeAuth.IsAuthorized() && storeCountry.Country3() !== 'ARE') {
                return (
                    <DefaultFloatError
                        label={`${translate(item.key)}`}
                        key={item.key}
                        item={item}
                        value={item.value}
                        type={defaultType}
                        typeKey={item.key}
                        disabled={item.disabled || false}
                        placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                if (e.target.value.length === 0) {
                                    StoreCheckout.SetCheckoutFormValue(item.key, '')
                                } else {
                                    const regx = get(item, 'args.regx', null)
                                    if (regx) {
                                        const regex = new RegExp(regx, 'i')
                                        if (regex.test(e.target.value)) {
                                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                        } else {
                                        }
                                    } else {
                                        StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                    }
                                }
                            }
                        }}
                        {...item.inputProps}
                    />
                )
            }
            if (item.key === 'phone' && storeCountry.Country3() === 'ARE') {
                return <DefaultPhoneNumberCountry item={item} />
            } else {
                let label = item.key

                const form = storeAuth.IsAuthorized() ? StoreCheckout.GetShippingForm() : StoreCheckout.GetCheckoutForms()

                const countryValue = get(
                    (form || []).find(item => item.key === 'country'),
                    'value'
                )

                const uaeCountryObj = uaeAreaCountries(countryValue)

                const countryName = get(uaeCountryObj, 'name', '')

                if (item.key === 'address') {
                    switch (countryName) {
                        case 'United Arab Emirates':
                            label = 'uae_address'
                            break
                        case 'Bahrain':
                            label = 'bhr_address'
                            break
                        case 'Kuwait':
                            label = 'kwt_address'
                            break
                        case 'Qatar':
                            label = 'qar_address'
                            break
                        case 'Saudi Arabia':
                            label = 'sar_address'
                            break
                        default:
                    }
                }

                return (
                    <DefaultInput
                        helpertextcardtype={item.key === 'card_number' && helpertextcardtype}
                        label={`${translate(label)}${item.optional ? ` (${translate('optional')})` : ''}`}
                        type={defaultType}
                        value={item.key === 'card_number' ? formattedValue : item.value}
                        key={item.key}
                        typeKey={item.key}
                        disabled={item.disabled || false}
                        placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                if (e.target.value.length === 0) {
                                    StoreCheckout.SetCheckoutFormValue(item.key, '')
                                } else {
                                    const regx = get(item, 'args.regx', null)
                                    if (regx) {
                                        const regex = new RegExp(regx, 'i')
                                        if (regex.test(e.target.value)) {
                                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                        } else {
                                        }
                                    } else {
                                        StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                    }
                                }
                            }
                        }}
                        {...item.inputProps}
                        style={{ '-webkit-text-security': ['cvv'].includes(item.key) ? 'disc' : 'none' }}
                        item={item}
                        InputProps={
                            item.disabled
                                ? {
                                      autoComplete: 'new-password',
                                      autoCorrect: 'off',
                                      spellCheck: 'false',
                                      readOnly: true,
                                      endAdornment: (
                                          <InputAdornment position="end" style={{ width: '10px', height: '13px' }}>
                                              <Lock />
                                          </InputAdornment>
                                      )
                                  }
                                : null
                        }
                    />
                )
            }
        case 'password':
            return (
                <DefaultInput
                    type="password"
                    label={translate(item.label)}
                    value={item.value}
                    key={item.key}
                    onChange={e => {
                        if (onChange) {
                            onChange(item, e.target.value)
                        } else {
                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                        }
                    }}
                    showAdornmentLabel={item.args?.onEnroll}
                    item={item}
                />
            )
        case 'AutoAddress':
            return (
                <AutoAddress
                    key={item.key}
                    item={item}
                    selectedAddressCallback={value => {
                        if (onChange) {
                            onChange(item, value)
                        } else {
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetShippingForm(), value)
                            StoreCheckout.CheckOutInstance().MapAutoAddressToForm(StoreCheckout.GetCheckoutForms(), value)
                        }
                    }}
                    itemKeys={StoreCheckout.CheckOutInstance().AutoAddressDisplayKeys()}
                    inputCallback={value => {
                        if (onChange) {
                            onChange(item, value)
                        } else {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)
                        }
                    }}
                />
            )

        case 'CountryBox':
            if (['ARE'].includes(storeCountry.Country3()) && !/\/user/.test(window.location.pathname)) {
                // const options = UAECountry.map(item => item.name)
                const value = UAECountry.find(country => country.name === item.value || country.native_name === item.value)
                const options = []
                StoreCheckout.CheckOutInstance()
                    .Instance()
                    .AddressOptionList(item.key, StoreCheckout)
                    .map(item => {
                        options.push(item.value)
                    })
                if (!item.value) {
                    item.value = ''
                }

                const allCountriesData = AllCountries.map(item => {
                    return {
                        flag: item.code,
                        countryName: item.name,
                        options: `${item.dial_code} ${item.name}`,
                        dial_code: item.dial_code
                    }
                })

                return (
                    <ModalSelect
                        label={translate(item.key)}
                        onSet={value => {
                            StoreCheckout.SetCheckoutFormValue(item.key, value)

                            const uaeCountryObj = uaeAreaCountries(value)

                            const countryName = get(uaeCountryObj, 'name', '')

                            // const uaeAreaCountry = UAECountry.find(country => country.name === value || country.native_name === value)

                            if (countryName !== 'United Arab Emirates') {
                                const phoneNumberCode = allCountriesData.find(item => item.countryName === countryName || item.countryName === value)

                                StoreCheckout.SetPhoneNumberCode(phoneNumberCode.dial_code)
                            }

                            const isChainAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.isChainAddress')
                            const groupAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.groupAddress', []) || []

                            const autoPopup = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.autoPopup')
                            const autoPopupKeys = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.autoPopupKeys', []) || []

                            if (isChainAddress && groupAddress.includes(item.key)) {
                                const keyToSetInit = groupAddress.slice(groupAddress.indexOf(item.key) + 1) || []
                                keyToSetInit.forEach(key => StoreCheckout.SetCheckoutFormValue(key, ''))
                                const element = document.getElementById(`address-${keyToSetInit[0]}`)
                                if (element && autoPopup && autoPopupKeys.includes(item.key)) {
                                    setTimeout(() => {
                                        element.click()
                                    }, 800)
                                }
                            }
                        }}
                        options={options}
                        value={item.value}
                        keys={item.key}
                        placeholder={translate(get(item, 'placeholder', ''))}
                        InputProps={{
                            autoComplete: 'new-password',
                            autoCorrect: 'off',
                            spellCheck: 'false',
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Flag flag={value ? value.flag : ''} type />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" style={{ width: '10px', height: '13px' }}>
                                    <Down />
                                </InputAdornment>
                            )
                        }}
                    />
                )
            }
            return <CountryBox label={'country'} value={StoreCountry.CountryCapitalize()} />
        case 'ExpDate':
            const monthOptionsFactory = () => {
                const lm = []
                for (let i = 1; i <= 12; i++) {
                    const m = i < 10 ? '0' + i : i
                    lm.push(m.toString())
                }
                return lm
            }

            const yearOptionsFactory = () => {
                const min = new Date().getFullYear()
                const max = 10
                const ly = []
                for (let i = 0; i <= max; i++) {
                    const year = `${min + i}`
                    ly.push(year)
                }
                return ly
            }
            const [month, year] = (item.value || '').split('/')
            return (
                <ModalExpSelect
                    label={translate(item.key)}
                    onSet={(month, year) => {
                        storeReferralCheckout.SetCreditCard({ key: item.key }, { month, year })
                    }}
                    options={{ month: monthOptionsFactory(), year: yearOptionsFactory() }}
                    value={{ month: month || '', year: year ? '20' + year : '' }}
                />
            )
        case 'select':
            const options = []
            StoreCheckout.CheckOutInstance()
                .Instance()
                .AddressOptionList(item.key, StoreCheckout)
                ?.forEach(item => {
                    options.push(item.value)
                })
            if (!item.value) {
                item.value = ''
            }

            let label = item.key

            const form = storeAuth.IsAuthorized() ? StoreCheckout.GetShippingForm() : StoreCheckout.GetCheckoutForms()

            const countryValue = get(
                (form || []).find(item => item.key === 'country'),
                'value'
            )

            const uaeCountryObj = uaeAreaCountries(countryValue)

            const countryName = get(uaeCountryObj, 'name', '')

            if (item.key === 'city_selection') {
                switch (countryName) {
                    case 'Bahrain':
                        label = 'bhr_city'
                        break
                    case 'Kuwait':
                        label = 'kwt_city'
                        break
                    case 'Qatar':
                        label = 'qar_city'
                        break
                    case 'Saudi Arabia':
                        label = 'sar_city'
                        break
                    default:
                }
            }

            if (
                (item.key === 'city_selection' && ['Kuwait', 'Bahrain', 'Qatar'].includes(countryName)) ||
                (item.key === 'province_selection' && ['Saudi Arabia'].includes(countryName)) ||
                item.key === 'area_selection'
            ) {
                // const cityOptions = options.map(item => {
                //     return {
                //         city_native: item,
                //         city_roman: item,
                //         priority: null
                //     }
                // })
                return <CitySelect label={translate(label)} value={item.value.toUpperCase()} item={item} />
            }

            if (item.key === 'city_selection' && ['Saudi Arabia'].includes(countryName)) {
                return (
                    <DefaultInput
                        helpertextcardtype={item.key === 'card_number' && helpertextcardtype}
                        label={`${translate(label)}${item.optional ? ` (${translate('optional')})` : ''}`}
                        type="text"
                        value={item.key === 'card_number' ? formattedValue : item.value}
                        key={item.key}
                        typeKey={item.key}
                        disabled={item.disabled || false}
                        // placeholder={item.placeholder || ''}
                        onKeyUp={e => {
                            if (onKeyUp) {
                                onKeyUp(item, e)
                            }
                        }}
                        onChange={e => {
                            if (onChange) {
                                onChange(item, e)
                            } else {
                                if (e.target.value.length === 0) {
                                    StoreCheckout.SetCheckoutFormValue(item.key, '')
                                } else {
                                    const regx = get(item, 'args.regx', null)
                                    if (regx) {
                                        const regex = new RegExp(regx, 'i')
                                        if (regex.test(e.target.value)) {
                                            StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                        } else {
                                        }
                                    } else {
                                        StoreCheckout.SetCheckoutFormValue(item.key, e.target.value)
                                    }
                                }
                            }
                        }}
                        {...item.inputProps}
                        style={{ '-webkit-text-security': ['cvv'].includes(item.key) ? 'disc' : 'none' }}
                    />
                )
            }

            return (
                <ModalSelect
                    label={translate(label)}
                    onSet={value => {
                        StoreCheckout.SetCheckoutFormValue(item.key, value)

                        const isChainAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.isChainAddress')
                        const groupAddress = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.groupAddress', []) || []

                        const autoPopup = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.autoPopup')
                        const autoPopupKeys = get(StoreCheckout.CheckOutInstance().Instance(), 'chainAddress.autoPopupKeys', []) || []

                        if (isChainAddress && groupAddress.includes(item.key)) {
                            const keyToSetInit = groupAddress.slice(groupAddress.indexOf(item.key) + 1) || []
                            keyToSetInit.forEach(key => StoreCheckout.SetCheckoutFormValue(key, ''))
                            const element = document.getElementById(`address-${keyToSetInit[0]}`)
                            if (element && autoPopup && autoPopupKeys.includes(item.key)) {
                                setTimeout(() => {
                                    element.click()
                                }, 800)
                            }
                        }
                    }}
                    disabled={isEmpty(options)}
                    options={options}
                    value={item.value}
                    keys={item.key}
                    // placeholder={translate(get(item, 'placeholder', ''))}
                    InputProps={{
                        autoComplete: 'new-password',
                        autoCorrect: 'off',
                        spellCheck: 'false',
                        readOnly: true,
                        endAdornment: isEmpty(options) ? (
                            <InputAdornment position="end" style={{ width: '10px', height: '13px' }}>
                                <Lock />
                            </InputAdornment>
                        ) : (
                            <InputAdornment position="end" style={{ width: '10px', height: '13px' }}>
                                <Down />
                            </InputAdornment>
                        )
                    }}
                />
            )
    }
}
