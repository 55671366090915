import { observer } from 'mobx-react-lite'
import { Stack, Typography, createTheme, ThemeProvider, Box, styled } from '@mui/material'
import ResponseCard from '../Elements/ResetPassword/ResponseCard'
import { ReactComponent as SuccessIcon } from '../Elements/ResetPassword/Assets/success.svg'
import { ReactComponent as WrongIcon } from '../Elements/ResetPassword/Assets/wrong.svg'
import { useNavigate } from 'react-router-dom'
import { DefaultButton } from 'Components/Elements/Forms'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'

const ResetPasswordSuccess = observer(({ type }) => {
    const navigate = useNavigate()
    const translate = useTranslate()

    const requestReceivedDetail = (
        <>
            <Typography
                variant="h5"
                sx={{
                    fontSize: {
                        xs: '12px',
                        sm: '14px'
                    },
                    fontWeight: 400,
                    textAlign: {
                        xs: 'start',
                        sm: 'center'
                    },
                    color: '#153862',
                    marginBottom: {
                        xs: '16px',
                        sm: '28px'
                    },
                    width: '100%'
                }}>
                {translate('request_received_detail_success_desc_1')}
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    fontSize: {
                        xs: '12px',
                        sm: '14px'
                    },
                    fontWeight: 400,
                    textAlign: {
                        xs: 'start',
                        sm: 'center'
                    },
                    color: '#153862',
                    width: '100%'
                }}>
                {translate('request_received_detail_success_desc_2')}
            </Typography>
        </>
    )

    const errorDetail = (
        <Typography
            variant="h5"
            sx={{
                fontSize: {
                    xs: '12px',
                    sm: '14px'
                },
                fontWeight: 400,
                textAlign: {
                    xs: 'start',
                    sm: 'center'
                },
                color: '#153862',
                width: '100%'
            }}>
            {translate('request_received_detail_error_desc_1')}
        </Typography>
    )

    const errorButton = (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                width: {
                    xs: '100%',
                    sm: '279px'
                }
            }}
            mt="50px"
            onClick={() => navigate(`/reset-password`)}>
            <DefaultButton fullWidth={true} titleText={translate('reset_password')} backgroundcolor={'#153862'} />
        </Box>
    )

    const loginButton = (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                width: '100%',
                maxWidth: '275px'
            }}
            mt="40px">
            <DefaultButton fullWidth={true} titleText={translate('login')} backgroundcolor={'#5A8FC3'} onClick={() => navigate(`/login`)} />
        </Box>
    )

    const successDetail = (
        <Typography
            variant="h5"
            sx={{
                fontSize: {
                    xs: '12px',
                    sm: '14px'
                },
                fontWeight: 400,
                textAlign: {
                    xs: 'start',
                    sm: 'center'
                },
                color: '#153862',
                width: '100%'
            }}>
            {/* {translate('request_received_detail_error_desc_1')} */}
            <T>reset_pwd_success_desc</T>
        </Typography>
    )

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Wrapper>
                {type === 'success' ? (
                    <ResponseCard title={`${translate('success')}!`} icon={<SuccessIcon />} detail={successDetail} button={loginButton} />
                ) : (
                    <ResponseCard title={translate('something_went_wrong')} icon={<WrongIcon />} detail={errorDetail} button={errorButton} />
                )}
            </Wrapper>
        </ThemeProvider>
    )
})

export default ResetPasswordSuccess

const Wrapper = styled(Stack)`
    * {
        box-sizing: border-box;
    }
    background-color: #d1d9e2;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: calc(100vh - (64px + 60px)); // header + footer
    align-items: center;

    & > div.MuiPaper-root {
        padding: 40px 30px;
        border-radius: 12px;
        width: 100%;
        max-width: 650px;
        box-shadow: none;
        margin: 0 auto;
    }

    & .MuiTypography-root {
        font-family: Poppins !important;
    }

    & .icon__stack {
        align-items: center;
    }
    & .MuiTypography-root {
        text-align: center;
    }

    & .title__typography {
        font-size: 32px;
        font-weight: 700;
    }

    & .detail__stack .MuiTypography-root {
        font-size: 14px;
        font-weight: 400;
    }
    @media screen and (min-width: 768px) {
        padding-top: 40px;
    }
`
