import loadable from '@loadable/component'
import { Stack, ThemeProvider } from '@mui/material'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import ReferralBar from './Uae/Components/ReferralBar/ReferralBar'
import { DefaultReferralTheme } from './Theme/DefaultReferralTheme'
import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeReferral from './Uae/Stores/StoreReferral'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import LoginWrapper from './Uae/LoginWrapper'

const ReferralMainPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    useEffect(() => {
        if (!params.customerId && !storeAuth.IsAuthorized() && !storeReferral.HasReferral() && !storeReferral.GetCheckedReferralParam()) {
            if (qs.get('logout')) {
                sessionStorage.setItem('from-logout', '1')
            }
            navigate('/login', { replace: true })
        }
    }, [params.customerId, navigate, qs])

    const conditionToRender = () => {
        return params.customerId || storeAuth.IsAuthorized() || storeReferral.HasReferral() || storeReferral.GetCheckedReferralParam()
    }

    return (
        <ThemeProvider theme={DefaultReferralTheme}>
            <LoginWrapper>
                <Stack minHeight={'calc(100vh - 137px)'} bgcolor={'#d2d9e1'}>
                    {conditionToRender() ? (
                        <>
                            <ReferralBar />
                            <DynamicCountry />
                        </>
                    ) : (
                        <Stack justifyContent={'center'} alignItems={'center'} minHeight={'calc(100vh - 137px)'}>
                            <IconSpinner />
                        </Stack>
                    )}
                </Stack>
            </LoginWrapper>
        </ThemeProvider>
    )
}

const DynamicCountry = loadable(() => import(`./${storeCountry.CountryCapitalize()}/MainPage`))

export default ReferralMainPage
