import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import styled from '@emotion/styled'

const Flag = observer(props => {
    const [flag] = useState(StoreCountry.Country2())

    return (
        <Wrapper>
            {flag && (
                <FlagImage
                    className={props.type ? 'border-header' : ''}
                    src={`https://ushop-media.unicity.com/images/flags/${props.flag ? props.flag : flag}.svg`}
                />
            )}
        </Wrapper>
    )
})

const Wrapper = styled.div`
    width: 30px;
    display: flex;
`

const FlagImage = styled.img`
    // width: 30px;
    height: 19px;

    &.border-header {
        border-radius: 3px;
    }
`

export default Flag
