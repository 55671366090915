import { Stack, Typography, styled } from '@mui/material'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'
import StoreUser from 'GlobalStores/User/StoreUser';
import { isEmpty, isNullOrUndefined } from 'Utils/Utils';
import { DefaultButton, DefaultInput } from '../Forms';
import StoreAuth from 'GlobalStores/User/StoreAuth';
import { reaction } from 'mobx';
import { storeElement } from 'GlobalStores/StoreElement'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate';
import userAPI from 'GlobalStores/User/APIs';
import BadWordsFilter from 'bad-words';


const DisplayNameSetting = observer((props) => {

    const translate = useTranslate()
    const badWordFilter = new BadWordsFilter()

    const localObserver = useLocalObservable(() => ({
        displayName: "",
        displayNameInput: {
            firstName: "",
            lastName: ""
        },
        showInput: false,
        saveLoading: false,
        isShowError: false,
        errorMessages: []
    }))

    const handleInitialDisplayName = () => {
        const preferredName = StoreUser.CustomerData().PreferredName()
        const humanName = StoreUser.CustomerData().HumanName()

        if ((isEmpty(preferredName?.firstName) && isEmpty(preferredName?.lastName))) {
            const firstName = humanName?.firstName || ""
            const lastName = humanName?.lastName || ""

            localObserver.displayNameInput = {
                firstName,
                lastName
            }
            return

        } else if (isNullOrUndefined(preferredName?.firstName) || isNullOrUndefined(preferredName?.lastName)) {
            const firstName = humanName?.firstName || ""
            const lastName = humanName?.lastName || ""

            localObserver.displayNameInput = {
                firstName,
                lastName
            }
            return
        } else {
            const firstName = preferredName?.firstName || ""
            const lastName = preferredName?.lastName || ""

            localObserver.displayNameInput = {
                firstName,
                lastName
            }
            return
        }
    }

    const handleShowInput = (isShow) => {

        localObserver.showInput = isShow

        if (isShow === false) {
            handleInitialDisplayName()
            resetErrorMessages()
        }


    }

    const handleChangeDisplayName = (e) => {

        const { name, value } = e.target

        localObserver.displayNameInput = {
            ...localObserver.displayNameInput,
            [name]: value
        };

        resetErrorMessages()
    }

    const handleUpdateDisplayName = async () => {
        localObserver.saveLoading = true

        const firstName = localObserver.displayNameInput.firstName
        const lastName = localObserver.displayNameInput.lastName

        try {

            //Check Bad word
            const isBadWord = handleFilterBadWord({ firstName, lastName })
            if (isBadWord) {
                throw new Error("not_allow_bad_word")
            }

            // Check customer site is exist
            const existingCustomerSite = await StoreAuth.getCustomerSite()

            if (existingCustomerSite.isSuccess === false) {
                throw new Error('Update display name is failed')
            }

            const existingAliasName = existingCustomerSite?.data?.alias || null

            const aliasName = handleNewAliasName({ firstName, lastName, existingAliasName })

            const updateDisplayNameResult = await StoreAuth.updateDisplayName({
                alias: aliasName,
                firstName: firstName,
                lastName: lastName
            })

            if (updateDisplayNameResult.isSuccess === false) {
                throw new Error("Update display name is failed")
            }

            //Success get new official data from api menu
            const userResponse = await StoreAuth.getCustomerMenu()

            const ushop = userResponse.onself.ushop

            ushop.profile.preferredName = {
                firstName: firstName,
                lastName: lastName
            }

            StoreUser.setUserData(ushop)

            handleInitialDisplayName()

            await userAPI.clearEtlV2Cache(StoreAuth.GetId())
            localObserver.showInput = false
            localObserver.saveLoading = false
        } catch (error) {
            console.log("Error handleUpdatePreferredName : ", error)

            localObserver.errorMessages[0] = (translate(`${error.message === "not_allow_bad_word" ? "not_allow_bad_word" : "update_display_name_failed"}`))

            localObserver.saveLoading = false
        }


    }

    const handleNewAliasName = ({ existingAliasName }) => {

        if (existingAliasName === null) {
            return `${StoreAuth.GetId()}-${StoreAuth.GetUserCountry()}`
        } else {
            return existingAliasName
        }
    }

    const resetErrorMessages = () => {
        localObserver.errorMessages = []
    }

    const handleFilterBadWord = ({ firstName, lastName }) => {

        const firstNameNoSpace = firstName.replace(/\s/g, '').toLowerCase()
        const lastNameNoSpace = lastName.replace(/\s/g, '').toLowerCase()
        const combinedName = `${firstNameNoSpace}${lastNameNoSpace}`

        const badWordList = badWordFilter.list
        const firstNameBadWord = badWordList.some((word) => firstNameNoSpace.includes(word))
        const lastNameBadWord = badWordList.some((word) => lastNameNoSpace.includes(word))
        const combinedNameBadWord = badWordList.some((word) => combinedName.includes(word))

        return firstNameBadWord || lastNameBadWord || combinedNameBadWord
    }

    useEffect(() => {
        handleInitialDisplayName()


        reaction(() => localObserver.saveLoading, (saveLoading) => {

            if (saveLoading === true) {
                storeElement.showCardFrontDrop = true
            } else {
                storeElement.showCardFrontDrop = false
            }
        })

    }, [])

    return (
        <Section showdivider={"true"}>
            <Stack sx={{ mb: '16px', justifyContent: "space-between", flexDirection: "row", width: "100%", overflowX: "hidden" }}>
                <Typography variant="h3" component="h3" sx={{ fontWeight: 500 }}>
                    <T>display_name</T>
                </Typography>

                <Typography variant='h3' component="a" sx={{ color: `${localObserver.showInput ? "#CCDEEF" : "#5A8FC3"}`, textDecoration: "underline", cursor: "pointer", fontWeight: 500 }} onClick={() => handleShowInput(true)}>
                    <T>change</T>
                </Typography>
            </Stack>
            <Stack sx={{ width: "100%", overflowX: "hidden", mb: "16px" }}>
                <Typography variant="subtitle1" component="p" color={'#8699AF'} fontSize={'14px'}>
                    <T>display_name_caption</T>
                </Typography>
            </Stack>
            <Stack sx={{ width: "100%", overflowX: "hidden", mb: localObserver.showInput ? "24px" : 0 }}>
                <Typography variant="h2" component="p" sx={{ color: '#003B6F', fontSize: { xs: "24px", sm: "20px" }, lineHeight: 1.5, overflow: 'hidden', textOverflow: "ellipsis" }}>
                    {StoreUser.CustomerData().DisplayName()}
                </Typography>
            </Stack>

            {localObserver.showInput ?
                <Stack sx={{ flexDirection: "column", width: "100%", overflowX: "hidden" }}>
                    <Stack sx={{
                        width: "100%",
                        flexDirection: "row",
                        gap: "24px",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        mb: localObserver.isShowError ? "12px" : "24px",
                        '& .MuiBox-root': { width: { xs: "100%", sm: "calc(50% - 12px)" } },
                        '& .MuiInputBase-input': { borderColor: "#CCDEEF", color: "#003B6F" }
                    }}
                    >
                        <DefaultInput
                            name="firstName"
                            label={null}
                            value={`${localObserver?.displayNameInput?.firstName}`}
                            onChange={handleChangeDisplayName}
                        />
                        <DefaultInput
                            name="lastName"
                            label={null}
                            value={localObserver?.displayNameInput?.lastName}
                            onChange={handleChangeDisplayName}
                        />
                    </Stack>

                    {localObserver.errorMessages?.length > 0 ?
                        <Stack mb="24px">
                            {localObserver.errorMessages.map((message, index) =>
                                <Typography color="#D03C3C" fontSize="14px" fontWeight={400} lineHeight="24px" component='p'>
                                    {message}
                                </Typography>
                            )}
                        </Stack>
                        : null
                    }


                    <Stack sx={{ justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>

                        <Typography variant='h3' component="a" sx={{ color: "#003B6F", textDecoration: "underline", cursor: "pointer", fontWeight: 400 }} onClick={() => handleShowInput(false)}>
                            <T>cancel_fg</T>
                        </Typography>

                        <DefaultButton
                            sx={{
                                width: { xs: "180px", sm: "200px" },
                                backgroundColor: "#5A8FC3",
                                ":hover": { backgroundColor: "#5A8FC3" }
                            }}
                            titleText={translate("save")}
                            isloading={localObserver.saveLoading.toString()}
                            onClick={handleUpdateDisplayName}
                        />
                    </Stack>
                </Stack> : null}
        </Section>
    )
}
)
export default DisplayNameSetting

const Section = styled(Stack)`
    @media screen and (max-width: 900px) {
        position: relative;
    }

    border-bottom: 1px solid #CCDEEF;
    padding-bottom: 24px;
    margin-bottom: 24px;
    width: 100%;
    overflow-x: hidden;
   
}
  
`