import { Box, Modal, Fade } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ModalLogin from './ModalLogin'
import { reaction } from 'mobx'
import StoreReferral from '../Stores/StoreReferral'
import { storeElement } from 'GlobalStores/StoreElement'

const Login = observer(props => {
    const [openLoginModal, setOpenLoginModal] = useState(false)

    useEffect(() => {
        const referralCodeComposer = reaction(
            () => StoreReferral.HasReferral(),
            _ => {
                if (Boolean(_) === true) {
                    setOpenLoginModal(false)
                }
            }
        )

        const referralCheckErrorComposer = reaction(
            () => StoreReferral.GetReferralCheckError(),
            _ => {
                if (Boolean(_) === true) {
                    setOpenLoginModal(false)
                }
            }
        )

        const disposerShowLoginModal = reaction(
            () => storeElement.showLoginModal,
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    setOpenLoginModal(newValue)
                }
            }
        )

        return () => {
            referralCodeComposer()
            referralCheckErrorComposer()
            disposerShowLoginModal()
        }
    }, [])

    const onClose = () => {
        storeElement.showLoginModal = !storeElement.showLoginModal
        setOpenLoginModal(false)
    }

    return (
        <Modal
            open={openLoginModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => StoreReferral.HasReferral() !== false && onClose()}>
            <Fade in={openLoginModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '0px',
                        outline: 'none'
                    }}>
                    <ModalLogin setOpenLoginModal={setOpenLoginModal} />
                </Box>
            </Fade>
        </Modal>
    )
})

export default Login
