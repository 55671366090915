import { MainTemplate } from 'Components/Template/MainTemplate'
import { Container, Stack, ThemeProvider, Dialog, DialogContent } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { lazy, useEffect, useMemo, useState } from 'react'
import pMinDelay from 'p-min-delay'
import { useNavigate, useParams } from 'react-router-dom'
import { reaction, runInAction } from 'mobx'
import StoreFormat from 'GlobalStores/Format/StoreFormat'
import { CreditCardHelper } from 'Helpers/CreditCardHelper'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { get } from 'lodash'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import DefaultCheckoutTheme from '../DefaultCheckoutTheme'
import storeReferralCheckout from './Stores/StoreReferralCheckout'
import DuplicatedOrder from 'Components/Elements/DuplicatedOrder/DuplicatedOrder'
import { ReferralUaeAPIs } from './Services/ReferralUaeAPIs'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import CheckoutLoaderStore from 'GlobalStores/Checkout/CheckoutLoader'
import PaymentProcessBar from 'Components/Elements/LoadingBar/PaymentProcessBar'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import StoreReferralCart from './Stores/StoreReferralCart'
import StoreOrderCalc from 'GlobalStores/Cores/OrderCalc/StoreOrderCalc'
import StoreReferralProducts from './Stores/StoreReferralProducts'
import StoreReferralFormat from './Stores/StoreReferralFormat'
import trackingEvents from 'Services/Tracker/TrackingEvents'
import storeReferral from './Stores/StoreReferral'

// const ShippingAddressBoxLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/ShippingAddress'), 0))
const PaymentBoxLazyLoad = lazy(() => pMinDelay(import('./Components/OrderSummary/ReferralPayment'), 0))
const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('../Uae/Components/OrderSummary/V2/OrderSummaryItemCard'), 0))
// const PaymentReviewLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/PaymentReview'), 0))
// const CreateAccountWithShippingLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/CreateAccountWithShipping'), 0))

const CheckoutPage = observer(() => {
    const params = useParams()
    const navigate = useNavigate()
    const translate = useTranslate()
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [openDuplicatedModal, setOpenDuplicatedModal] = useState(false)
    const [duplicatedData, setDuplicatedData] = useState({
        paymentId: '',
        dateAndTime: '',
        totalPrice: '',
        currency: '',
        is_duplicate: false
    })

    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    const [sectionReady, setSectionReady] = useState(false)

    const closeDuplicateModal = () => {
        setOpenDuplicatedModal(false)
        runInAction(() => {
            storeElement.showCardFrontDrop = false
        })
    }

    const checkDuplicateOrder = async () => {
        try {
            console.log('checkDuplicateOrder')

            let items = []

            const purchaseOption = StoreReferralFormat.paymentOptionSelected
            let itemsDetailPage = JSON.parse(StoreFormat.GetDetailPage().items)

            itemsDetailPage.map(item => {
                const product = StoreReferralProducts.GetProducts().find(product => product.options[purchaseOption].item_code === item.item_code)

                if (product) {
                    items.push({
                        item_id: product.id,
                        item_code: product.options[purchaseOption].item_code,
                        item_name: product.name.english,
                        price: StoreReferralCart.hasDiscount() ? product.options[purchaseOption].price.member : product.options[purchaseOption].price.retail,
                        pv: product.options[purchaseOption].pv,
                        qty: item.qty,
                        type: product.type,
                        purchaseOption: purchaseOption,
                    })
                }
            })
            const totalPrice = items.map(item => item.price * item.qty).reduce((a, b) => a + b, 0)

            trackingEvents.BeginCheckout(storeCountry.CountryLowerCase(), trackingEvents.EVENT_TYPE.BEGIN_CHECKOUT.toLowerCase(), items, totalPrice, storeReferral.GetReferral(), '')

            if (/subscribe/.test(JSON.stringify(StoreReferralCart.getCartItems()))) {
                runInAction(() => {
                    CheckoutLoaderStore.orderApiProgress.createAutoship = 0
                })
            } else {
                runInAction(() => {
                    CheckoutLoaderStore.orderApiProgress.createAutoship = -1
                })
            }
            runInAction(() => {
                storeReferralCheckout.paymentErrors = []
                storeElement.showCardFrontDrop = true
                storeReferralCheckout.paymentLoading = true
            })
            const checkDuplicateOrder = await ReferralUaeAPIs.checkDuplicateOrder({
                countryCode3Alpha: storeCountry.Country3(),
                uuid: storeReferralCheckout.GetUuid() || qs.get('uuid')
            })

            if (checkDuplicateOrder.data.is_duplicate === true) {
                setDuplicatedData({
                    paymentId: checkDuplicateOrder.data.payments_id,
                    dateAndTime: checkDuplicateOrder.data.date_time,
                    totalPrice: checkDuplicateOrder.data.total_price,
                    is_duplicate: true,
                    currency: checkDuplicateOrder.data.currency
                })
                setOpenDuplicatedModal(true)
            } else {
                await onPlaceOrder()
            }
        } catch (e) {
            console.log('error', e)
            runInAction(() => {
                storeReferralCheckout.paymentLoading = false
            })
            closeDuplicateModal()
        }
    }

    const onPlaceOrder = async () => {
        let items = []

        const purchaseOption = StoreReferralFormat.paymentOptionSelected
        let itemsDetailPage = JSON.parse(StoreFormat.GetDetailPage().items)

        itemsDetailPage.map(item => {
            const product = StoreReferralProducts.GetProducts().find(product => product.options[purchaseOption].item_code === item.item_code)
            if (product) {
                items.push({
                    item_id: product.id,
                    item_code: product.options[purchaseOption].item_code,
                    item_name: product.name.english,
                    price: StoreReferralCart.hasDiscount() ? product.options[purchaseOption].price.member : product.options[purchaseOption].price.retail,
                    pv: product.options[purchaseOption].pv,
                    qty: item.qty,
                    type: product.type,
                    purchaseOption: purchaseOption,
                })
            }
        })

        try {
            runInAction(() => {
                storeReferralCheckout.paymentErrors = []
                storeElement.showCardFrontDrop = true
                storeReferralCheckout.paymentLoading = true
            })

            // * Check Out of Stock
            const isOutOfStock = storeReferralCheckout.CheckOutofStockCartItems()
            if (isOutOfStock?.length > 0) {
                const formatErrMessage = isOutOfStock.map(item => translate('error_ufg_validation_oos_product').replace('{{productName}}', item))
                console.log('formatErrMessage', formatErrMessage)
                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeReferralCheckout.paymentErrors = [...formatErrMessage]
                    storeReferralCheckout.paymentLoading = false
                })

                return
            }

            // Creditcard section
            const creditCard = {
                countryCode: 'AE',
                creditCard: {
                    creditCardNumber: GetCardValue('card_number'),
                    payer: GetCardValue('name_on_card'),
                    creditCardExpires: GetCardValue('exp_date'),
                    creditCardSecurityCode: GetCardValue('cvv')
                }
            }

            const cardValidate = CreditCardHelper.cardValidate(creditCard)

            if (cardValidate.success) {
                if (get(StoreFormat.GetDetailPage(), 'type') !== 'enroll' && !StoreAuth.GetToken()) {
                    await StoreAuth.Login({ unicityID: StoreUser.baId, password: StoreUser.password })
                }
            } else {
                const query = new URLSearchParams(document.location.search)
                let cardErrors = []
                cardValidate.error_messages.map(item => {
                    // cardErrors.push({
                    //     english: item,
                    //     native: item
                    // })
                    cardErrors.push(item)
                    return item
                })
                cardErrors.push(`UUID : ${query.get('uuid')}`)
                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    storeReferralCheckout.paymentErrors = cardErrors
                    storeReferralCheckout.paymentLoading = false
                })
                return
            }

            const creditCardData = storeReferralCheckout.CreditCardEncrypted(creditCard.creditCard, StoreFormat.GetDetailPage().email)

            if (StoreAuth.GetToken() && !storeReferralCheckout.paymentFromEnroll) {
                await storeReferralCheckout.UpdateItemdata()
            } else {
                await storeReferralCheckout.UpdateItemdataCreateAccount()
            }

            const response = await storeReferralCheckout.SubmitPayment({
                uuid: storeReferralCheckout.GetUuid(),
                detailPage: StoreFormat.GetDetailPage(),
                CreditCardEncrypted: creditCardData,
                products: items
            })

            if (response.success) {
                runInAction(() => {
                    runInAction(() => (storeElement.showCardFrontDrop = true))
                    // storeReferralCheckout.paymentLoading = false
                })
                // StoreCheckout.RestoreCart()

                setTimeout(() => {
                    runInAction(() => {
                        StoreReferralCart.cartBottleQty = 0
                        StoreReferralCart.cartBottle.qty = 0
                        StoreReferralCart.cartBottle.option = 'onetime'
                        StoreOrderCalc.resetOrderCalc()
                        StoreReferralCart.ResetAllItemQtytoOne()
                        StoreReferralCart.showBottle = false
                        StoreReferralCart.removeItem('32015')
                    })
                    if (get(response, 'gateway_ref_id')) {
                        navigate(`/payment-response/success?gateway_ref_id=${response.gateway_ref_id}`)
                    } else {
                        navigate(`/payment-response/success?payment_id=${response.payment_id}`)
                    }
                }, 1000)
            } else {
                runInAction(() => {
                    storeElement.showCardFrontDrop = false
                    // storeReferralCheckout.paymentLoading = false
                })
            }
        } catch (e) {
            console.log('error', e)
            runInAction(() => {
                storeElement.showCardFrontDrop = false
                storeReferralCheckout.paymentLoading = false
            })
        }
    }

    const GetCardValue = key => {
        const card = storeReferralCheckout.creditCard.find(item => item.label === key)
        if (card) {
            return card.value
        }
        return ''
    }

    useEffect(() => {
        if (qs.get('test-duplicated')) {
            setDuplicatedData({
                paymentId: '123123215415',
                dateAndTime: '2024-03-04 23:55:43',
                totalPrice: '580',
                is_duplicate: true,
                currency: 'AED'
            })
            setTimeout(() => {
                setOpenDuplicatedModal(true)
            }, 1000)
        }

        if (qs.get('uuid')) {
            storeReferralCheckout.SetUuid(qs.get('uuid'))
        }
    }, [qs])

    useEffect(() => {
        storeReferralCheckout.paymentErrors = []
        runInAction(() => {
            storeElement.showCardFrontDrop = false
            storeReferralCheckout.paymentErrors = []
            storeReferralCheckout.paymentLoading = false
            StoreReferralCart.cartBottle.qty = 0
            StoreReferralCart.cartBottle.option = 'onetime'
            StoreOrderCalc.resetOrderCalc()
            // StoreReferralCart.ResetAllItemQtytoOne() 
            StoreReferralCart.showBottle = false
            StoreReferralCart.removeItem('32015')
        })

        reaction(
            () => StorePaymentStep.PaymentDone,
            (n, p, d) => {
                if (n) {
                    storeReferralCheckout.paymentLoading = false
                    d.dispose()
                }
            }
        )
    }, [])

    const Sections = step => {
        return <PaymentBoxLazyLoad onPlaceOrder={checkDuplicateOrder} sectionReady={setSectionReady} />
    }

    const PreSummarySection = () => {
        return <PreSummaryBoxLazyLoad onSubmit={checkDuplicateOrder} />
    }

    const CheckoutThemesSelector = () => {
        return (
            <StackStyled stepPaymentOrReview={params.step === 'review'}>
                <DefaultCheckoutTheme
                    onPlaceOrder={checkDuplicateOrder}
                    step={params.step}
                    Sections={Sections}
                    setOpenLoginModal={setOpenLoginModal}
                    openLoginModal={openLoginModal}
                    PreSummarySection={PreSummarySection}
                />
            </StackStyled>
        )
    }

    const confirmPlaceOrder = async () => {
        console.log('confirm place order')
        setOpenDuplicatedModal(false)
        runInAction(() => {
            storeReferralCheckout.paymentErrors = []
            storeElement.showCardFrontDrop = true
            storeReferralCheckout.paymentLoading = true
        })
        await onPlaceOrder()
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack backgroundColor={MainTemplate.palette.background.default} minHeight="100vh">
                <ContainerStyled maxWidth="lg">
                    <Stack>{CheckoutThemesSelector()}</Stack>
                    <DialogDuplicatedStyled open={openDuplicatedModal}>
                        <DialogContent
                            sx={{
                                padding: 0
                            }}>
                            <DuplicatedOrder confirmPlaceOrder={confirmPlaceOrder} setOpenModal={closeDuplicateModal} {...duplicatedData} />
                        </DialogContent>
                    </DialogDuplicatedStyled>
                    <PaymentProcessBar
                        isModalOpen={storeReferralCheckout.paymentLoading}
                        onClose={() => {
                            storeReferralCheckout.paymentLoading = false
                            CheckoutLoaderStore.orderApiReady = false

                            CheckoutLoaderStore.orderApiProgress = {
                                hydraCreateAccount: 0,
                                login: 0,
                                createOrder: 0,
                                createAutoship: 0
                            }
                        }}
                    />
                </ContainerStyled>
            </Stack>
        </ThemeProvider>
    )
})
const ContainerStyled = styled(Container)`
    @media screen and (min-width: 730px) and (max-width: 1200px) {
        padding: 0 70px;
    }
`

const StackStyled = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 50vh;
    display: flex;
    flex-direction: ${props => (props.stepPaymentOrReview ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};
    gap: 20px;

    @media screen and (min-width: 399px) {
        padding-top: 40px;
    }

    @media screen and (min-width: 600px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 380px;
        gap: 22px;
        padding-top: 40px;
    }
`

const DialogDuplicatedStyled = styled(Dialog)`
    .MuiDialog-paper {
        border-radius: 12px;
        box-shadow: none;
        @media screen and (min-width: 768px) {
            width: 100%;
            max-width: 650px;
        }
    }
`

export default CheckoutPage
