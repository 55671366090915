import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import HttpHelper from 'Services/HttpHelper'

const getFeelGreatCountries = () => {
    const url = `${ServerEnv.MemberCalls2(true)}/settings/countryFeelGreatV2/enable?t=${new Date().getTime()}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

const getSiteVersion = ({ country = 'thailand' }) => {
    const url = `https://member-calls4.unicity.com/ALL/ALL_Versions.asp` // No Dev Version

    return axios.post(
        HttpHelper.Proxy({ originalUrl: url }),
        {
            strData: JSON.stringify({
                source: `ufg.expert/${country}`
            })
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )
}

const feelGreateAPI = { getFeelGreatCountries, getSiteVersion }

export default feelGreateAPI
