import { createTheme } from "@mui/material";

const CustomLoginTheme = createTheme({
    breakpoints: {
        values: {
            xs: 320, // phone
            sm: 700, // tablets
            md: 900 // small laptop
        }
    },
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#FFF'
        },
        button: {
            disabled: '#CCDEEF'
        }
    },
    typography: {
        fontFamily: 'Poppins, Inter',
        fontStyle: 'normal',
        h3: {
            color: '#0B3B61',
            fontFamily: 'Poppins, Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px'
        },
        label: {
            color: '#8A9BB0!important',
            fontSize: "10px",
            fontWeight: 400
        },
        link: {
            cursor: 'pointer',
            textAlign: 'center',
            fontFamily: 'Poppins, Inter',
            fontStyle: 'normal',
            color: '#153862',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            textDecoration: 'underline'
        },
        caption: {
            fontFamily: 'Poppins, Inter',
            fontStyle: 'normal',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#003764'
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: "#000!important",
                    border: "1px solid #CCDEEF!important",
                }
            }
        }
    }
})

export default CustomLoginTheme