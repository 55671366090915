
const PasswordHideIcon = ({ strokeColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 22 12" fill="none">
      <path d="M19.8631 1C19.5873 3.20492 18.5587 5.22917 16.9692 6.69561C15.3796 8.16206 13.3374 8.97068 11.2231 8.97083C9.10872 8.97098 7.06642 8.16264 5.47667 6.69642C3.88692 5.2302 2.85815 3.20609 2.58203 1.00122" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
      <path d="M15.2617 8.5L16.8463 11" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
      <path d="M18.4297 5.16406L20.9988 6.47779" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
      <path d="M18.4297 5.16406L20.9988 6.47779" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
      <path d="M3.57031 5.16406L1.00122 6.47779" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
      <path d="M6.54688 11L8.13144 8.49998" stroke={strokeColor} stroke-width="1.5" stroke-linecap="round" />
    </svg>
  )
}

export default PasswordHideIcon
