import { Box, Modal, styled } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DefaultButton } from '../Forms'
import { ReactComponent as FlipLeft } from './Assets/flip-left.svg'
import { ReactComponent as FlipRight } from './Assets/flip-right.svg'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const ProfilePictureTool = observer(props => {
    const translate = useTranslate()
    const { imgFile, open, onCloseModal, callback } = props
    const [uploadingImage, setUploadingImage] = useState(false)
    const imageCropRef = useRef()
    const [imageCropped, setImageCropped] = useState(null)
    const [imageBeforeCrop, setImageBeforeCrop] = useState(null)
    const [imageCroppedForUpload, setImageCroppedForUpload] = useState(null)
    const [crop, setCrop] = useState({
        unit: '%',
        width: 132,
        aspect: 1
    })
    useEffect(() => {
        setImageBeforeCrop(imgFile)
    }, [])

    const resetTools = () => {
        setImageBeforeCrop(null)
        setImageCropped(null)
        setImageCroppedForUpload(null)
    }

    const handleCancel = () => {
        onCloseModal(false)
        resetTools()
    }

    const handleConfirm = async () => {
        callback(imageCroppedForUpload)
        setUploadingImage(false)
        onCloseModal(false)
        resetTools()
    }

    const onLoad = useCallback(img => {
        imageCropRef.current = img
    }, [])

    const onCropChange = (crop, percentCrop) => {
        if (crop.width >= 132 && crop.height >= 132) {
            crop.x = crop.x < 0 ? 0 : crop.x
            setCrop(crop)
        }
    }

    const createCropPreview = async crop => {
        if (imageCropRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageCropRef.current, crop, 'newFile.jpeg')

            setImageCroppedForUpload(croppedImageUrl)
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                blob => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'))
                        return
                    }
                    blob.name = fileName
                    window.URL.revokeObjectURL(imageCropped)
                    setImageCropped(window.URL.createObjectURL(blob))

                    resolve(blob)
                },
                'image/jpeg',
                1
            )
        })
    }

    const createImage = url =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.src = url
        })

    const getRotatedImage = async (imageSrc, rotation = 0) => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
        if (orientationChanged) {
            canvas.width = image.height
            canvas.height = image.width
        } else {
            canvas.width = image.width
            canvas.height = image.height
        }

        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.rotate((rotation * Math.PI) / 180)
        ctx.drawImage(image, -image.width / 2, -image.height / 2)

        return new Promise(resolve => {
            canvas.toBlob(
                file => {
                    setCrop({
                        unit: '%',
                        width: 132,
                        aspect: 1
                    })
                    setImageBeforeCrop(URL.createObjectURL(file))
                },
                'image/jpeg',
                1
            )
        })
    }

    return (
        <Modal open={open} onClose={() => { }}>
            <Wrapper>

                <CropBox>
                    <ReactCrop
                        src={imageBeforeCrop}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={onLoad}
                        onComplete={createCropPreview}
                        onChange={onCropChange}
                    />
                </CropBox>
                <RotateBox>
                    <Box onClick={() => getRotatedImage(imageBeforeCrop, -90)}>
                        <FlipLeft />
                    </Box>
                    <Box>
                        <img
                            key={imageCropped}
                            src={imageCropped}
                            style={{
                                objectFit: 'contain',
                                // width: '100%',
                                height: '100%',
                                objectPosition: 'center'
                            }}
                        />
                    </Box>
                    <Box onClick={() => getRotatedImage(imageBeforeCrop, 90)}>
                        <FlipRight />
                    </Box>
                </RotateBox>
                <Divine />
                <Box padding={'16px'} display="flex" justifyContent={'space-evenly'}>
                    <DefaultButton
                        disabled={uploadingImage}
                        type="submit"
                        fullWidth={true}
                        titleText={translate('confirm')}
                        width="115px"
                        isloading={uploadingImage.toString()}
                        sx={{
                            backgroundColor: '#0da9ef',
                            fontWeight: 500,
                            fontSize: '16px',
                            fontStyle: 'normal',
                            textTransform: 'uppercase'
                        }}
                        onClick={handleConfirm}
                    />
                    <DefaultButton
                        disabled={uploadingImage}
                        type="submit"
                        fullWidth={true}
                        titleText={translate('cancel')}
                        width="115px"
                        sx={{
                            backgroundColor: '#fff',
                            fontWeight: 500,
                            fontSize: '16px',
                            fontStyle: 'normal',
                            color: '#333333',
                            border: '1px solid #CCCCCC',
                            ':hover': {
                                backgroundColor: '#e6e6e6',
                                color: 'grey',
                                border: '1px solid #adadad'
                            },
                            textTransform: 'uppercase'
                        }}
                        onClick={handleCancel}
                    />
                </Box>
            </Wrapper>
        </Modal>
    )
})

const Wrapper = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    max-width: 95vw;
    background-color: #fff;
    border-radius: 12px;

    @media screen and (min-width: 768px) {
    }

`

const Divine = styled('div')`
    width: 100%;
    height: 1px;
    box-sizing: border-box;
    background-color: #f0f0f0;
`

const CropBox = styled(Box)`
    margin: 16px 16px 5px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DDDDDD;
    min-height: 150px;

    img.ReactCrop__image {
        max-height: calc(80vh - 272px);
        max-width: 100%;
        width: auto;
        height: auto;
        min-height: 132px;
        min-width: 132px;
    }

    
`

const RotateBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    div {
        width: 150px;
        height: 150px;

        :nth-of-type(2) {
            background-color: #d9d9d9;
        }

        :nth-of-type(1),
        :nth-of-type(3) {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
`

export default ProfilePictureTool
