import { Box, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'

const CountryItem = observer(({ country, handleClick }) => {
    // const flags = require.context('../../../../public/Assets/flags', true)

    return (
        <Box
            width="100%"
            sx={{
                display: 'grid',
                gridTemplateColumns: '40px 1fr',
                alignItems: 'center'
            }}>
            <Box
                sx={{ display: 'flex', alignItems: 'center', width: '24px' }}
                onClick={() => {
                    // handleClick(country, get(country, 'config.language.list[0].code', ''), get(country, 'config.language.default'))
                }}>
                {country.flagUrl ? (
                    <img src={country.flagUrl} alt={country.native_name} style={{ marginRight: 20, borderRadius: '3px', width: '24px' }} />
                ) : (
                    <div>&nbsp;</div>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                <Stack flexDirection={'row'}>
                    <Typography
                        variant="label"
                        sx={{
                            fontSize: {
                                xs: '16px',
                                md: '14px'
                            },
                            color: '#153862',
                            fontWeigth: 500
                        }}
                        onClick={() => {
                            // handleClick(country, get(country, 'config.language.list[0].code', ''), get(country, 'config.language.default'))
                        }}>
                        {country.native_name || country.roman_name}
                    </Typography>

                    <Typography
                        variant="label"
                        sx={{
                            fontSize: {
                                xs: '16px',
                                md: '14px'
                            },
                            color: '#a5b9d1',
                            fontWeigth: 500,
                            marginLeft: '5px'
                        }}>
                        {country.config.currency.full_currency_code}
                    </Typography>
                </Stack>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {country.config.languageSettings.items.map((language, index) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} key={`${country.native_name || country.roman_name}_language_${index}`}>
                            <Box
                                onClick={() => {
                                    handleClick(country, language.code)
                                }}
                                sx={{ cursor: 'pointer' }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontSize: {
                                            xs: '14px',
                                            md: '12px'
                                        },
                                        color: '#153862'
                                    }}>
                                    {language.name}
                                </Typography>
                            </Box>
                            {index !== country.config.languageSettings.items.length - 1 && <Box>|</Box>}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
})

export default CountryItem
