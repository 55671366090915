import { Box, Fade, Modal, Stack, Typography, styled } from '@mui/material'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CardFrontDrop from './CardFrontDrop'
import { storeElement } from 'GlobalStores/StoreElement'
import ModalLogin from './ModalLogin'
import { ReactComponent as ProfileIcon } from './../Assets/Svgs/profile.svg'

const LoginBar = observer(props => {
    const navigate = useNavigate()
    const location = useLocation()
    const [openLoginModal, setOpenLoginModal] = useState(false)
    return (
        <Stack position={'relative'} sx={{ marginTop: '20px' }} >
            <CardFrontDrop />
            <StackAlreadyAccountStyled>
                <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                    <ProfileIcon />
                    <Typography variant="caption2" fontWeight={500} color="#003764" fontSize={'14px'}>
                        <T>Already_have_an_account</T>
                    </Typography>
                </Stack>

                <Typography
                    variant="link"
                    sx={{ fontWeight: 700, cursor: 'pointer', textDecoration: 'underline' }}
                    color="#153862"
                    onClick={() => setOpenLoginModal(true)}>
                    <T>login</T>
                </Typography>
                <Modal open={openLoginModal} onClose={() => setOpenLoginModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Fade in={openLoginModal}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 'calc(50% - 40px)',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                padding: '0px',
                                outline: 'none'
                            }}>
                            <ModalLogin setOpenLoginModal={setOpenLoginModal} />
                        </Box>
                    </Fade>
                </Modal>
            </StackAlreadyAccountStyled>
        </Stack>
    )
})

const StackAlreadyAccountStyled = styled(Stack)`
    /* align-items: center; */
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFF1DC;
    border-radius: 12px;
    padding: 14px 10px;
    align-items: center;

    @media screen and (min-width: 700px) {
        justify-content: space-between;
    }
`

export default LoginBar
