import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import Subscriptions from './Subscriptions'
import ReasonCancelSubscriptions from './ReasonCancelSubscriptions'
import CancelSuccess from './CancelSuccess'
import SkipDelivery from './SkipDelivery'
import SkipSuccess from './SkipSuccess'
import SubscriptionsMobile from './SubscriptionsMobileLayout/SubscriptionsMobile'
import SkipDeliveryMobile from './SubscriptionsMobileLayout/SkipDeliveryMobile'
import SkipSuccessMobile from './SubscriptionsMobileLayout/SkipSuccessMobile'
import ReasonCancelSubscriptionsMobile from './SubscriptionsMobileLayout/ReasonCancelSubscriptionsMobile'
import CancelSuccessMobile from './SubscriptionsMobileLayout/CancelSuccessMobile'
import CancelSubscriptions from './CancelSubscriptions'
import CancelSubscriptionsMobile from './SubscriptionsMobileLayout/CancelSubscriptionsMobile'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { Stack } from '@mui/material'

const PersonalDetailsRoute = observer(props => {

    const { width } = useSizeWindow()

    if (!StoreAuth.IsAuthorized()) {
        return <Navigate to={-1} replace />
    }

    return (
        <Stack>
            {width > 834 ? (
                <Routes>
                    <Route index element={<Subscriptions />} />
                    <Route path="cancel-subscriptions" element={<CancelSubscriptions />} />
                    <Route path="reason-cancel-subscriptions" element={<ReasonCancelSubscriptions />} />
                    <Route path="cancel-subscriptions/cancel-subscriptions-success" element={<CancelSuccess />} />
                    <Route path="skip-delivery" element={<SkipDelivery />} />
                    <Route path="skip-delivery/skip-delivery-success" element={<SkipSuccess />} />
                </Routes>
            ) : (
                <Routes>
                    <Route index element={<SubscriptionsMobile />} />
                    <Route path="cancel-subscriptions" element={<CancelSubscriptionsMobile />} />
                    <Route path="reason-cancel-subscriptions" element={<ReasonCancelSubscriptionsMobile />} />
                    <Route path="cancel-subscriptions/cancel-subscriptions-success" element={<CancelSuccessMobile />} />
                    <Route path="skip-delivery" element={<SkipDeliveryMobile />} />
                    <Route path="skip-delivery/skip-delivery-success" element={<SkipSuccessMobile />} />
                </Routes>
            )}
        </Stack>
    )
})

export default PersonalDetailsRoute
