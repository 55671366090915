import { useEffect, useState } from 'react'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'

const getNativeName = (obj, nameType, nativeNameReturnAs = 'fullName') => {
    var fullName = ''
    var nativeName = ''

    let checkNative = /@/
    Object.keys(obj).forEach(function (key) {
        var value = obj[key]

        if (checkNative.test(key)) {
            nativeName = value
        } else {
            nativeName = false
            if (key === 'firstName' || key === 'lastName') {
                fullName += value
            } else if (key === 'fullName') {
                fullName = value
            }
        }
    })

    if (nameType === 'fullName') {
        return fullName
    } else if (nameType === 'nativeName') {
        if (nativeName === false || nativeName === '') {
            return nativeNameReturnAs === 'fullName' ? fullName : ''
        } else {
            return nativeName
        }
    } else {
        if (nativeName === '') {
            return fullName
        } else {
            if (nativeName === false || nativeName === '') {
                return nativeNameReturnAs === 'fullName' ? fullName : ''
            } else {
                return nativeName
            }
        }
    }
}

const formatPriceDemical = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

const useSizeWindow = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

const isMaintenanceMode = () => {
    const isMaintenance = StoreCountry.country.maintenance_mode.maintenance_mode
    const hostname = window.location.hostname
    if (/localhost/.test(hostname) || /127.0.0.1/.test(hostname)) {
        return false
    }
    return isMaintenance
}

const isComingSoon = () => {
    // return /ufg.expert|ushop-dev/.test(window.location.hostname)
    return /ufg.expert/.test(window.location.hostname)
}

function formToJSON(elements) {
    let inputs = {}
    for (var i = 0, element; (element = elements[i++]);) {
        inputs[element.name] = element.value
    }
    return inputs
}

export { getNativeName, formatPriceDemical, useSizeWindow, isMaintenanceMode, formToJSON, isComingSoon }
