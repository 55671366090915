import { makeAutoObservable, runInAction } from 'mobx'
import StoreCheckout from './Checkout/StoreCheckout'
import { get, isEmpty } from 'lodash'
class StoreProducts {
    flavors = []
    products = {}

    isInit = false

    Init(flavors, products) {
        runInAction(() => {
            this.flavors = flavors
            this.products = products
            this.isInit = true
        })
    }

    GetProducts() {
        return this.products
    }

    GetFlavors() {
        return this.flavors
    }

    GetProduct(flavor, purchaseType) {
        if (this.products[flavor]) {
            return this.products[flavor][purchaseType]
        }
    }

    GetProductPrice(flavor, purchaseOption, priceType = null) {
        const product = this.GetProduct(flavor, purchaseOption)
        if (product) {
            if (priceType) {
                return product.price[priceType]
            }
            if (StoreCheckout.HasDiscount()) {
                return product.price.member
            } else {
                return product.price.retail
            }
        }
    }

    GetIsInit() {
        return this.isInit
    }

    GetProductByItemCode(itemCode) {
        let product = null
        if (this.products) {
            Object.keys(this.products).forEach(productName => {
                Object.keys(this.products[productName]).forEach(subscription => {
                    if (this.products[productName][subscription].item_code === itemCode) {
                        product = this.products[productName][subscription]
                    }
                })
            })
        }

        return product
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new StoreProducts()
