import styled from '@emotion/styled'
import { Box, Dialog, LinearProgress, Stack, ThemeProvider, Typography, linearProgressClasses } from '@mui/material'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import { ReactComponent as AppleIcon } from './assets/AppleIcon.svg'
import { ReactComponent as SuccessIcon } from './assets/Success.svg'
import { ReactComponent as FailedIcon } from './assets/Failed.svg'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { ReactComponent as IconSpinnerBlue } from '../Spinner/IconSpinnerBlue.svg'
import { useEffect } from 'react'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { runInAction } from 'mobx'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import CheckoutLoaderStore from 'GlobalStores/Checkout/CheckoutLoader'

const PaymentProcessBar = observer(({ isModalOpen, onClose }) => {
    useEffect(() => {
        Object.keys(CheckoutLoaderStore.orderApiProgress).forEach(key => {
            CheckoutLoaderStore.orderApiProgress[key] = 0
        })
        if (storeAuth.IsAuthorized()) {
            runInAction(() => {
                CheckoutLoaderStore.orderApiProgress.login = -1
                CheckoutLoaderStore.orderApiProgress.hydraCreateAccount = -1
            })
        }

        if (/onetime/.test(storeFormat.GetAutoship())) {
            runInAction(() => {
                CheckoutLoaderStore.orderApiProgress.createAutoship = -1
            })
        }
        // setTimeout(() => {
        //     callSimulate()
        // }, 1000)
    }, [])

    function callSimulate() {
        simulateProcessing('hydraCreateAccount', true)
            .then(() => simulateProcessing('login', true))
            .then(() => simulateProcessing('createOrder', true))
            .then(() => simulateProcessing('createAutoship', true))
            .catch(error => {
                console.error(error)
                // Mark all subsequent steps as failed
                for (const step in CheckoutLoaderStore.orderApiProgress) {
                    if (CheckoutLoaderStore.orderApiProgress[step] !== 2) {
                        CheckoutLoaderStore.orderApiProgress[step] = 3
                    }
                }
            })
    }

    function simulateProcessing(step, success = true) {
        return new Promise((resolve, reject) => {
            if (success) {
                CheckoutLoaderStore.orderApiProgress[step] = 1
                setTimeout(() => {
                    CheckoutLoaderStore.orderApiProgress[step] = 2
                    console.log(`Step ${step} processed successfully`)
                    resolve() // Resolve the promise
                }, Math.random() * 2000 + 1000)
            } else {
                console.error(`Step ${step} failed`)
                // Reject the promise with an error
                reject(new Error(`Step ${step} failed`))
            }
        })
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <DialogStyled open={isModalOpen} fullWidth keepMounted={false}>
                <LoaderLayout>
                    <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <AppleIcon />
                    </Stack>
                    <BarLoaderStyled>
                        <Box>
                            <Typography variant="h2" textAlign={'center'} fontSize={'30px'}>
                                <T>processing_your_purchase</T>
                            </Typography>
                        </Box>
                        <Box maxWidth={'326px'} width="90%" pt="30px">
                            <BorderLinearProgress
                                variant="determinate"
                                value={CheckoutLoaderStore.GetOrderApisPercentSummary()}
                                valueBuffer={CheckoutLoaderStore.GetOrderApisPercentBuffer()}
                            />
                        </Box>
                    </BarLoaderStyled>
                    <DebugProcessBar onClose={onClose} />
                </LoaderLayout>
            </DialogStyled>
        </ThemeProvider>
    )
})

export const DebugProcessBar = observer(({ onClose }) => {
    const IconState = state => {
        switch (state) {
            case 2:
                return (
                    <BoxIcon>
                        <SuccessIcon />
                    </BoxIcon>
                )
            case 3:
                return (
                    <BoxIcon>
                        <FailedIcon />
                    </BoxIcon>
                )
            default:
                return (
                    <BoxIcon className="icon-spinner">
                        <IconSpinner />
                    </BoxIcon>
                )
        }
    }

    const closeModal = () => {
        setTimeout(() => {
            onClose()
        }, 1000)
    }

    return (
        <Box mt="40px" display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <Stack direction={'column'}>
                {Object.keys(CheckoutLoaderStore.orderApiProgress).map(key => {
                    if (CheckoutLoaderStore.orderApiProgress[key] === -1) return null
                    return (
                        <Stack columnGap={'10px'} rowGap={'12px'} direction={'row'} alignItems={'center'}>
                            <Box>{IconState(CheckoutLoaderStore.orderApiProgress[key])}</Box>
                            <Box>
                                <Typography variant="subtitle2" fontSize={'14px'}>
                                    <T>{CheckoutLoaderStore.orderApiProgressKeyToDictionary[key]}</T>
                                </Typography>
                            </Box>
                        </Stack>
                    )
                })}
            </Stack>
            {CheckoutLoaderStore.IsOrderApisHasFailed() && (
                <Stack mt="30px">
                    <Typography variant="link" fontSize={'16px'} fontWeight={400} onClick={closeModal} sx={{ cursor: 'pointer' }}>
                        Close
                    </Typography>
                </Stack>
            )}
        </Box>
    )
})

const BoxIcon = styled(Box)`
    display: flex;
    width: 15px;
    height: 15px;

    svg {
        width: 15px;
        height: 15px;
    }
`

const DialogStyled = styled(Dialog)`
    .MuiDialog-paper {
        border-radius: 12px;
        box-shadow: none;
    }
    .MuiBackdrop-root {
        background-color: rgba(56, 66, 80, 0.5);
    }
`

const LoaderLayout = styled(Stack)`
    padding: 70px 0px;
`

const BarLoaderStyled = styled(Stack)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    background-color: white;
    .fade-out {
        animation: fadeout 1s;
        opacity: 0;
        z-index: -1;
    }
`

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#E9F1FA'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#0071E3'
    }
}))

const IconSpinner = styled(IconSpinnerBlue)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 65px;
    height: 65px;

    circle {
        stroke: #e2e8ed;
    }
    path {
        stroke: #e2e8f2;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default PaymentProcessBar
