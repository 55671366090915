import { Grid, Skeleton, Typography, ThemeProvider, Stack, styled } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageTool from './ProfilePictureTool'
import { customTheme } from './PersonalTheme'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { runInAction } from 'mobx'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import ProfilePhotoSetting from './ProfilePhotoSetting'
import DisplayNameSetting from './DisplayNameSetting'
import SettingItemTemplate from './SettingItemTemplate'
import CardFrontDrop from '../CardFrontDrop/CardFrontDrop'

const Settings = observer(props => {
    const inputUploadRef = useRef(null)
    const { width } = useSizeWindow()
    const translate = useTranslate()
    const navigate = useNavigate()
    const { userData } = props

    const [userDataFullfiled, fullFillUserData] = useState(null)
    const [image, setImage] = useState(null)
    const [showImageTools, setShowImageTools] = useState(false)
    const [imageUploading, setImageUploading] = useState(false)

    const handleNav = path => {
        navigate(path)
    }



    const handleFileClick = () => {
        if (imageUploading) return

        inputUploadRef.current.click()
        let acceptDevices = ['iPad', 'iPhone']
        if (!acceptDevices.includes(window.navigator?.platform)) {
            document.body.onfocus = () => {
                setImageUploading(false)
                document.body.onfocus = null
            }
            document.body.ontouchstart = e => {
                setImageUploading(false)
            }
        }

    }

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0]
        if (!fileObj) {
            return
        }
        setImage(window.URL.createObjectURL(fileObj))
        setShowImageTools(true)
        event.target.value = null
    }

    const handleOnSaveProfilePicturre = async picture => {
        setImageUploading(true)
        const [res, err] = await StoreAuth.updateUserProfilePicture(picture)
        if (err) alert(err)
        if (res) {
            const userResponse = await StoreAuth.getCustomerMenu()
            let ushop = userResponse.onself.ushop
            ushop.profile.profilePicture = res
            StoreUser.setUserData(ushop)
        }
        setTimeout(() => {
            setImageUploading(false)
        }, 1500)

    }

    const handleRemoveProfilePicture = async () => {
        setImageUploading(true);
        const [res, err] = await StoreAuth.removeUserProfilePicture();

        if (err) {
            alert(err)
        }

        if (res.status == 204) {
            const userResponse = await StoreAuth.getCustomerMenu()
            let ushop = userResponse.onself.ushop
            ushop.profile.profilePicture.sizes = []
            StoreUser.setUserData(ushop)
        }

        console.log("Debug handleRemoveProfilePicture :", { res, err })

        setTimeout(() => {
            setImageUploading(false)
        }, 1500)

    }

    useEffect(() => {
        runInAction(() => {
            storeElement.cardTitle.titleStep1 = <T>settings</T>
        })
        return () => {
            runInAction(() => {
                storeElement.cardTitle.titleStep1 = ''
            })
        }
    })

    useEffect(() => {
        if (userData) {
            setTimeout(() => {
                fullFillUserData(userData || null)
            }, 1000)
        }
    }, [userData])



    return (
        <ThemeProvider theme={DefaultReferralTheme}>
            <CardFrontDrop />

            {userDataFullfiled ? (
                <>
                    <Title>
                        <Typography
                            variant="h2"
                            component="h2"
                            sx={{
                                fontSize: {
                                    xs: '24px',
                                    md: '32px'
                                },
                                textAlign: 'left',
                                textWrap: ''
                            }}>
                            {translate('personal_details')}
                        </Typography>
                    </Title>
                    <Grid container display={'flex'} flexDirection={'column'}>

                        {showImageTools && (
                            <ImageTool open={showImageTools} imgFile={image} onCloseModal={setShowImageTools} callback={handleOnSaveProfilePicturre} />
                        )}

                        <input style={{ display: 'none' }} ref={inputUploadRef} type="file" accept='image/*' onChange={handleFileChange} />
                        <ProfilePhotoSetting
                            onClickChange={handleFileClick}
                            onClickRemove={handleRemoveProfilePicture}
                            isLoading={imageUploading}
                        />

                        <DisplayNameSetting />

                        <SettingItemTemplate
                            titleText={translate('change_email')}
                            onClickChange={() => handleNav('email-edit')}
                            presentValue={userDataFullfiled.email}
                        />
                        <SettingItemTemplate
                            titleText={translate('change_mobile')}
                            onClickChange={() => handleNav('mobile-number-edit')}
                            presentValue={userDataFullfiled.mobilePhone}
                        />
                        <SettingItemTemplate
                            titleText={translate('change_password')}
                            onClickChange={() => handleNav('change-password')}
                            presentValue={'********'}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Stack mb="32px">
                        <Skeleton variant="rounded" animation="wave" width="250px" height="48px" />
                    </Stack>

                    <Grid container display={'flex'} flexDirection={'column'}>

                        <SettingItemTemplateSkeleton>
                            <Stack mb={'16px'}>
                                <Skeleton variant="rounded" animation="wave" width="200px" height="20px" />
                            </Stack>
                            <Stack mb={'32px'}>
                                <Skeleton variant="rounded" animation="wave" width="95%" height="16px" />
                            </Stack>

                            <Stack sx={{ flexDirection: 'row' }}>
                                <Stack
                                    sx={{
                                        pr: {
                                            xs: '32px',
                                            md: '48px'
                                        },
                                        justifyContent: "center",
                                        position: 'relative',
                                    }}
                                >
                                    <Skeleton variant="rounded" animation="wave" width="100px" height="100px" />

                                </Stack>

                                <Stack sx={{
                                    flexDirection: 'row',
                                    flexWrap: {
                                        xs: 'wrap',
                                        sm: 'nowrap'
                                    },
                                    gap: '24px',
                                    width: '100%',
                                    alignItems: 'center',
                                }}>
                                    <Skeleton
                                        sx={{
                                            width: "100%",
                                            maxWidth: "234px",
                                            height: "50px",
                                            padding: "8px 16px",
                                        }}
                                        variant="rounded" animation="wave"
                                    />

                                    <Skeleton
                                        sx={{
                                            width: "100%",
                                            maxWidth: "234px",
                                            height: "50px",
                                            padding: "8px 16px",
                                        }}
                                        variant="rounded" animation="wave"
                                    />

                                </Stack>
                            </Stack>

                        </SettingItemTemplateSkeleton>


                        <SettingItemTemplateSkeleton>
                            <Stack sx={{
                                mb: '16px',
                                justifyContent: "space-between",
                                flexDirection: "row"
                            }}>

                                <Skeleton variant="rounded" animation="wave" width="200px" height="20px" />

                                <Skeleton variant="rounded" animation="wave" width="100px" height="20px" />

                            </Stack>
                            <Stack mb={'32px'}>
                                <Skeleton variant="rounded" animation="wave" width="95%" height="16px" />
                            </Stack>
                            <Stack>
                                <Skeleton variant="rounded" animation="wave" width="50%" height="25px" />
                            </Stack>
                        </SettingItemTemplateSkeleton>



                        <SettingItemTemplateSkeleton>
                            <Stack sx={{
                                mb: '16px',
                                justifyContent: "space-between",
                                flexDirection: "row"
                            }}>

                                <Skeleton variant="rounded" animation="wave" width="200px" height="20px" />

                                <Skeleton variant="rounded" animation="wave" width="100px" height="20px" />

                            </Stack>
                            <Stack>
                                <Skeleton variant="rounded" animation="wave" width="70%" height="20px" />
                            </Stack>

                        </SettingItemTemplateSkeleton>
                        <SettingItemTemplateSkeleton>
                            <Stack sx={{
                                mb: '16px',
                                justifyContent: "space-between",
                                flexDirection: "row"
                            }}>

                                <Skeleton variant="rounded" animation="wave" width="200px" height="20px" />

                                <Skeleton variant="rounded" animation="wave" width="100px" height="20px" />

                            </Stack>
                            <Stack>
                                <Skeleton variant="rounded" animation="wave" width="70%" height="20px" />
                            </Stack>

                        </SettingItemTemplateSkeleton>
                        <SettingItemTemplateSkeleton>
                            <Stack sx={{
                                mb: '16px',
                                justifyContent: "space-between",
                                flexDirection: "row"
                            }}>

                                <Skeleton variant="rounded" animation="wave" width="200px" height="20px" />

                                <Skeleton variant="rounded" animation="wave" width="100px" height="20px" />

                            </Stack>
                            <Stack>
                                <Skeleton variant="rounded" animation="wave" width="70%" height="20px" />
                            </Stack>
                        </SettingItemTemplateSkeleton>
                    </Grid>
                </>
            )}
        </ThemeProvider>
    )
})

export default Settings


const Title = styled(Stack)`
    color: #003b6f;
    margin-bottom: 32px;
`

const SettingItemTemplateSkeleton = styled(Stack)`
   @media screen and (max-width: 900px) {
        position: relative;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid #CCDEEF;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
`